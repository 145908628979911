var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"testo"}},[_c('el-row',{staticClass:"posizioni",attrs:{"span":24}},[_c('el-col',{attrs:{"xs":{ span: 24, offset: 0 },"sm":{ span: 6, offset: 1 },"lg":{ span: 4, offset: 3 }}},[_c('el-col',{staticClass:"frontale",attrs:{"span":24}},[_c('span',[_vm._v("FRONTALE")])]),_c('el-col',{class:{ isActive: _vm.selezionaPos == 'testoCilSx' },attrs:{"span":24}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.selezionaPos = 'testoCilSx'}}},[_vm._v("DESTRO")])],1)],1),_c('el-col',{staticClass:"asta-interna",attrs:{"xs":{ span: 24, offset: 0 },"sm":{ span: 7, offset: 1 },"lg":{ span: 6, offset: 1 }}},[_c('el-col',{staticClass:"ast-int",attrs:{"xs":{ span: 24, offset: 0 },"sm":{ span: 23, offset: 1 }}},[_c('span',[_vm._v("ASTE INTERNE")])]),_c('el-col',{class:{ isActive: _vm.selezionaPos == 'testoIntSx' },attrs:{"xs":{ span: 12, offset: 0 },"sm":{ span: 10, offset: 1 }}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.selezionaPos = 'testoIntSx'}}},[_vm._v("SINISTRA")])],1),(_vm.windowWidth >= 768)?_c('el-col',{attrs:{"span":_vm.selezionaPos != 'testoIntSx' && _vm.selezionaPos != 'testoIntDx' ? 1 : 0,"offset":_vm.selezionaPos != 'testoIntSx' && _vm.selezionaPos != 'testoIntDx' ? 1 : 0}},[_c('el-divider',{attrs:{"direction":"vertical"}})],1):_vm._e(),_c('el-col',{class:{ isActive: _vm.selezionaPos == 'testoIntDx' },attrs:{"xs":{ span: 12, offset: 0 },"sm":{
          span: 10,
          offset:
            ("" + _vm.selezionaPos) != 'testoIntSx' &&
            ("" + _vm.selezionaPos) != 'testoIntDx'
              ? 1
              : 3,
        }}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.selezionaPos = 'testoIntDx'}}},[_vm._v("DESTRA")])],1)],1),_c('el-col',{staticClass:"ast-esterna",attrs:{"xs":{ span: 24, offset: 0 },"sm":{ span: 7, offset: 1 },"lg":{ span: 6, offset: 1 }}},[_c('el-col',{staticClass:"ast-est",attrs:{"xs":{ span: 24, offset: 0 },"sm":{ span: 23, offset: 1 }}},[_c('span',[_vm._v("ASTE ESTERNE")])]),_c('el-col',{class:{ isActive: _vm.selezionaPos == 'testoEstSx' },attrs:{"xs":{ span: 12, offset: 0 },"sm":{ span: 10, offset: 1 }}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.selezionaPos = 'testoEstSx'}}},[_vm._v("SINISTRA")])],1),(_vm.windowWidth >= 768)?_c('el-col',{attrs:{"span":_vm.selezionaPos != 'testoEstSx' && _vm.selezionaPos != 'testoEstDx' ? 1 : 0,"offset":_vm.selezionaPos != 'testoEstSx' && _vm.selezionaPos != 'testoEstDx' ? 1 : 0}},[_c('el-divider',{attrs:{"direction":"vertical"}})],1):_vm._e(),_c('el-col',{class:{ isActive: _vm.selezionaPos == 'testoEstDx' },attrs:{"xs":{ span: 12, offset: 0 },"sm":{
          span: 10,
          offset:
            ("" + _vm.selezionaPos) != 'testoEstSx' &&
            ("" + _vm.selezionaPos) != 'testoEstDx'
              ? 1
              : 3,
        }}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.selezionaPos = 'testoEstDx'}}},[_vm._v("DESTRA")])],1)],1)],1),_c('el-row',{staticClass:"colori-preview"},[_c('el-col',{staticClass:"nome-colore",attrs:{"xs":{ span: 24, offset: 0 },"sm":{ span: 11, offset: 1 },"lg":{ span: 9, offset: 3 }}},[(
          this.selezionaPos == 'testoEstSx' ||
          this.selezionaPos == 'testoEstDx'
        )?_c('el-input',{staticClass:"input-nome",attrs:{"placeholder":"Inserisci il tuo nome","maxlength":"25","show-word-limit":""},model:{value:(_vm.testo),callback:function ($$v) {_vm.testo=$$v},expression:"testo"}}):_c('el-input',{staticClass:"input-nome",attrs:{"placeholder":"Inserisci il tuo nome","maxlength":"10","show-word-limit":""},model:{value:(_vm.testo),callback:function ($$v) {_vm.testo=$$v},expression:"testo"}}),_c('div',{staticClass:"colori"},_vm._l((_vm.colors),function(c,id){return _c('div',{key:id},[_c('div',{staticClass:"img-colori",on:{"click":function($event){return _vm.setCol(c)}}},[_c('el-avatar',{class:{
                oro: c.name == 'oro',
                argento: c.name == 'argento',
                bordered: c.name == 'bianco',
                nc: c.name == 'nessun-colore',
                active: c.name == _vm.selectedColor.name,
              },style:({ backgroundColor: c.colorCode }),attrs:{"size":100},model:{value:(_vm.colore),callback:function ($$v) {_vm.colore=$$v},expression:"colore"}})],1)])}),0)],1),_c('el-col',{staticClass:"preview",attrs:{"xs":{ span: 24, offset: 0 },"sm":{ span: 11, offset: 0 },"lg":{ span: 9, offset: 0 }}},[(this.selezionaPos == 'testoCilSx')?_c('personal-name-preview',{staticClass:"preview-nome-front",attrs:{"name":_vm.testo,"colore":_vm.selectedColor,"font":_vm.font}}):(this.selezionaPos == 'testoIntSx')?_c('int-asta-sx-preview',{staticClass:"preview-nome",attrs:{"name":_vm.testo,"colore":_vm.selectedColor,"font":_vm.font,"astaColor":_vm.coloreAste.U_THE_COLORE}}):(this.selezionaPos == 'testoEstSx')?_c('ext-asta-sx-preview',{staticClass:"preview-nome",attrs:{"name":_vm.testo,"colore":_vm.selectedColor,"font":_vm.font,"astaColor":_vm.coloreAste.U_THE_COLORE}}):(this.selezionaPos == 'testoIntDx')?_c('int-asta-dx-preview',{staticClass:"preview-nome",attrs:{"name":_vm.testo,"colore":_vm.selectedColor,"font":_vm.font,"astaColor":_vm.coloreAste.U_THE_COLORE}}):(this.selezionaPos == 'testoEstDx')?_c('ext-asta-dx-preview',{staticClass:"preview-nome",attrs:{"name":_vm.testo,"colore":_vm.selectedColor,"font":_vm.font,"astaColor":_vm.coloreAste.U_THE_COLORE}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }