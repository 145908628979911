<template>
  <div id="menu">
    <ul class="action-list-left" :class="{ hidden: riepilogo == true }">
      <li :class="{ hidden: riepilogo == true || windowWidth < 768 }">
        <el-tooltip
          placement="top"
          :content="
            canPrint ? 'Stampa' : 'Completa la configurazione per stampare'
          "
        >
          <div :class="{ disabled: !canPrint }">
            <img :src="print" alt="Print" @click="printSet(true)" />
          </div>
        </el-tooltip>
      </li>
      <li :class="{ hidden: isClosed == false && windowWidth < 768 }">
        <el-tooltip
          placement="bottom"
          :content="
            canPrint
              ? 'Condividi'
              : 'Completa la configurazione per condividere'
          "
        >
          <div :class="{ disabled: !canPrint }" @click="openShareDialog(true)">
            <img :src="share" alt="Share" />
          </div>
        </el-tooltip>
      </li>
    </ul>
    <div
      class="close-menu-button"
      @click="isClosed = !isClosed"
      :class="{ hidden: riepilogo == true || windowWidth < 768 }"
    >
      <img
        :src="arrowDown"
        alt="close arrow"
        :class="{ 'rotate-up': isClosed }"
      />
    </div>
    <el-row
      :span="24"
      class="menu-selected"
      v-if="windowWidth < 768 && isClosed == false"
    >
      <!-- <el-col :span="8"></el-col> -->
      <el-col class="title-menu-selected" :span="16" :offset="4">{{
        nomeMenu
      }}</el-col>
      <el-col
        class="close-menu-selected"
        :span="1"
        :class="{ hidden: riepilogo == true }"
      >
        <div @click="isClosed = !isClosed">
          <WindowClose :size="40" />
        </div>
      </el-col>
    </el-row>
    <div
      v-if="(windowWidth < 768 && isClosed == true) || windowWidth >= 768"
      class="button-menu"
    >
      <!-- <div class="button-menu"> -->
      <ul class="list-menu">
        <li
          :class="{
            isActive: frontMenu == true,
            hidden: riepilogo == true,
            sole: $store.state.configurator.glassType == 'S',
          }"
        >
          <div
            class="img-div-black"
            v-if="!isCompleted('MONTATURA') && frontMenu == false"
          >
            <img
              :src="require('../../assets/images/icone/frontale.png')"
              @click="activeFrontMenu()"
            />
          </div>
          <div
            class="img-div-orange"
            v-else-if="isCompleted('MONTATURA') || frontMenu == true"
          >
            <img
              :src="require('../../assets/images/icone/frontale_orange.png')"
              @click="activeFrontMenu()"
            />
          </div>
          <div class="list-menu-title">
            <span v-if="frontMenu == true">FRONTALE</span>
          </div>
          <el-divider
            v-if="!frontMenu && !colorMenu"
            direction="vertical"
          ></el-divider>
        </li>
        <li
          :class="{
            isActive: colorMenu == true,
            hidden: riepilogo == true,
            sole: $store.state.configurator.glassType == 'S',
          }"
        >
          <div
            class="img-div-black"
            v-if="!isCompleted('COLORE') && colorMenu == false"
          >
            <img
              :src="require('../../assets/images/icone/colore.png')"
              @click="activeColorMenu()"
            />
          </div>
          <div
            class="img-div-orange"
            v-else-if="isCompleted('COLORE') || colorMenu == true"
          >
            <img
              :src="require('../../assets/images/icone/colore_orange.png')"
              @click="activeColorMenu()"
            />
          </div>
          <div class="list-menu-title">
            <span v-if="colorMenu == true">COLORE</span>
          </div>
          <el-divider
            v-if="!colorMenu && !templesMenu"
            direction="vertical"
          ></el-divider>
        </li>
        <li
          :class="{
            isActive: templesMenu == true,
            hidden: riepilogo == true,
            sole: $store.state.configurator.glassType == 'S',
          }"
        >
          <div
            class="img-div-black"
            v-if="!isCompleted('ASTE') && templesMenu == false"
          >
            <img
              :src="require('../../assets/images/icone/aste.png')"
              @click="activeTemplesMenu()"
            />
          </div>
          <div
            class="img-div-orange"
            v-else-if="isCompleted('ASTE') || templesMenu == true"
          >
            <img
              :src="require('../../assets/images/icone/aste_orange.png')"
              @click="activeTemplesMenu()"
            />
          </div>
          <div class="list-menu-title">
            <span v-if="templesMenu == true">ASTE</span>
          </div>
          <el-divider
            v-if="!templesMenu && !textMenu"
            direction="vertical"
          ></el-divider>
        </li>
        <li
          :class="{
            isActive: textMenu == true,
            hidden: riepilogo == true,
            sole: $store.state.configurator.glassType == 'S',
          }"
        >
          <div
            class="img-div-black"
            v-if="textMenu == false && !isCompleted('PERSONALIZZAZIONE')"
          >
            <img
              :src="require('../../assets/images/icone/personalizzazione.png')"
              @click="activeTextMenu()"
            />
          </div>
          <div
            class="img-div-orange"
            v-else-if="textMenu == true || isCompleted('PERSONALIZZAZIONE')"
          >
            <img
              :src="
                require('../../assets/images/icone/personalizzazione_orange.png')
              "
              @click="activeTextMenu()"
            />
          </div>
          <div class="list-menu-title">
            <span v-if="textMenu == true">INCISIONE</span>
          </div>
          <el-divider
            v-if="
              !textMenu &&
              !lentiMenu &&
              $store.state.configurator.glassType == 'S'
            "
            direction="vertical"
          ></el-divider>
        </li>
        <li
          v-if="$store.state.configurator.glassType == 'S'"
          :class="{
            isActive: lentiMenu == true,
            hidden: riepilogo == true,
            sole: $store.state.configurator.glassType == 'S',
          }"
        >
          <div
            class="img-div-black"
            v-if="lentiMenu == false && !isCompleted('LENTI')"
          >
            <img
              :src="require('../../assets/images/icone/frontale_sole.png')"
              @click="activeLentiMenu()"
            />
          </div>
          <div
            class="img-div-orange"
            v-else-if="lentiMenu == true || isCompleted('LENTI')"
          >
            <img
              :src="
                require('../../assets/images/icone/frontale_sole_orange.png')
              "
              @click="activeLentiMenu()"
            />
          </div>
          <div class="list-menu-title">
            <span v-if="lentiMenu == true">LENTI</span>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="(windowWidth < 768 && isClosed == false) || windowWidth >= 768"
      class="object-menu"
    >
      <div class="body-object-menu">
        <div v-if="frontMenu == true">
          <frontale></frontale>
        </div>

        <div v-if="colorMenu == true">
          <colore></colore>
        </div>

        <div v-if="templesMenu == true">
          <aste></aste>
        </div>

        <div v-if="textMenu == true">
          <testo></testo>
        </div>

        <div v-if="lentiMenu == true">
          <LentiSole></LentiSole>
        </div>

        <div v-if="riepilogo == true">
          <riepilogo></riepilogo>
        </div>
      </div>
    </div>
    <div
      class="footer-button"
      :class="{ hidden: windowWidth >= 768 && isClosed == true }"
    >
      <el-button-group class="buttons-group">
        <el-button
          class="reset"
          :class="{ reset100: isOnSite == true }"
          type="default"
          v-if="confirm == false"
          @click="clearConfiguration()"
          >RESET</el-button
        >
        <el-button class="reset" type="default" v-else @click="changeConfig()"
          >MODIFICA</el-button
        >
        <el-button
          class="aggiungi"
          type="primary"
          v-if="confirm == false && isOnSite == false"
          :disabled="userIsGuest || !isConfComplete"
          @click="addConfig()"
          >AGGIUNGI AL CARRELLO</el-button
        >
        <el-button
          class="aggiungi"
          type="primary"
          :disabled="canAddToCart && note == ''"
          v-else-if="isOnSite == false"
          @click="conferma"
          >CONFERMA</el-button
        >
      </el-button-group>
    </div>

    <!-- Dialog articolo aggiunto -->
    <el-dialog
      class="confirm-dialog"
      width="500px"
      title="ARTICOLO AGGIUNTO AL CARRELLO"
      :visible.sync="gAddedDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-button-group>
        <el-button
          type="default"
          class="confirm-button-1"
          @click="goToConfigurator"
        >
          <span v-if="windowWidth < 767">CONFIGURATORE</span>
          <span v-else>VAI AL CONFIGURATORE</span>
        </el-button>
        <el-button type="primary" class="confirm-button-2" @click="goToCart">
          <span v-if="windowWidth < 767">CARRELLO</span>
          <span v-else>VAI AL CARRELLO</span>
        </el-button>
      </el-button-group>
    </el-dialog>

    <!-- Form per condivisione configurazione -->
    <el-dialog
      class="share-dialog"
      width="550px"
      title="CONDIVIDI MAIL"
      :visible.sync="shareDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
      >
        <el-form-item
          class="input-mail-from"
          prop="emailFrom"
          label="Email mittente"
          :rules="[
            { required: true, message: 'Campo obbligatorio', trigger: 'blur' },
            {
              type: 'email',
              message: 'Per favore inserire una mail valida',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            placeholder="example@example.com"
            v-model="dynamicValidateForm.emailFrom"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="input-mail-to"
          prop="emailTo"
          label="Email destinatario"
          :rules="[
            { required: true, message: 'Campo obbligatorio', trigger: 'blur' },
            {
              type: 'email',
              message: 'Per favore inserire una mail valida',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            placeholder="example@example.com"
            v-model="dynamicValidateForm.emailTo"
          ></el-input>
        </el-form-item>
        <el-input
          class="message-mail"
          type="textarea"
          :rows="5"
          placeholder="Messaggio"
          v-model="messageMail"
        ></el-input>
        <div class="campi-obblig">
          <span>* Campi obbligatori</span>
        </div>
        <el-form-item>
          <el-button-group>
            <el-button type="default" @click="shareDialog = false"
              >CHIUDI</el-button
            >
            <el-button type="primary" @click="condividiConfig()"
              >INVIA</el-button
            >
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- Dialog configurazione inviata -->
    <el-dialog
      class="sendmail-dialog"
      width="550px"
      title="MAIL INVIATA"
      :visible.sync="shareConfirm"
      :modal="false"
      :show-close="true"
    >
      <img :src="require('../../assets/images/icone/send_mail.png')" alt />
      <el-button
        type="default"
        class="close-button"
        @click="shareConfirm = false"
        >CHIUDI</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import jslinq from "jslinq";
// eslint-disable-next-line no-unused-vars
import { EventBus } from "@/plugins/EventBus.js";

import Components from "./menu/index";
import arrowDown from "@/assets/images/arrow-down-icon.png";
import share from "@/assets/images/share-icon.png";
import _ from "lodash";
import print from "@/assets/images/print-icon.png";
import LentiSole from "./menu/LentiSole.vue";

export default {
  components: { ...Components, LentiSole },
  data() {
    return {
      frontMenu: true,
      colorMenu: false,
      templesMenu: false,
      textMenu: false,
      lentiMenu: false,
      riepilogo: false,
      confirmDialog: false,
      gAddedDialog: false,
      arrowDown,
      share,
      print,
      isClosed: false,
      shareDialog: false,
      emailFrom: "",
      emailTo: "",
      messageMail: "",
      shareConfirm: false,
      mailData: [],
      dynamicValidateForm: {
        emailFrom: "",
        emailTo: "",
      },
      windowWidth: window.innerWidth,
      nomeMenu: "",
      isConfComplete: true,
    };
  },
  async mounted() {
    if (this.windowWidth < 768) {
      this.isClosed = true;
    } else {
      this.isClosed = false;
    }
    if (this.$route.params.id === undefined && _.isEmpty(this.frontale)) {
      await this.getItems(false);
      if (this.itemsSizes.length == 0) await this.getAllModelsSizes();
      await this.getColors();
      await this.getAllColors();
      const c = jslinq(this.colors),
        black = c.firstOrDefault((col) => {
          return col.U_THE_COLORE == "EP0001";
        }),
        cC = c.firstOrDefault((col) => {
          return col.Category == "SOLIDI" && col.U_THE_COLORE == "EP0001";
        }),
        ca = jslinq(this.allColors),
        blackAste = ca.firstOrDefault((col) => {
          return col.U_THE_COLORE == "PL0001" && col.U_THE_LUNGASTA == 145;
        }),
        s = jslinq(this.itemsSizes),
        regularSize = s.firstOrDefault((size) => {
          return (
            size.size_description == "REGULAR" &&
            size.U_THE_MODELLO == this.frontale.U_THE_MODELLO
          );
        });
      if (black) {
        this.setColore(black);
      }
      if (cC) {
        this.setCatColore(cC);
      }
      this.setTemplesColor(blackAste);
      if (regularSize) {
        this.setSize(regularSize);
      }
    } else {
      await this.getItems(false);
      await this.getAllModelsSizes();
      await this.getColors();
      await this.getAllColors();
      let s = jslinq(this.itemsSizes),
        choosenSize = s.firstOrDefault((size) => {
          return (
            size.size_bridge == this.naso && size.size_eye == this.calibro
            // &&
            // size.U_THE_MODELLO == this.frontale.U_THE_MODELLO
          );
        });
      if (this.colore != null && this.colore != undefined) {
        this.setCatColore(this.colore);
        this.setColore(this.colore);
      }
      this.setTemplesColor(this.coloreAste);
      this.setSize(choosenSize);
      this.setFinishing(this.finitura);
    }
  },
  methods: {
    // ...mapActions(["sendSendGridMail", "setIdForUrl", "makeQRCode"]),
    // ...mapActions(["sendSendGridMail", "setIdForUrl"]),
    ...mapMutations(["setCatColore", "setNavMenuVisible"]),
    screenshotAndSendSendGridMail(data, emailFrom, emailTo) {
      this.$store.dispatch("configurator/screenshotAndSendSendGridMail", {
        data,
        emailFrom,
        emailTo,
      });
    },
    setIdForUrl() {
      this.$store.dispatch("configurator/setIdForUrl");
    },
    setConfirm() {
      this.$store.commit("configurator/setConfirm");
    },
    saveConfiguration() {
      this.$store.commit("configurator/saveConfiguration");
    },
    clearAllConfigurations() {
      this.$store.commit("configurator/clearAllConfigurations");
    },
    resetConfiguration() {
      this.$store.commit("resetConfiguration", {
        forma: "MYC-001",
        colore: "EP0001",
        coloreAste: "PL0001",
      });
    },
    setColore(color) {
      this.$store.dispatch("configurator/frontali/setColor", color);
    },
    setTemplesColor() {
      this.$store.commit("configurator/setTemplesColor");
    },
    setSize(size) {
      this.$store.commit("configurator/setSize", size);
    },
    setFinishing(finishing) {
      this.$store.commit("configurator/setFinishing", finishing);
    },
    // ...mapActions("cart", ["addToCart"]),
    // ...mapActions("frontali", ["getItems", "getColors", "getAllModelsSizes"]),
    // ...mapActions("aste", ["getAllColors"]),
    addToCart() {
      this.$store.dispatch("configurator/cart/addToCart");
    },
    getItems() {
      this.$store.dispatch("configurator/frontali/getItems");
    },
    getColors() {
      this.$store.dispatch("configurator/frontali/getColors");
    },
    getAllModelsSizes() {
      this.$store.dispatch("configurator/frontali/getAllModelsSizes");
    },
    getAllColors() {
      this.$store.dispatch("configurator/aste/getAllColors");
    },
    isCompleted(menuName) {
      switch (menuName) {
        case "MONTATURA":
          return this.modelErrors && this.modelErrors.length == 0;
        case "COLORE":
          return this.colorErrors && this.colorErrors.length == 0;
        case "ASTE":
          return this.templesErrors && this.templesErrors.length == 0;
        case "PERSONALIZZAZIONE":
          return (
            this.nome != "" ||
            this.nomeExtAstaSx != "" ||
            this.nomeIntAstaSx != "" ||
            this.nomeExtAstaDx != "" ||
            this.nomeIntAstaDx != ""
          );
        case "LENTI":
          return this.$store.state.configurator.lenteSole !== null;
      }
    },
    activeFrontMenu() {
      this.frontMenu = true;
      this.colorMenu = false;
      this.templesMenu = false;
      this.textMenu = false;
      this.lentiMenu = false;
      this.riepilogo = false;
      if (this.windowWidth < 768) {
        this.isClosed = !this.isClosed;
        this.nomeMenu = "FRONTALE";
      }
    },
    activeColorMenu() {
      this.frontMenu = false;
      this.colorMenu = true;
      this.templesMenu = false;
      this.textMenu = false;
      this.lentiMenu = false;
      this.riepilogo = false;
      if (this.windowWidth < 768) {
        this.isClosed = !this.isClosed;
        this.nomeMenu = "COLORE";
      }
    },
    activeTemplesMenu() {
      this.frontMenu = false;
      this.colorMenu = false;
      this.templesMenu = true;
      this.textMenu = false;
      this.lentiMenu = false;
      this.riepilogo = false;
      if (this.windowWidth < 768) {
        this.isClosed = !this.isClosed;
        this.nomeMenu = "ASTE";
      }
    },
    activeTextMenu() {
      this.frontMenu = false;
      this.colorMenu = false;
      this.templesMenu = false;
      this.textMenu = true;
      this.lentiMenu = false;
      this.riepilogo = false;
      if (this.windowWidth < 768) {
        this.isClosed = !this.isClosed;
        this.nomeMenu = "INCISIONE";
      }
    },
    activeLentiMenu() {
      this.frontMenu = false;
      this.colorMenu = false;
      this.templesMenu = false;
      this.textMenu = false;
      this.lentiMenu = true;
      this.riepilogo = false;
      if (this.windowWidth < 768) {
        this.isClosed = !this.isClosed;
        this.nomeMenu = "LENTI";
      }
    },
    recap() {
      this.frontMenu = false;
      this.colorMenu = false;
      this.templesMenu = false;
      this.textMenu = false;
      this.lentiMenu = false;
      this.riepilogo = true;
      if (this.windowWidth < 768) {
        // this.isClosed = !this.isClosed;
        this.nomeMenu = "RIEPILOGO";
      }
    },
    addConfig() {
      this.setConfirm();
      this.saveConfiguration();
      this.recap();
      if (this.isClosed == true) {
        this.isClosed = false;
      }
    },
    changeConfig() {
      this.setConfirm();
      this.clearAllConfigurations();
      this.activeFrontMenu();
    },
    conferma() {
      // this.confirmDialog = false;
      this.gAddedDialog = true;
      this.addToCart();
    },
    goToConfigurator() {
      // this.$router.push("/");
      this.activeFrontMenu();
      this.gAddedDialog = false;
      this.setConfirm();
      this.clearConfiguration();
      this.clearAllConfigurations();
    },
    goToCart() {
      this.$router.push("/carrello");
      this.activeFrontMenu();
      this.gAddedDialog = false;
      this.setConfirm();
      this.clearConfiguration();
      this.clearAllConfigurations();
    },
    clearConfiguration() {
      this.resetConfiguration({
        forma: this.frontale.U_THE_MODELLO,
        colore: "PL0001",
        sizeDescription: "REGULAR",
      });
      // this.$emit("resettedMaterial");
    },
    printSet(v) {
      // EventBus.$emit("print");
      this.positionForPrint = v;

      setTimeout(() => {
        this.$store.commit("configurator/setPrint", v);
      }, 750);
    },
    condividiConfig() {
      // this.shareDialog = false;
      // this.shareConfirm = true;
      this.$refs.dynamicValidateForm.validate(async (valid) => {
        if (valid) {
          // alert("submit!");
          let data = {},
            emailFrom = this.dynamicValidateForm.emailFrom,
            emailTo = this.dynamicValidateForm.emailTo,
            isGmail = this.dynamicValidateForm.emailTo.includes("@gmail.com");
          let finFrontale =
              this.getActiveConfiguration.finitura == "S"
                ? "LUCIDO"
                : this.getActiveConfiguration.finitura == "N"
                ? "NATURALE"
                : "OPACO",
            finAste =
              this.getActiveConfiguration.finituraAste == "S"
                ? "LUCIDO"
                : "OPACO",
            canvas = document.getElementsByTagName("canvas")[0],
            img = canvas.toDataURL("image/png"),
            colFrontale =
              this.getActiveConfiguration.colore.U_THE_COLORE.replace("PL", ""),
            colAste =
              this.getActiveConfiguration.coloreAste.U_THE_COLORE.replace(
                "PL",
                ""
              ),
            colEst = "",
            colInt = "";
          if (this.colore.Category == "MULTISTRATO") {
            colEst =
              "https://cdn.thema-optical.com/3d-models/colori_acetato_sito/" +
              this.colore.U_THE_COLORE +
              ".jpg";
            colInt =
              "https://cdn.thema-optical.com/3d-models/colori_acetato_sito/" +
              this.colore.U_THE_COLORE +
              "_b.jpg";
          }
          data = {
            modello: this.getActiveConfiguration.frontale.U_THE_MODELLO,
            calibro: this.getActiveConfiguration.calibro,
            ponte: this.getActiveConfiguration.naso,
            coloreFrontale: colFrontale,
            finFrontale: finFrontale,
            misuraAste: this.getActiveConfiguration.misuraAste,
            coloreAste: colAste,
            finAste: finAste,
            nomeFrontale: this.getActiveConfiguration.nome,
            colNomeFrontale: this.getActiveConfiguration.coloreNome,
            nomeExtAstaSx: this.getActiveConfiguration.nomeExtAstaSx,
            coloreNomeExtAstaSx:
              this.getActiveConfiguration.coloreNomeExtAstaSx,
            nomeExtAstaDx: this.getActiveConfiguration.nomeExtAstaDx,
            coloreNomeExtAstaDx:
              this.getActiveConfiguration.coloreNomeExtAstaDx,
            nomeIntAstaSx: this.getActiveConfiguration.nomeIntAstaSx,
            coloreNomeIntAstaSx:
              this.getActiveConfiguration.coloreNomeIntAstaSx,
            nomeIntAstaDx: this.getActiveConfiguration.nomeIntAstaDx,
            coloreNomeIntAstaDx:
              this.getActiveConfiguration.coloreNomeIntAstaDx,
            // note: this.getActiveConfiguration.note,
            message: this.messageMail,
            image: img,
            qrcode: await this.makeQRCode(this.id_for_url),
            qrcodeUrl: this.qrcodeUrl,
            colEst: colEst,
            colInt: colInt,
            id: this.id_for_url,
            isGmail: isGmail,
          };
          // eslint-disable-next-line no-undef
          this.screenshotAndSendSendGridMail(data, emailFrom, emailTo);
          this.shareDialog = false;
          this.shareConfirm = true;
        } else {
          alert("Email non inviata correttamente");
          // console.log("error submit!!");
          // return false;
        }
      });
    },
    // },
    async openShareDialog(v) {
      this.messageMail = this.note;
      this.positionForPrint = v;
      await this.setIdForUrl();
      setTimeout(() => {
        this.shareDialog = true;
      }, 1500);
      // this.$emit("setPositionForPrint");
      this.qrcode = await this.makeQRCode(this.id_for_url);
    },
    makeQRCode(id) {
      let QRCode = require("qrcode");
      let url = window.location.href;
      let id1 = url.split("/");
      if (parseInt(id1[id1.length - 1]) > 0) {
        id1.pop();
        this.$store.state.qrcodeUrl = id1.join("/") + "/" + id;
        return QRCode.toDataURL(id1.join("/") + "/" + id);
      } else {
        this.$store.state.qrcodeUrl = window.location.href + id;
        return QRCode.toDataURL(window.location.href + id);
      }
    },
  },
  computed: {
    // ...mapState([
    //   "frontale",
    //   "calibro",
    //   "naso",
    //   "colore",
    //   "finitura",
    //   "coloreAste",
    //   "misuraAste",
    //   "nome",
    //   "nomeExtAstaSx",
    //   "nomeIntAstaSx",
    //   "nomeExtAstaDx",
    //   "nomeIntAstaDx",
    //   "confirm",
    //   "qrcode",
    //   "id_for_url",
    //   "qrcodeUrl",
    //   "note",
    //   "isOnSite",
    // ]),
    frontale() {
      return this.$store.state.configurator.frontale;
    },
    calibro() {
      return this.$store.state.configurator.calibro;
    },
    naso() {
      return this.$store.state.configurator.naso;
    },
    colore() {
      return this.$store.state.configurator.colore;
    },
    finitura() {
      return this.$store.state.configurator.finitura;
    },
    coloreAste() {
      return this.$store.state.configurator.coloreAste;
    },
    misuraAste() {
      return this.$store.state.configurator.misuraAste;
    },
    nome() {
      return this.$store.state.configurator.frontali.name;
    },
    nomeExtAstaSx() {
      return this.$store.state.configurator.aste.extSxName;
    },
    nomeIntAstaSx() {
      return this.$store.state.configurator.aste.intSxName;
    },
    nomeExtAstaDx() {
      return this.$store.state.configurator.aste.extDxName;
    },
    nomeIntAstaDx() {
      return this.$store.state.configurator.aste.intDxName;
    },
    confirm() {
      return this.$store.state.configurator.confirm;
    },
    qrcode() {
      return this.$store.state.configurator.qrcode;
    },
    id_for_url() {
      return this.$store.state.configurator.id_for_url;
    },
    qrcodeUrl() {
      return this.$store.state.configurator.qrcodeUrl;
    },
    note() {
      return this.$store.state.configurator.note;
    },
    isOnSite() {
      return this.$store.state.isOnSite;
    },
    // ...mapState("frontali", ["colors", "itemsSizes"]),
    colors() {
      return this.$store.state.configurator.frontali.colors;
    },
    itemsSizes() {
      return this.$store.state.configurator.frontali.itemsSizes;
    },
    // ...mapState("aste", ["allColors"]),
    allColors() {
      return this.$store.state.configurator.aste.allColors;
    },
    // ...mapGetters("cart", ["canAddToCart"]),
    canAddToCart() {
      return this.$store.getters["configurator/cart/canAddToCart"];
    },
    // ...mapGetters("user", { userIsGuest: "isGuest" }),
    userIsGuest() {
      return this.$store.getters["configurator/user/isGuest"];
    },
    // ...mapGetters("frontali", ["checkErrors"]),
    checkErrors() {
      return this.$store.getters["configurator/frontali/checkErrors"];
    },
    // ...mapGetters("aste", { templesErrors: "checkErrors" }),
    templesErrors() {
      return this.$store.getters["configurator/aste/checkErrors"];
    },
    // ...mapGetters(["getActiveConfiguration"]),
    getActiveConfiguration() {
      return this.$store.getters["configurator/getActiveConfiguration"];
    },
    configurationComplete() {
      return this.$store.getters["configurator/configurationComplete"];
    },
    modelErrors() {
      let e = jslinq(this.checkErrors);
      return e
        .where((error) => {
          return error.label == "model" || error.label == "size";
        })
        .toList();
    },
    colorErrors() {
      let e = jslinq(this.checkErrors);
      return e
        .where((error) => {
          return error.label == "color";
        })
        .toList();
    },
    canPrint() {
      return (
        this.isCompleted("MONTATURA") &&
        this.isCompleted("COLORE") &&
        this.isCompleted("ASTE")
      );
    },

    positionForPrint: {
      get() {
        return this.$store.state.configurator.positionForPrint;
      },

      set(value) {
        this.$store.commit("configurator/setPositionForPrint", value);
      },
    },
  },
  watch: {
    isClosed(newVal) {
      if (newVal) {
        this.$emit("close");
        this.setNavMenuVisible(true);
      } else {
        this.$emit("open");
        this.setNavMenuVisible(false);
      }
    },
    configurationComplete(newV) {
      if (newV == true) {
        this.isConfComplete = true;
        console.log(this.isConfComplete, "this.isConfComplete1");
        console.log(this.configurationComplete, "this.configurationComplete1");
      } else if (newV == false) {
        this.isConfComplete = false;
        console.log(this.isConfComplete, "this.isConfComplete2");
        console.log(this.isConfComplete, "this.configurationComplete2");
      }
    },
  },
};
</script>

<style lang="less">
#menu {
  @menu-top-height: 60px;
  height: 100vh;

  .menu-selected {
    background-color: @--color-text-secondary;
    height: 15%;
    .title-menu-selected {
      color: @--color-warning;
      margin-top: 15%;
    }
    .close-menu-selected {
      margin-top: 11%;
    }
  }

  @media (max-width: 1200px) {
    .close-menu-button {
      margin-top: -15%;
    }
  }

  @media (max-width: 992px) {
    .list-menu {
      li {
        width: 25%;
      }
    }

    .close-menu-button {
      margin-top: -19%;
    }
  }
  @media (max-width: 767px) {
    .list-menu {
      li {
        width: 25% !important;
        &.sole {
          width: 20% !important;
        }
      }
    }
  }
  @media (min-width: 1201px) {
    .close-menu-button {
      margin-top: -5%;
    }
  }

  .close-menu-button,
  .action-list-left {
    position: absolute;
    cursor: pointer;
    .el-tooltip {
      height: 48px;
    }
  }

  .close-menu-button {
    height: 48px;
    right: 30px;

    img {
      max-height: 100%;
      max-width: 100%;
      transition-duration: 0.5s;
      &.rotate-up {
        transform: rotate(180deg);
      }
    }
  }

  .action-list-left {
    margin: 0;
    list-style-type: none;
    padding: 0;
    left: 30px;
    li {
      padding-top: 8px;
      & > div {
        height: 60px;
        img {
          max-height: 100%;
          max-width: 100%;
          cursor: pointer;
        }

        &.disabled {
          img {
            cursor: not-allowed;
            filter: brightness(1.5);
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .action-list-left {
      margin-top: -22%;
    }
  }

  @media (max-width: 992px) {
    .action-list-left {
      margin-top: -27%;
    }
  }
  @media (min-width: 1201px) {
    .action-list-left {
      margin-top: -9%;
    }
  }
  @media (max-width: 767px) {
    .action-list-left {
      margin-top: -40%;
      position: fixed;
      bottom: 155px;
    }
  }

  @media (max-width: 767px) {
    .button-menu {
      position: fixed;
      bottom: 55px;
      width: 100%;
    }
  }

  .button-menu {
    // height: 33px;
    background-color: @--color-text-secondary;

    @media (max-width: 992px) {
      .list-menu {
        li {
          width: 25%;
        }
      }
    }
    @media (min-width: 993px) {
      .list-menu {
        li {
          width: 15%;
        }
      }
    }
    @media (max-width: 1200px) {
      .list-menu {
        li {
          width: 25%;
        }
      }
    }
    .list-menu {
      list-style-type: none;
      display: flex;
      align-content: flex-start;
      justify-content: center;
      margin: 0px;
      padding: 0px;

      li {
        display: flex;
        position: relative;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;

        margin-top: (@menu-top-height + 28px) * -1;
        z-index: 100;
        background-color: 0xf4f4f4;

        .el-divider {
          position: absolute;
          right: -8px;
          height: 30px;
          color: @--color-primary;
          top: 15px;
        }

        &.isActive {
          background-color: @--color-text-secondary;
        }

        .img-div-black {
          display: flex !important;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          height: @menu-top-height;
          width: 100px;
          cursor: pointer;
        }

        &.hidden {
          visibility: hidden;
        }

        .img-div-orange {
          display: flex !important;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          height: @menu-top-height;
          width: 100px;
          cursor: pointer;
          // background-color: @--color-text-secondary;

          &.isActive {
            background-color: @--color-text-secondary;
          }

          &.hidden {
            visibility: hidden;
          }
        }

        img {
          // height: 75px;
          height: @menu-top-height;
        }
        .list-menu-title {
          color: @--color-warning;
          height: 28px;
          font-weight: 900;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .object-menu {
      .body-object-menu {
        width: 100%;
        height: 79vh;
        // height: 100%;
        // height: ~"calc(100% - 66%)";
      }
    }
  }

  @media (min-width: 768px) {
    .object-menu {
      .body-object-menu {
        width: 100%;
        height: 35vh;
        // height: 100%;
        // height: ~"calc(100% - 66%)";
      }
    }
  }

  .object-menu {
    height: 100%;
    // .body-object-menu {
    //   width: 100%;
    //   height: 35vh;
    //   // height: 100%;
    //   // height: ~"calc(100% - 66%)";
    // }
  }
  .footer-button {
    // background-color: @--color-primary;
    height: 55px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;

    &.hidden {
      visibility: hidden;
    }

    .buttons-group {
      width: 100%;

      .reset100 {
        width: 100% !important;
      }

      .reset {
        width: 50%;
      }
      .aggiungi {
        width: 50%;
      }
    }
    .el-button {
      height: 55px;
      padding: 0px;
    }
  }

  .share-dialog {
    padding-top: 5%;
    // font-weight: 500;
    background-color: rgba(0, 0, 0, 0.59);
    .el-dialog {
      // color: @--color-primary;
      border: 1px solid @--color-primary;
      box-shadow: 0 0px 0px rgb(0 0 0);
      border-radius: 0px;
      background-color: transparent;

      .el-dialog__header {
        padding: 50px 0px 0px 0px;
        background-color: #fff;
      }

      .el-dialog__title {
        color: @--color-warning;
        font-size: 28px;
      }

      .el-dialog__body {
        background-color: #fff;
        // height: 34vh;
        padding: 20px 0px 0px 0px;
        // margin-left: -1px;
        // margin-right: -2px;
        .input-mail-from {
          width: 75%;
          padding-bottom: 3%;
          display: flex;
          flex-direction: column;
          padding-left: 13%;

          .el-form-item__content {
            .el-input__inner {
              color: @--color-warning !important;
            }
          }
        }
        .input-mail-to {
          width: 75%;
          padding-bottom: 5%;
          display: flex;
          flex-direction: column;
          padding-left: 13%;

          .el-form-item__content {
            .el-input__inner {
              color: @--color-warning !important;
            }
          }
        }
        .message-mail {
          width: 75%;
          padding-bottom: 1%;
          .el-textarea__inner {
            color: @--color-warning;
          }
        }
        .campi-obblig {
          display: flex;
          justify-content: space-around;
          width: 50%;
          padding-bottom: 5%;
        }
        .el-button-group {
          width: 100%;
          // height: 50px ;
          .el-button--default {
            width: 50%;
            float: left;
            font-size: 16px;
            // margin-bottom: -1px;
          }
          .el-button--primary {
            width: 50%;
            float: right;
            font-size: 16px;
            // margin-bottom: -1px;
          }
        }
        .el-form-item {
          margin-bottom: 0px !important;
          .el-form-item__label {
            padding: 0px;
            line-height: 25px;
          }
          .el-form-item__label:before {
            color: #8b9091;
          }
        }
      }
    }
  }

  .sendmail-dialog {
    padding-top: 10%;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.59);

    .el-dialog__title {
      color: @--color-warning !important;
      font-weight: 900;
      font-size: 25px !important;
    }

    @media (max-width: 767px) {
      .el-dialog {
        .el-dialog__body {
          img {
            top: 30%;
            right: 30%;
          }
        }
      }
    }
    @media (min-width: 768px) {
      .el-dialog {
        .el-dialog__body {
          img {
            top: 35%;
            right: 35%;
          }
        }
      }
    }

    .el-dialog {
      color: @--color-primary;
      border: 1px solid @--color-primary;
      box-shadow: 0 0px 0px rgb(0 0 0);
      border-radius: 0px;
      background-color: transparent;
      .el-dialog__header {
        background-color: #fff;
        height: 30vh;
        padding: 50px 0px 0px 0px;
      }
      .el-dialog__body {
        padding: 7px 0px 0px 0px;
        margin-left: -1px;
        margin-right: -2px;
        img {
          height: 150px;
          position: absolute;
        }
        .close-button {
          width: 100%;
          font-size: 18px;
        }
      }
    }
  }
}
</style>