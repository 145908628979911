<template>
  <div id="testo">
    <el-row class="posizioni" :span="24">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 6, offset: 1 }"
        :lg="{ span: 4, offset: 3 }"
      >
        <el-col class="frontale" :span="24">
          <span>FRONTALE</span>
        </el-col>
        <el-col :class="{ isActive: selezionaPos == 'testoCilSx' }" :span="24">
          <el-button type="text" @click="selezionaPos = 'testoCilSx'"
            >DESTRO</el-button
          >
        </el-col>
      </el-col>
      <el-col
        class="asta-interna"
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 7, offset: 1 }"
        :lg="{ span: 6, offset: 1 }"
      >
        <el-col
          class="ast-int"
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 23, offset: 1 }"
        >
          <span>ASTE INTERNE</span>
        </el-col>
        <el-col
          :class="{ isActive: selezionaPos == 'testoIntSx' }"
          :xs="{ span: 12, offset: 0 }"
          :sm="{ span: 10, offset: 1 }"
        >
          <el-button type="text" @click="selezionaPos = 'testoIntSx'"
            >SINISTRA</el-button
          >
        </el-col>
        <el-col
          v-if="windowWidth >= 768"
          :span="
            selezionaPos != 'testoIntSx' && selezionaPos != 'testoIntDx' ? 1 : 0
          "
          :offset="
            selezionaPos != 'testoIntSx' && selezionaPos != 'testoIntDx' ? 1 : 0
          "
        >
          <el-divider direction="vertical"></el-divider>
        </el-col>
        <el-col
          :class="{ isActive: selezionaPos == 'testoIntDx' }"
          :xs="{ span: 12, offset: 0 }"
          :sm="{
            span: 10,
            offset:
              `${selezionaPos}` != 'testoIntSx' &&
              `${selezionaPos}` != 'testoIntDx'
                ? 1
                : 3,
          }"
        >
          <el-button type="text" @click="selezionaPos = 'testoIntDx'"
            >DESTRA</el-button
          >
        </el-col>
      </el-col>
      <el-col
        class="ast-esterna"
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 7, offset: 1 }"
        :lg="{ span: 6, offset: 1 }"
      >
        <el-col
          class="ast-est"
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 23, offset: 1 }"
        >
          <span>ASTE ESTERNE</span>
        </el-col>
        <el-col
          :class="{ isActive: selezionaPos == 'testoEstSx' }"
          :xs="{ span: 12, offset: 0 }"
          :sm="{ span: 10, offset: 1 }"
        >
          <el-button type="text" @click="selezionaPos = 'testoEstSx'"
            >SINISTRA</el-button
          >
        </el-col>
        <el-col
          v-if="windowWidth >= 768"
          :span="
            selezionaPos != 'testoEstSx' && selezionaPos != 'testoEstDx' ? 1 : 0
          "
          :offset="
            selezionaPos != 'testoEstSx' && selezionaPos != 'testoEstDx' ? 1 : 0
          "
        >
          <el-divider direction="vertical"></el-divider>
        </el-col>
        <el-col
          :class="{ isActive: selezionaPos == 'testoEstDx' }"
          :xs="{ span: 12, offset: 0 }"
          :sm="{
            span: 10,
            offset:
              `${selezionaPos}` != 'testoEstSx' &&
              `${selezionaPos}` != 'testoEstDx'
                ? 1
                : 3,
          }"
        >
          <el-button type="text" @click="selezionaPos = 'testoEstDx'"
            >DESTRA</el-button
          >
        </el-col>
      </el-col>
    </el-row>
    <el-row class="colori-preview">
      <el-col
        class="nome-colore"
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 11, offset: 1 }"
        :lg="{ span: 9, offset: 3 }"
      >
        <el-input
          v-if="
            this.selezionaPos == 'testoEstSx' ||
            this.selezionaPos == 'testoEstDx'
          "
          class="input-nome"
          placeholder="Inserisci il tuo nome"
          maxlength="25"
          show-word-limit
          v-model="testo"
        ></el-input>
        <el-input
          v-else
          class="input-nome"
          placeholder="Inserisci il tuo nome"
          maxlength="10"
          show-word-limit
          v-model="testo"
        ></el-input>
        <!-- <el-input
          v-if="this.selezionaPos == 'testoEstSx' || this.selezionaPos == 'testoEstDx'"
          placeholder="Inserisci il tuo nome"
          v-model="testo"
          class="input-nome"
          maxlength="25"
          show-word-limit
          :style="{fontFamily: font}"
        >
          <el-select v-model="font" slot="prepend" :style="{fontFamily: font}">
            <el-option label="Stampatello" value="Batang"></el-option>
            <el-option label="Corsivo" value="Lucida Handwriting"></el-option>
          </el-select>
        </el-input>
        <el-input
          v-else
          placeholder="Inserisci il tuo nome"
          v-model="testo"
          class="input-nome"
          maxlength="10"
          show-word-limit
          :style="{fontFamily: font}"
        >
          <el-select v-model="font" slot="prepend" :style="{fontFamily: font}">
            <el-option label="Stampatello" value="Batang"></el-option>
            <el-option label="Corsivo" value="Lucida Handwriting"></el-option>
          </el-select>
        </el-input>-->

        <div class="colori">
          <div v-for="(c, id) in colors" :key="id">
            <div class="img-colori" @click="setCol(c)">
              <el-avatar
                v-model="colore"
                :class="{
                  oro: c.name == 'oro',
                  argento: c.name == 'argento',
                  bordered: c.name == 'bianco',
                  nc: c.name == 'nessun-colore',
                  active: c.name == selectedColor.name,
                }"
                :size="100"
                :style="{ backgroundColor: c.colorCode }"
              />
              <!-- <span
                  class="span-colori"
                  :class="{active: c.colorCode == colore.colorCode}"
              >{{c.name.toUpperCase()}}</span>-->
            </div>
          </div>
        </div>
      </el-col>
      <el-col
        class="preview"
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 11, offset: 0 }"
        :lg="{ span: 9, offset: 0 }"
      >
        <personal-name-preview
          v-if="this.selezionaPos == 'testoCilSx'"
          class="preview-nome-front"
          :name="testo"
          :colore="selectedColor"
          :font="font"
        />
        <int-asta-sx-preview
          v-else-if="this.selezionaPos == 'testoIntSx'"
          class="preview-nome"
          :name="testo"
          :colore="selectedColor"
          :font="font"
          :astaColor="coloreAste.U_THE_COLORE"
        />
        <ext-asta-sx-preview
          v-else-if="this.selezionaPos == 'testoEstSx'"
          class="preview-nome"
          :name="testo"
          :colore="selectedColor"
          :font="font"
          :astaColor="coloreAste.U_THE_COLORE"
        />
        <int-asta-dx-preview
          v-else-if="this.selezionaPos == 'testoIntDx'"
          class="preview-nome"
          :name="testo"
          :colore="selectedColor"
          :font="font"
          :astaColor="coloreAste.U_THE_COLORE"
        />
        <ext-asta-dx-preview
          v-else-if="this.selezionaPos == 'testoEstDx'"
          class="preview-nome"
          :name="testo"
          :colore="selectedColor"
          :font="font"
          :astaColor="coloreAste.U_THE_COLORE"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { mapMutations, mapState } from "vuex";
// eslint-disable-next-line no-unused-vars
import jslinq from "jslinq";
// eslint-disable-next-line no-unused-vars
import _ from "lodash";

import PersonalNamePreview from "./PersonalNamePreview.vue";
import ExtAstaDxPreview from "./ExtAstaDxPreview.vue";
import ExtAstaSxPreview from "./ExtAstaSxPreview.vue";
import IntAstaDxPreview from "./IntAstaDxPreview.vue";
import IntAstaSxPreview from "./IntAstaSxPreview.vue";

export default {
  components: {
    PersonalNamePreview,
    ExtAstaDxPreview,
    ExtAstaSxPreview,
    IntAstaDxPreview,
    IntAstaSxPreview,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      activeColor: {},
      selezionaPos: "testoCilSx",
      colors: [
        {
          name: "nero",
          colorCode: "#212121",
          colorClass: "grey darken-4",
          checkColor: "white",
        },
        {
          name: "bianco",
          colorCode: "#fafafa",
          colorClass: "grey lighten-5",
          checkColor: "grey",
        },
        {
          name: "verde",
          colorCode: "#4caf50",
          colorClass: "green",
          checkColor: "white",
        },
        {
          name: "blu",
          colorCode: "#2196f3",
          colorClass: "blu",
          checkColor: "white",
        },
        {
          name: "giallo",
          colorCode: "#FFEB3B",
          colorClass: "yellow",
          checkColor: "grey",
        },
        {
          name: "rosso",
          colorCode: "#f44336",
          colorClass: "red",
          checkColor: "white",
        },
        {
          name: "rosa",
          colorCode: "#F8BBD0",
          colorClass: "pink",
          checkColor: "white",
        },
        {
          name: "arancione",
          colorCode: "#FF9800",
          colorClass: "orange",
          checkColor: "white",
        },
        {
          name: "viola",
          colorCode: "#AB47BC",
          colorClass: "purple",
          checkColor: "white",
        },
        {
          name: "oro",
          colorCode: "#FFD700",
          colorClass: "gold",
          checkColor: "white",
        },
        {
          name: "argento",
          colorCode: "#D3D3D3",
          colorClass: "silver",
          checkColor: "white",
        },
        {
          name: "azzurro",
          colorCode: "#81D4FA",
          colorClass: "light blue",
          checkColor: "white",
        },
        {
          name: "nessun-colore",
          colorCode: "#f4f4f4",
          colorClass: "no color",
          checkColor: "",
        },
      ],
    };
  },

  mounted() {
    // if () {
    // }
    // TODO: sbagliato

    if (!this.coloreNome) {
      this.setColoreNome("nessun-colore");
    }

    if (!this.coloreNomeExtAstaSx) {
      this.setColorNameExtAstaSx("nessun-colore");
    }

    if (!this.coloreNomeIntAstaSx) {
      this.setColorNameIntAstaSx("nessun-colore");
    }

    if (!this.coloreNomeExtAstaDx) {
      this.setColorNameExtAstaDx("nessun-colore");
    }

    if (!this.coloreNomeIntAstaDx) {
      this.setColorNameIntAstaDx("nessun-colore");
    }
  },

  methods: {
    // ...mapMutations([
    //   "setColoreNome",
    //   "setColorNameIntAstaSx",
    //   "setColorNameExtAstaSx",
    //   "setColorNameIntAstaDx",
    //   "setColorNameExtAstaDx",
    //   "setName",
    //   "setNameIntAstaSx",
    //   "setNameExtAstaSx",
    //   "setNameIntAstaDx",
    //   "setNameExtAstaDx",
    //   "setFontNome",
    //   "setFontNameIntAstaSx",
    //   "setFontNameExtAstaSx",
    //   "setFontNameIntAstaDx",
    //   "setFontNameExtAstaDx",
    // ]),

    setName(n) {
      this.$store.commit("configurator/frontali/setName", n);
    },
    setFontNome(nf) {
      this.$store.commit("configurator/frontali/setNameFont", nf);
    },
    setColoreNome(nc) {
      this.$store.commit("configurator/frontali/setNameColor", nc);
    },
    setNameExtAstaSx(neas) {
      this.$store.commit("configurator/aste/setExtSxName", neas);
    },
    setColorNameExtAstaSx(cneas) {
      this.$store.commit("configurator/aste/setExtSxNameColor", cneas);
    },
    setFontNameExtAstaSx(fneas) {
      this.$store.commit("configurator/aste/setExtSxNameFont", fneas);
    },
    setNameIntAstaSx(nias) {
      this.$store.commit("configurator/aste/setIntSxName", nias);
    },
    setColorNameIntAstaSx(cnias) {
      this.$store.commit("configurator/aste/setIntSxNameColor", cnias);
    },
    setFontNameIntAstaSx(fnias) {
      this.$store.commit("configurator/aste/setIntSxNameFont", fnias);
    },
    setNameExtAstaDx(nead) {
      this.$store.commit("configurator/aste/setExtDxName", nead);
    },
    setColorNameExtAstaDx(cnead) {
      this.$store.commit("configurator/aste/setExtDxNameColor", cnead);
    },
    setFontNameExtAstaDx(fnead) {
      this.$store.commit("configurator/aste/setExtDxNameFont", fnead);
    },
    setNameIntAstaDx(niad) {
      this.$store.commit("configurator/aste/setIntDxName", niad);
    },
    setColorNameIntAstaDx(cniad) {
      this.$store.commit("configurator/aste/setIntDxNameColor", cniad);
    },
    setFontNameIntAstaDx(fniad) {
      this.$store.commit("configurator/aste/setIntDxNameFont", fniad);
    },

    setCol(val) {
      this.colore = val.name;
    },

    scrollLeft() {
      let container = document.getElementsByClassName("colori");
      container[0].scrollLeft -= 250;
    },

    scrollRight() {
      let container = document.getElementsByClassName("colori");
      container[0].scrollLeft += 250;
    },
  },

  computed: {
    // ...mapState([
    //   "coloreAste",
    //   "nome",
    //   "fontNome",
    //   "coloreNome",
    //   "nomeExtAstaSx",
    //   "fontNomeExtAstaSx",
    //   "coloreNomeExtAstaSx",
    //   "nomeIntAstaSx",
    //   "fontNomeIntAstaSx",
    //   "coloreNomeIntAstaSx",
    //   "nomeExtAstaDx",
    //   "fontNomeExtAstaDx",
    //   "coloreNomeExtAstaDx",
    //   "nomeIntAstaDx",
    //   "fontNomeIntAstaDx",
    //   "coloreNomeIntAstaDx",
    // ]),

    coloreAste() {
      return this.$store.state.configurator.coloreAste;
    },

    nome() {
      return this.$store.state.configurator.frontali.name;
    },
    fontNome() {
      return this.$store.state.configurator.frontali.nameFont;
    },
    coloreNome() {
      return this.$store.state.configurator.frontali.nameColor;
    },

    nomeExtAstaSx() {
      return this.$store.state.configurator.aste.extSxName;
    },
    fontNomeExtAstaSx() {
      return this.$store.state.configurator.aste.extSxNameFont;
    },
    coloreNomeExtAstaSx() {
      return this.$store.state.configurator.aste.extSxNameColor;
    },
    nomeIntAstaSx() {
      return this.$store.state.configurator.aste.intSxName;
    },
    fontNomeIntAstaSx() {
      return this.$store.state.configurator.aste.intSxNameFont;
    },
    coloreNomeIntAstaSx() {
      return this.$store.state.configurator.aste.intSxNameColor;
    },
    nomeExtAstaDx() {
      return this.$store.state.configurator.aste.extDxName;
    },
    fontNomeExtAstaDx() {
      return this.$store.state.configurator.aste.extDxNameFont;
    },
    coloreNomeExtAstaDx() {
      return this.$store.state.configurator.aste.extDxNameColor;
    },
    nomeIntAstaDx() {
      return this.$store.state.configurator.aste.intDxName;
    },
    fontNomeIntAstaDx() {
      return this.$store.state.configurator.aste.intDxNameFont;
    },
    coloreNomeIntAstaDx() {
      return this.$store.state.configurator.aste.intDxNameColor;
    },

    colore: {
      get() {
        if (this.selezionaPos == "testoCilSx") {
          return this.coloreNome;
        } else if (this.selezionaPos == "testoIntSx") {
          return this.coloreNomeIntAstaSx;
        } else if (this.selezionaPos == "testoEstSx") {
          return this.coloreNomeExtAstaSx;
        } else if (this.selezionaPos == "testoIntDx") {
          return this.coloreNomeIntAstaDx;
        } else if (this.selezionaPos == "testoEstDx") {
          return this.coloreNomeExtAstaDx;
        }

        return "";
      },
      set(val) {
        if (this.selezionaPos == "testoCilSx") {
          this.setColoreNome(val);
        } else if (this.selezionaPos == "testoIntSx") {
          this.setColorNameIntAstaSx(val);
        } else if (this.selezionaPos == "testoEstSx") {
          this.setColorNameExtAstaSx(val);
        } else if (this.selezionaPos == "testoIntDx") {
          this.setColorNameIntAstaDx(val);
        } else if (this.selezionaPos == "testoEstDx") {
          this.setColorNameExtAstaDx(val);
        }
      },
    },

    testo: {
      get() {
        if (this.selezionaPos == "testoCilSx") {
          return this.nome;
        } else if (this.selezionaPos == "testoIntSx") {
          return this.nomeIntAstaSx;
        } else if (this.selezionaPos == "testoEstSx") {
          return this.nomeExtAstaSx;
        } else if (this.selezionaPos == "testoIntDx") {
          return this.nomeIntAstaDx;
        } else if (this.selezionaPos == "testoEstDx") {
          return this.nomeExtAstaDx;
        }

        return "";
      },
      set(val) {
        if (this.selezionaPos == "testoCilSx") {
          this.setName(
            val.replace(
              /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
              ""
            )
          );
        } else if (this.selezionaPos == "testoIntSx") {
          this.setNameIntAstaSx(
            val.replace(
              /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
              ""
            )
          );
        } else if (this.selezionaPos == "testoEstSx") {
          this.setNameExtAstaSx(
            val.replace(
              /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
              ""
            )
          );
        } else if (this.selezionaPos == "testoIntDx") {
          this.setNameIntAstaDx(
            val.replace(
              /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
              ""
            )
          );
        } else if (this.selezionaPos == "testoEstDx") {
          this.setNameExtAstaDx(
            val.replace(
              /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
              ""
            )
          );
        }
      },
    },

    font: {
      get() {
        if (this.selezionaPos == "testoCilSx") {
          return this.fontNome;
        } else if (this.selezionaPos == "testoIntSx") {
          return this.fontNomeIntAstaSx;
        } else if (this.selezionaPos == "testoEstSx") {
          return this.fontNomeExtAstaSx;
        } else if (this.selezionaPos == "testoIntDx") {
          return this.fontNomeIntAstaDx;
        } else if (this.selezionaPos == "testoEstDx") {
          return this.fontNomeExtAstaDx;
        }

        return "";
      },
      set(val) {
        if (this.selezionaPos == "testoCilSx") {
          this.setFontNome(val);
        } else if (this.selezionaPos == "testoIntSx") {
          this.setFontNameIntAstaSx(val);
        } else if (this.selezionaPos == "testoEstSx") {
          this.setFontNameExtAstaSx(val);
        } else if (this.selezionaPos == "testoIntDx") {
          this.setFontNameIntAstaDx(val);
        } else if (this.selezionaPos == "testoEstDx") {
          this.setFontNameExtAstaDx(val);
        }
      },
    },

    selectedColor() {
      return this.colors.filter((c) => {
        return c.name == this.colore;
      })[0];
    },
  },
};
</script>

<style lang="less">
#testo {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  // background-color: @--color-text-secondary;

  .isActive {
    background-color: @--color-warning;
    span {
      color: @--color-white !important;
    }
  }

  @media (max-width: 767px) {
    .posizioni {
      background-color: @--color-text-secondary;
      height: 40%;
    }

    .colori-preview {
      height: 40%;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .colori-preview::-webkit-scrollbar {
      display: none;
    }

    .nome-colore {
      padding-right: 0;
      margin-top: 10px;
    }

    .preview {
      height: ~"calc(100% - 34%)";
    }

    .asta-interna {
      margin-top: 3%;
      margin-bottom: 3%;
    }

    .img-colori {
      width: 50px;
      height: 50px;

      span.el-avatar {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }
  @media (min-width: 768px) {
    .posizioni {
      display: flex;
      flex-direction: row;
      // justify-content: center;
      background-color: @--color-text-secondary;
      height: 14%;
    }

    .colori-preview {
      display: flex;
      flex-direction: row;
      height: 21%;
    }

    .nome-colore {
      padding-right: 10px;
    }

    .preview {
      margin-left: 10px;
      height: ~"calc(100% - 22%)";
    }

    .asta-interna {
      margin-top: 0%;
      margin-bottom: 0%;
    }

    .img-colori {
      width: 45px;
      height: 45px;

      span.el-avatar {
        width: 45px !important;
        height: 45px !important;
      }
    }
  }

  .posizioni {
    // display: flex;
    // flex-direction: row;
    // // justify-content: center;
    // background-color: @--color-text-secondary;
    // height: 14%;

    .frontale {
      border-bottom: solid 2px @--color-primary;
      margin-top: 15px;
      padding-bottom: 10px;
    }

    .asta-interna {
      position: relative;

      .el-divider {
        margin: 10px;
      }

      .ast-int {
        margin-top: 15px;
        padding-bottom: 10px;
        border-bottom: solid 2px @--color-primary;
      }
    }

    .ast-esterna {
      position: relative;
      .el-divider {
        margin: 10px;
      }
      .ast-est {
        margin-top: 15px;
        padding-bottom: 10px;
        border-bottom: solid 2px @--color-primary;
      }
      // .el-divider {
      //   position: absolute;
      //   top: 27px;
      //   color: @--color-primary;
      //   right: 230px;
      // }
    }
  }

  .colori-preview {
    // justify-content: center;
    // padding-top: 65px;
    background-color: @--color-text-secondary;

    .nome-colore {
      // padding-right: 10px;
      // width: 37%;
      height: ~"calc(100% - 22%)";
      // height: 80%;
    }

    .preview {
      background-color: @--color-white;

      // height: 80vh;

      .preview-nome-front {
        // padding-top: 20px;
        width: 250px;
      }

      .preview-nome {
        padding-top: 5px;
        width: 340px;
      }
    }

    .input-nome {
      // width: 100%;
      font-size: 22px;

      @media (max-width: 1200px) {
        .el-input-group__prepend {
          width: 35%;
        }
      }

      @media (max-width: 992px) {
        .el-input-group__prepend {
          width: 25%;
        }
      }
      @media (min-width: 1201px) {
        .el-input-group__prepend {
          width: 25%;
        }
      }

      .el-input-group__prepend {
        // width: 25%;
        background-color: @--color-warning;
        .el-select {
          .el-input--suffix {
            input {
              color: white;
            }
            ::placeholder {
              color: white;
            }
          }
        }
        span {
          .el-icon-arrow-up:before {
            content: "\e6e1";
            color: @--color-white;
          }
        }

        border-radius: 0;
        border: 1px solid @--table-header-background-color;
      }

      .el-input__inner {
        // font-family: "Corsivo-Produzione", sans-serif;
        border: transparent;
        text-align: center;
        color: @--color-warning;
      }

      ::placeholder {
        text-align: center;
      }
    }

    .colori::-webkit-scrollbar {
      display: none;
    }

    .colori {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: space-around;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-behavior: smooth;
      background-color: @--color-white;
      margin-top: 20px;
      height: 63%;
      padding-left: 2%;

      div {
        display: flex;
        align-items: center;
        padding-right: 1px;
        padding-left: 1px;
      }

      .img-colori {
        cursor: pointer;
        position: relative;

        span.el-avatar.el-avatar--circle.active {
          box-shadow: inset 0 0 0 2px @--color-white;
          border: solid @--color-warning 5px;
          // margin: 0 auto;
          // padding: 3%;
          // width: 16em;
          // height: 16em;
          // position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        span.el-avatar.el-avatar--circle.oro {
          background: rgb(205, 192, 0);
          background: linear-gradient(
            45deg,
            rgba(162, 143, 4, 1) 0%,
            rgba(255, 249, 154, 1) 50%,
            rgba(162, 143, 4, 1) 100%
          );
        }

        span.el-avatar.el-avatar--circle.argento {
          background: rgb(182, 182, 182);
          background: linear-gradient(
            45deg,
            rgba(182, 182, 182, 1) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(182, 182, 182, 1) 100%
          );
        }

        span.el-avatar.el-avatar--circle.bordered {
          border: solid @--color-primary 1px;
          &.active {
            border: solid @--color-warning 5px;
          }
        }

        span.el-avatar.el-avatar--circle.nc {
          background: @--table-header-background-color !important;
          border: solid @--color-primary 1px;
          &.active {
            border: solid @--color-warning 5px;
            background: @--table-header-background-color !important;
          }
        }
      }
    }
  }
}
</style>