<template>
  <div id="frontale">
    <div class="arrow-left" :class="{ hidden: windowWidth < 768 }">
      <img :src="require('../../../assets/images/icone/freccia.png')" @click="scrollLeft()" />
    </div>
    <div class="arrow-right" :class="{ hidden: windowWidth < 768 }">
      <img :src="require('../../../assets/images/icone/freccia.png')" @click="scrollRight()" />
    </div>
    <el-row class="filtri">
      <el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 14, offset: 1 }" :lg="{ span: 14, offset: 1 }">
        <el-col class="title" :span="24">MISURE</el-col>
        <el-col v-for="size in modelSizes" :key="size.size_description" class="misure" :span="8" :class="{
          active:
            `${size.size_eye}/${size.size_bridge}` == `${calibro}/${naso}`,
        }">
          <el-button type="text" @click="scegliMisura(size)">
            <span>{{ size | sizeLabel }}</span>
          </el-button>
        </el-col>
      </el-col>
      <el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 7, offset: 1 }" :lg="{ span: 6, offset: 2 }">
        <el-col class="title" :span="24">TIPO</el-col>
        <el-col class="misure" :span="12" :class="{ active: tipo == 'V' }">
          <el-button type="text" @click="setLensesType('V')">
            <span>VISTA</span>
          </el-button>
        </el-col>
        <el-col class="misure" :span="12" :class="{ active: tipo == 'S' }">
          <el-button type="text" @click="setLensesType('S')">
            <span>SOLE</span>
          </el-button>
        </el-col>
      </el-col>
    </el-row>
    <div class="modelli">
      <el-col class="modello-singolo" v-for="item in filteredFront" :key="item.U_THE_MODELLO" :class="{
        active:
          frontale != null
            ? item.U_THE_MODELLO == frontale.U_THE_MODELLO
            : '',
      }" @click="setFrontal(item)">
        <!-- <img :src="modelImageUrl(item.U_THE_MODELLO)" @click="setFrontal(item)" /> -->
        <div @click="setFrontal(item)">
          <model-image :model="item.U_THE_MODELLO.replace('S', '')" />
        </div>
        <span @click="setFrontal(item)">{{ item.U_THE_MODELLO }}</span>
      </el-col>
    </div>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import jslinq from "jslinq";
import _ from "lodash";
import ModelImage from "./ModelImage.vue";

export default {
  components: { ModelImage },
  data() {
    return {
      cercaModello: "",
      windowWidth: window.innerWidth,
    };
  },

  // mounted: function () {
  //   if (this.$route.params.id === undefined && _.isEmpty(this.frontale)) {
  //     this.getItems();
  //   }
  // },

  methods: {
    // ...mapActions("configurator/frontali", ["getItems", "getAllModelsSizes"]),
    // ...mapMutations(["setFrontal", "setSize", "setAsianFitting", "clearSize"]),

    getAllModelsSizes() {
      this.$store.dispatch("configurator/frontali/getAllModelsSizes");
    },

    setFrontal(i) {
      if (this.frontale?.U_THE_SOTTOLINEA != i?.U_THE_SOTTOLINEA) {
        // Reset colors
        this.$store.dispatch("configurator/frontali/setColor", "EP0001");
        this.$store.dispatch("configurator/aste/setColor", i?.U_THE_SOTTOLINEA == 'MYCLAROXL' ? "EP0001" : "PL0001");

        // Reset finishing to Shiny
        this.$store.commit("configurator/setFinishing", "S");
        this.$store.commit("configurator/setTemplesFinishing", "S");
      }
      this.$store.commit("configurator/setFrontal", i);
    },

    setSize(s) {
      this.$store.commit("configurator/setSize", s);
    },

    setAsianFitting(af) {
      this.$store.commit("configurator/setAsianFitting", af);
    },

    clearSize(s) {
      this.$store.commit("configurator/clearSize", s);
    },

    modelImageUrl(model) {
      return "https://media.thema-optical.com/3d_models/" + model + ".svg";
    },
    scrollLeft() {
      let container = document.getElementsByClassName("modelli");
      container[0].scrollLeft -= 250;
    },

    scrollRight() {
      let container = document.getElementsByClassName("modelli");
      container[0].scrollLeft += 250;
    },

    scegliMisura(val) {
      // console.log(this.sizeLabel);
      this.setSize(val);
    },

    setLensesType(val) {
      this.$store.commit("configurator/setLensesType", val);
    },

    setSunLensesModel(l) {
      this.$store.commit("configurator/setSunLensesModel", l);
    },
  },

  computed: {
    // ...mapState("configurator/frontali", ["items", "itemsSizes"]),
    // ...mapState(["frontale", "calibro", "naso", "asianFitting"]),

    tipo() {
      return this.$store.state.configurator.glassType;
    },

    frontale() {
      return this.$store.state.configurator.frontale;
    },
    calibro() {
      return this.$store.state.configurator.calibro;
    },
    naso() {
      return this.$store.state.configurator.naso;
    },
    asianFitting() {
      return this.$store.state.configurator.asianFitting;
    },

    items() {
      return this.$store.state.configurator.frontali.items;
    },
    itemsSizes() {
      return this.$store.state.configurator.frontali.itemsSizes;
    },
    queryFront() {
      return jslinq(this.items);
    },
    queryFrontSizes() {
      return jslinq(this.itemsSizes);
    },
    filteredFront() {
      let self = this;

      let m = this.items.filter((i) => {
        // if (i.U_THE_CUSMODEL != "C") console.log("no C", i);
        if (this.tipo == "V") {
          return i.U_THE_CUSMODEL == "C" && i.U_THE_CATMERC == "OVP";
        } else {
          return i.U_THE_CUSMODEL == "C" && i.U_THE_CATMERC == "OSP";
        }
      });

      m.sort(function compareFn(a, b) {
        if (self.tipo === "V") {
          return (
            a.U_THE_MODELLO.replace("MYC-", "") -
            b.U_THE_MODELLO.replace("MYC-", "")
          );
        } else {
          return (
            a.U_THE_MODELLO.replace("MYC-", "").replace("S", "") -
            b.U_THE_MODELLO.replace("MYC-", "").replace("S", "")
          );
        }
      });

      if (this.cercaModello && this.cercaModello != "" && m) {
        return m.filter(function (item) {
          return item.U_THE_MODELLO.indexOf(this.cercaModello) >= 0;
        });
      } else {
        return m; //this.items;
      }
    },
    modelSizes() {
      if (!_.isEmpty(this.frontale)) {
        return this.queryFrontSizes
          .where((s) => {
            return s.U_THE_MODELLO == this.frontale.U_THE_MODELLO;
          })
          .toList();
      }

      return [];
    },
  },

  watch: {
    async frontale(newVal) {
      if (newVal) {
        // console.log("frontale", this.frontale);
        if (!_.isEmpty(this.frontale)) {
          await this.getAllModelsSizes();
          let a = this.queryFrontSizes
            .where((c) => {
              return (
                c.U_THE_MODELLO == this.frontale.U_THE_MODELLO &&
                c.size_eye == this.calibro &&
                c.size_bridge == this.naso
              );
            })
            .toList();
          if (a.length == 0) {
            this.clearSize();
            let b = this.queryFrontSizes.firstOrDefault((c) => {
              return (
                c.U_THE_MODELLO == this.frontale.U_THE_MODELLO &&
                (c.size_description == "REGULAR" || c.size_description == 'MEDIUM')
              );
            });
            this.setSize(b);
          } else {
            this.setSize(a[0]);
          }
        }
      }
    },

    tipo(newV, oldV) {
      let catMerc = "";
      if (newV == "V") {
        catMerc = "OVP";
      } else if (newV == "S") {
        catMerc = "OSP";
      }

      if (oldV == "S" && newV == "V") {
        this.setSunLensesModel(null);
      }

      if (this.items) {
        const m = this.items.filter((i) => {
          return (
            i.U_THE_MODELLO.replace("S", "") ==
            this.frontale.U_THE_MODELLO.replace("S", "") &&
            i.U_THE_CATMERC == catMerc
          );
        });

        if (m.length > 0) {
          this.setFrontal(m[0]);
        }

        if (this.itemSizes && this.calibro && this.naso) {
          const s = this.itemSizes.filter((is) => {
            return (
              is.ItemCode == m[0].ItemCode &&
              parseInt(is.size_eye) == this.calibro &&
              parseInt(is.size_bridge) == this.naso
            );
          });

          if (s.length > 0) {
            this.setSize(s[0]);
          }
        }
      }
    },
  },
};
</script>

<style lang="less">
#frontale {
  .filtri {
    .cerca-modello {
      width: 20%;
    }

    .misure-modello {
      padding: 0% 5%;
    }
  }

  @media (max-width: 767px) {
    .filtri>div {
      height: 50% !important;
      // display: inline-block;
    }

    .modelli {
      height: 100%;
      flex-wrap: wrap;
      background-color: @--color-text-secondary;

      .modello-singolo {
        width: 50%;
        padding-top: 10px;

        img {
          width: 100%;
          // padding: 10px 50px;
          cursor: pointer;
        }

        span {
          cursor: pointer;
          padding-bottom: 15px;
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 768px) {

    // .filtri > div {
    //   height: 50% !important;
    //   // display: inline-block;
    // }
    .modelli {
      height: 80%;
      padding-left: 75px;
      padding-right: 75px;

      .modello-singolo {
        justify-content: center;

        img {
          width: 200px;
          padding: 10px 30px;
          cursor: pointer;
        }

        span {
          cursor: pointer;
          padding: 10px 30px;
          width: 200px;
        }
      }
    }
  }

  .modelli::-webkit-scrollbar {
    display: none;
  }

  .modelli {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    .modello-singolo {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;

      span,
      svg {
        transition: all 0.2s ease;
      }

      &.active {
        span {
          color: @--color-warning;
        }

        svg {
          fill: @--color-warning !important;
        }
      }
    }
  }
}
</style>