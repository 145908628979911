<template>
  <div id="colore">
    <div class="arrow-left" :class="{ hidden: windowWidth < 768 }">
      <img :src="require('../../../assets/images/icone/freccia.png')" @click="scrollLeft()" />
    </div>
    <div class="arrow-right" :class="{ hidden: windowWidth < 768 }">
      <img :src="require('../../../assets/images/icone/freccia.png')" @click="scrollRight()" />
    </div>

    <el-row class="filtri">
      <el-col v-if="windowWidth > 1201" :span="24">
        <el-col class="finitura" :lg="{ span: 5, offset: 1 }">
          <el-col class="title" :span="24">FINITURA</el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'S' }">
            <el-button @click="setFin('S')" type="text">LUCIDA</el-button>
            <el-divider v-if="finitura === 'N'" direction="vertical"></el-divider>
          </el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'M' }">
            <el-button @click="setFin('M')" type="text">OPACA</el-button>
            <el-divider v-if="finitura === 'S'" direction="vertical"></el-divider>
          </el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'N' }">
            <el-button @click="setFin('N')" type="text">NATURALE</el-button>
          </el-col>
        </el-col>
        <el-col class="categoria" :lg="{ span: 16, offset: 1 }">
          <el-col class="title" :span="24">CATEGORIA COLORE</el-col>
          <el-col class="valore cat" :class="{
      active:
        checkboxCategorie != ''
          ? cat == checkboxCategorie
          : cat == catColore,
    }" v-for="(cat, i) in categories" :key="i" :span="3">
            <el-button @click="(category = { i, cat }), (checkboxCategorie = cat)" type="text">{{ cat | demi
              }}</el-button>
            <el-divider v-if="i != category.i - 1 &&
      i != category.i &&
      i != categories.length - 1
      " direction="vertical"></el-divider>
          </el-col>
        </el-col>
      </el-col>

      <el-col v-else :span="24">
        <el-col class="finitura" :xs="{ span: 24, offset: 0 }" :sm="{ span: 8, offset: 2 }">
          <el-col class="title" :span="24">FINITURA</el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'S' }">
            <el-button @click="setFin('S')" type="text">LUCIDA</el-button>
            <el-divider v-if="finitura === 'N'" direction="vertical"></el-divider>
          </el-col>
          <!-- <el-divider direction="vertical"></el-divider> -->
          <el-col class="valore" :span="8" :class="{ active: finitura == 'M' }">
            <el-button @click="setFin('M')" type="text">OPACA</el-button>
            <el-divider v-if="finitura === 'S'" direction="vertical"></el-divider>
          </el-col>
          <el-col class="valore" :span="8" :class="{ active: finitura == 'N' }">
            <el-button @click="setFin('N')" type="text">NATURALE</el-button>
          </el-col>
        </el-col>
        <el-col class="categoria" :xs="{ span: 24, offset: 0 }" :sm="{ span: 10, offset: 2 }">
          <el-col class="title" :span="24">CATEGORIA COLORE</el-col>
          <el-col class="valore" :span="24">
            <el-select v-model="checkboxCategorie" placeholder="Select">
              <el-option v-for="(cat, i) in categories" :key="i" :label="cat | demi" :value="cat"></el-option>
              <!-- collapse-tags -->
            </el-select>
          </el-col>
        </el-col>
      </el-col>
    </el-row>
    <div class="colori">
      <div class="color" v-for="color in filteredColors" :key="color.U_THE_COLORE">
        <div class="img-colori" @click="(color.GiacIta < 1 && !color.ShipDateIta) ? null : setCol(color)">
          <span v-if="color.GiacIta < 1 && !color.ShipDateIta" class="color-finished">{{ $t('message.Colore_esaurito')
            }}</span>
          <el-avatar :class="{
      active:
        color.U_THE_COLORE ==
        $store.state.configurator.colore.U_THE_COLORE,
      white: color.U_THE_COLORE == 'EP0009',
    }" :src="baseUrlColor + color.U_THE_COLORE + '.jpg'" :size="100" />
          <span class="span-colori" :class="{ white: color.U_THE_COLORE == 'EP0009' }">{{ color.PLASTICA }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jslinq from "jslinq";
// import _ from "lodash";
import { mapMutations } from "vuex";
import { Message } from "element-ui";

export default {
  data() {
    return {
      cercaColore: "",
      checkboxCategorie: [],
      category: "",
      windowWidth: window.innerWidth,
      // finitura: "S",
    };
  },
  mounted: async function () {
    // await this.getColors();
    this.category = { cat: this.catColore, i: 3 };
    this.checkboxCategorie = this.category.cat;
  },

  methods: {
    // ...mapActions("frontali", ["getColors"]),
    getColors() {
      this.$store.dispatch("configurator/frontali/getColors");
    },
    ...mapMutations(["setCatColore"]),

    setColore(c) {
      if (parseInt(c.GiacIta) < 1) {
        Message({
          showClose: true,
          message: this.$t('message.Il_colore_{color}_è_esaurito,_data_di_arrivo:_{date}', {
            color: c.U_THE_COLORE,
            date: this.formatDate(new Date(c.ShipDateIta))
          }).toString(),
          type: "error",
          duration: 10000,
        });
      }
      this.$store.commit("configurator/setColore", c);
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    setFinishing(f) {
      this.$store.commit("configurator/setFinishing", f);
    },

    scrollLeft() {
      let container = document.getElementsByClassName("colori");
      container[0].scrollLeft -= 250;
    },

    scrollRight() {
      let container = document.getElementsByClassName("colori");
      container[0].scrollLeft += 250;
    },

    setCol(val) {
      this.setColore(val);
      this.setCatColore(val);
    },

    setFin(val) {
      this.finitura = val;
      this.setFinishing(val);
    },
  },
  computed: {
    // ...mapState("frontali", ["colors"]),
    colors() {
      return this.$store.state.configurator.frontali.colors;
    },
    // ...mapState(["catColore"]),
    baseUrlColor() {
      return `${process.env.VUE_APP_COLORS_URL}`;
    },

    queryColors() {
      return jslinq(this.colors);
    },

    filteredColors() {
      let col = this.queryColors;
      if (this.checkboxCategorie != "") {
        col = col
          .where((c) => {
            return this.checkboxCategorie.includes(c.Category);
          })
          .orderBy((e) => {
            return parseInt(e.PLASTICA);
          })
          .toList();
      } else {
        col = col
          .where((c) => {
            return c.Category == this.catColore;
          })
          .orderBy((e) => {
            return parseInt(e.PLASTICA);
          })
          .toList();
      }

      // if (this.cercaColore && this.cercaColore != "") {
      //   col = col.where((c) => {
      //     return c.U_THE_COLORE.indexOf(this.cercaColore) >= 0;
      //   });
      // }

      // return col.take(30).toList();
      return col;
    },

    categories() {
      return this.queryColors
        .select((col) => {
          return col.Category;
        })
        .distinct()
        .toList();
    },

    finitura: {
      get() {
        return this.$store.state.configurator.finitura;
      },

      set(val) {
        return this.setFinishing(val);
      },
    },

    catColore: {
      get() {
        return this.$store.state.catColore;
      },

      set(val) {
        return this.setCatColore(val);
      },
    },
  },
};
</script>

<style lang="less">
#colore {
  .filtri {
    .finitura {
      position: relative;

      .el-divider {
        position: relative;
        left: 40px;

        &.hidden {
          display: none;
        }
      }
    }

    @media (max-width: 767px) {
      .categoria {
        padding-top: 5%;
        padding-bottom: 5%;
      }
    }

    .categoria {
      // padding-top: 5%;
      position: relative;

      // .valore.cat {
      //   min-width: 150px;
      //   width: 180px;
      // }

      .valore {
        min-width: 144px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;

        .el-select {
          width: 100%;

          .el-input--suffix {
            .el-select__caret::before {
              color: #fff;
            }

            .el-input__inner {
              text-align: center;
              border: 0px;
              background: #f5821f;
              color: #fff;
            }
          }
        }

        .el-divider {
          position: absolute;
          right: 0px;
          // float: right;
          top: 10px;
          margin: 0;
        }
      }
    }

    .title {
      border-bottom: 2px solid @--color-primary;
      padding-bottom: 10px;
    }
  }

  .color-finished {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    font-size: 13px;
    font-weight: bold;
    color: red;
    background-color: rgba(255, 255, 255, 0.795);
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: not-allowed;
  }

  @media (max-width: 991px) {
    .filtri {
      .finitura {
        position: relative;

        .el-divider {
          position: relative;
          left: 20px;

          &.hidden {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .colori {
      flex-wrap: wrap;
      height: 100%;
      // justify-content: center;
      background-color: @--color-text-secondary;
      padding-bottom: 10px;
      padding-left: 1%;
      // padding-right: 25px;

      .color {
        margin-top: 10px;
        margin-bottom: 10px;

        .img-colori {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .colori {
      height: 80%;
      padding-left: 75px;
      padding-right: 75px;

      .color {
        justify-content: center;

        .img-colori {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }

  .colori::-webkit-scrollbar {
    display: none;
  }

  .colori {
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    .color {
      display: flex;
      flex-direction: column;

      .img-colori {
        cursor: pointer;
        width: 100px;
        height: 100px;
        position: relative;

        span.el-avatar.el-avatar--circle {
          transition: all 0.125s ease;

          &.white {
            border: solid black 1px;
          }
        }

        span.el-avatar.el-avatar--circle.active {
          box-shadow: inset 0 0 0 4px @--color-white;
          border: solid @--color-warning 7px;
          // margin: 0 auto;
          padding: 3%;

          // width: 16em;
          // height: 16em;
          // position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .span-colori {
          position: relative;
          top: -60px;
          color: @--color-white;

          &.white {
            color: @--color-primary;
          }
        }
      }
    }

    img {
      width: 100px;
    }
  }
}

.el-select-dropdown {
  margin: 0 !important;

  .el-select-dropdown__item {
    border-bottom: 1px solid #000;
    height: 40px;
    line-height: 40px;
  }

  .el-select-dropdown__item.hover {
    background-color: #f5821f !important;
    color: #fff;
  }

  .el-scrollbar__view.el-select-dropdown__list {
    padding: 0;
  }

  .el-scrollbar__bar.is-horizontal {
    height: 0;
  }

  .el-popper {
    .popper__arrow {
      border-bottom-color: transparent;
    }

    .popper__arrow::after {
      border-bottom-color: transparent;
    }
  }
}
</style>