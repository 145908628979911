<template>
  <div id="aste">
    <div class="arrow-left" :class="{ hidden: windowWidth < 768 }">
      <img
        :src="require('../../../assets/images/icone/freccia.png')"
        @click="scrollLeft()"
      />
    </div>
    <div class="arrow-right" :class="{ hidden: windowWidth < 768 }">
      <img
        :src="require('../../../assets/images/icone/freccia.png')"
        @click="scrollRight()"
      />
    </div>
    <el-row class="filtri" :span="24">
      <!-- <el-col class="finitura" :xs="{span:12, offset:2}" :lg="4"> -->
      <el-col
        class="finitura"
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 8, offset: 2 }"
        :lg="{ span: 4, offset: 6 }"
      >
        <el-col class="title" :span="24">FINITURA</el-col>
        <el-col
          class="valore"
          :span="12"
          :class="{ active: finituraAste == 'S' }"
        >
          <el-button @click="setFin('S')" type="text">LUCIDA</el-button>
        </el-col>
        <!-- <el-divider direction="vertical"></el-divider> -->
        <el-col
          class="valore"
          :span="12"
          :class="{ active: finituraAste == 'M' }"
        >
          <el-button @click="setFin('M')" type="text">OPACA</el-button>
        </el-col>
      </el-col>
      <el-col
        class="misura"
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 11, offset: 1 }"
        :lg="{ span: 6, offset: 2 }"
      >
        <el-col class="title" :span="24">MISURA</el-col>
        <el-col
          class="valore"
          :class="{ active: size == misuraAste }"
          v-for="(size, i) in availableSizes"
          :key="i"
          :span="12"
        >
          <el-button
            @click="(category = { i, size }), (misuraAste = size)"
            type="text"
            >{{ size | misuraAste }} {{ size }}</el-button
          >
          <!-- <el-divider
              v-if=" i != (availableSizes.length -1)"
              direction="vertical"
          ></el-divider>-->
        </el-col>
      </el-col>
    </el-row>

    <div class="colori">
      <div class="color" v-for="color in availableColors" :key="color.id">
        <div class="img-colori" @click="setCol(color)">
          <el-avatar
            :class="{
              active:
                color &&
                coloreAste &&
                color.U_THE_COLORE == coloreAste.U_THE_COLORE,
              white: color.U_THE_COLORE == 'PL0009',
            }"
            :src="baseUrlColor + color.U_THE_COLORE + '.jpg'"
            :size="100"
          />
          <span
            class="span-colori"
            :class="{
              white:
                color.U_THE_COLORE == 'PL0009' ||
                color.U_THE_COLORE == 'PL0010',
            }"
            >{{ color.U_THE_COLORE.replace("PL", "") }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapMutations, mapState } from "vuex";
import jslinq from "jslinq";

export default {
  data() {
    return {
      // finituraAste: "S",
      // misuraAste: "145",
      windowWidth: window.innerWidth,
    };
  },
  // mounted: async function () {
  //   this.getAllColors();
  // },

  methods: {
    // ...mapActions("aste", ["getAllColors"]),
    getAllColors(ac) {
      this.$store.dispatch("configurator/aste/getAllColors", ac);
    },
    // ...mapMutations([
    //   "setTemplesFinishing",
    //   "setTemplesColor",
    //   "setTemplesSize",
    // ]),

    setTemplesFinishing(tf) {
      this.$store.commit("configurator/setTemplesFinishing", tf);
    },
    setTemplesColor(tc) {
      this.$store.commit("configurator/setTemplesColor", tc);
    },
    setTemplesSize(ts) {
      this.$store.commit("configurator/setTemplesSize", ts);
    },

    scrollLeft() {
      let container = document.getElementsByClassName("colori");
      container[0].scrollLeft -= 250;
    },

    scrollRight() {
      let container = document.getElementsByClassName("colori");
      container[0].scrollLeft += 250;
    },

    setCol(val) {
      this.setTemplesColor(val);
    },

    setFin(val) {
      this.finituraAste = val;
      this.setTemplesFinishing(val);
    },
  },

  computed: {
    // ...mapState("aste", ["allColors"]),
    allColors() {
      return this.$store.state.configurator.aste.allColors;
    },
    // ...mapState(["coloreAste"]),
    coloreAste() {
      return this.$store.state.configurator.coloreAste;
    },

    baseUrlColor() {
      return `${process.env.VUE_APP_COLORS_URL}`;
    },

    currentSubline() {
      return this.$store.state.configurator.frontale?.U_THE_SOTTOLINEA;
    },

    finituraAste: {
      get() {
        return this.$store.state.configurator.finituraAste;
      },

      set(val) {
        return this.setTemplesFinishing(val);
      },
    },

    misuraAste: {
      get() {
        return this.$store.state.configurator.misuraAste;
      },

      set(val) {
        return this.setTemplesSize(val);
      },
    },

    queryColorSizes() {
      return jslinq(this.allColors);
    },

    availableSizes() {
      let c = this.queryColorSizes
        .select((c) => {
          return c.U_THE_LUNGASTA;
        })
        .distinct()
        .toList();

      c = c.sort(function (a, b) {
        return a - b;
      });

      return c;
    },

    availableColors() {
      if (this.misuraAste != "") {
        console.log(this.allColors);
        return this.queryColorSizes
          .where((ac) => {
            return (
              ac.U_THE_LUNGASTA == this.misuraAste &&
              (this.currentSubline == "MYCLAROXL"
                ? ac.U_THE_SOTTOLINEA == "MYCLAROXL"
                : ac.U_THE_SOTTOLINEA != "MYCLAROXL")
            );
          })
          .orderBy((e) => {
            return e.U_THE_COLORE.replaceAll("PL", "");
          })
          .toList();
      } else {
        return this.allColors;
      }
    },
  },

  watch: {
    misuraAste(newV) {
      let c = this.queryColorSizes.firstOrDefault((col) => {
        return (
          col.U_THE_COLORE == this.coloreAste.U_THE_COLORE &&
          col.U_THE_LUNGASTA == newV &&
          (this.currentSubline == "MYCLAROXL"
            ? col.U_THE_SOTTOLINEA == "MYCLAROXL"
            : col.U_THE_SOTTOLINEA != "MYCLAROXL")
        );
      });
      return this.setTemplesColor(c);
    },
  },
};
</script>

<style lang="less">
@media (max-width: 767px) {
  #aste {
    .filtri > div {
      height: auto !important;
    }
  }
}
@media (min-width: 768px) {
  #aste {
    .filtri {
      display: flex;
      flex-direction: row;
    }
    .filtri > div {
      height: 100% !important;
      display: inline-block !important;
    }
  }
}
#aste {
  .filtri {
    .finitura {
      position: relative;
      .el-divider {
        position: absolute;
        right: 110px;
        top: 38px;
      }
    }

    @media (max-width: 767px) {
      .misura {
        padding-top: 5%;
        padding-bottom: 5%;
      }
    }

    .misura {
      position: relative;

      .el-divider {
        position: absolute;
        right: -50px;
        top: 0px;
      }
    }

    .title {
      border-bottom: 2px solid @--color-primary;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    .colori {
      flex-wrap: wrap;
      height: 100%;
      // justify-content: center;
      background-color: @--color-text-secondary;
      padding-bottom: 10px;
      padding-left: 1%;

      .color {
        margin-top: 10px;
        margin-bottom: 10px;
        .img-colori {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .colori {
      height: 80%;
      padding-left: 75px;
      padding-right: 75px;
      .color {
        justify-content: center;
        .img-colori {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }

  .colori::-webkit-scrollbar {
    display: none;
  }

  .colori {
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    .color {
      display: flex;
      flex-direction: column;

      .img-colori {
        cursor: pointer;
        width: 100px;
        height: 100px;
        position: relative;

        span.el-avatar.el-avatar--circle {
          transition: all 0.125s ease;
          &.white {
            border: solid black 1px;
          }
        }

        span.el-avatar.el-avatar--circle.active {
          box-shadow: inset 0 0 0 4px @--color-white;
          border: solid @--color-warning 7px;
          // margin: 0 auto;
          padding: 3%;
          // width: 16em;
          // height: 16em;
          // position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .span-colori {
          position: relative;
          top: -60px;
          color: @--color-white;
          &.white {
            color: @--color-primary;
          }
        }
      }
    }

    img {
      width: 100px;
    }
  }
}
</style>