<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    version="1.1"
    id="Livello_1"
    x="0px"
    y="0px"
    viewBox="0 0 282.27534 125.07557"
    xml:space="preserve"
    sodipodi:docname="ext_asta_dx_m.svg"
    inkscape:version="0.92.5 (2060ec1f9f, 2020-04-08)"
  >
    <metadata id="metadata4609">
      <rdf:RDF>
        <cc:Work rdf:about>
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <defs id="defs4607" />
    <sodipodi:namedview
      pagecolor="#ffffff"
      borderColor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1848"
      inkscape:window-height="1016"
      id="namedview4605"
      showgrid="false"
      inkscape:zoom="4.4850931"
      inkscape:cx="143.97942"
      inkscape:cy="49.652545"
      inkscape:window-x="72"
      inkscape:window-y="27"
      inkscape:window-maximized="1"
      inkscape:current-layer="Livello_1"
    />
    <g id="g4602" transform="matrix(-1,0,0,1,438.5,-358.92466)">
      <g id="g4598">
        <line
          class="st0"
          x1="205.7"
          y1="366.79999"
          x2="205.60001"
          y2="366.79999"
          id="line4"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.8"
          y1="366.79999"
          x2="205.8"
          y2="366.79999"
          id="line6"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.89999"
          y1="366.79999"
          x2="205.8"
          y2="366.79999"
          id="line8"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206"
          y1="366.79999"
          x2="205.89999"
          y2="366.79999"
          id="line10"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206"
          y1="366.79999"
          x2="206"
          y2="366.79999"
          id="line12"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.10001"
          y1="366.79999"
          x2="206"
          y2="366.79999"
          id="line14"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.10001"
          y1="366.79999"
          x2="206.10001"
          y2="366.79999"
          id="line16"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.2"
          y1="366.79999"
          x2="206.10001"
          y2="366.79999"
          id="line18"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.3"
          y1="366.79999"
          x2="206.2"
          y2="366.79999"
          id="line20"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.3"
          y1="366.79999"
          x2="206.3"
          y2="366.79999"
          id="line22"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.39999"
          y1="366.79999"
          x2="206.3"
          y2="366.79999"
          id="line24"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.39999"
          y1="366.79999"
          x2="206.39999"
          y2="366.79999"
          id="line26"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.5"
          y1="366.79999"
          x2="206.39999"
          y2="366.79999"
          id="line28"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.60001"
          y1="366.79999"
          x2="206.5"
          y2="366.79999"
          id="line30"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.5"
          y1="375.60001"
          x2="199.39999"
          y2="375.60001"
          id="line32"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="375.60001"
          x2="199.5"
          y2="375.60001"
          id="line34"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.8"
          y1="375.60001"
          x2="199.60001"
          y2="375.60001"
          id="line36"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="375.60001"
          x2="199.8"
          y2="375.60001"
          id="line38"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.10001"
          y1="375.60001"
          x2="199.89999"
          y2="375.60001"
          id="line40"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.2"
          y1="375.60001"
          x2="200.10001"
          y2="375.60001"
          id="line42"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.3"
          y1="375.60001"
          x2="200.2"
          y2="375.60001"
          id="line44"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.5"
          y1="375.60001"
          x2="200.3"
          y2="375.60001"
          id="line46"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.60001"
          y1="375.60001"
          x2="200.5"
          y2="375.60001"
          id="line48"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.8"
          y1="375.60001"
          x2="200.60001"
          y2="375.60001"
          id="line50"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.7"
          y1="359"
          x2="172.10001"
          y2="359"
          id="line52"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.89999"
          y1="375.60001"
          x2="200.8"
          y2="375.60001"
          id="line54"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.10001"
          y1="359.20001"
          x2="171.7"
          y2="359"
          id="line56"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201"
          y1="375.60001"
          x2="200.89999"
          y2="375.60001"
          id="line58"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 164.2,361.3 h -1.1 c 0,0 -3.9,0.3 -4.8,3.1 L 158,365"
          id="path60"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.2"
          y1="375.60001"
          x2="201"
          y2="375.60001"
          id="line62"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.10001"
          y1="482.29999"
          x2="186.39999"
          y2="482.70001"
          id="line64"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.3"
          y1="375.60001"
          x2="201.2"
          y2="375.60001"
          id="line66"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187"
          y1="483.20001"
          x2="187.5"
          y2="483.5"
          id="line68"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.5"
          y1="375.60001"
          x2="201.3"
          y2="375.60001"
          id="line70"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.3"
          y1="362.70001"
          x2="196.60001"
          y2="362.79999"
          id="line72"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.60001"
          y1="375.60001"
          x2="201.5"
          y2="375.60001"
          id="line74"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.7"
          y1="375.60001"
          x2="201.60001"
          y2="375.60001"
          id="line76"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.8"
          y1="479.79999"
          x2="201"
          y2="480.5"
          id="line78"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.89999"
          y1="375.60001"
          x2="201.7"
          y2="375.60001"
          id="line80"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.2"
          y1="476"
          x2="204.5"
          y2="475"
          id="line82"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202"
          y1="375.60001"
          x2="201.89999"
          y2="375.60001"
          id="line84"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.2"
          y1="375.60001"
          x2="202"
          y2="375.60001"
          id="line86"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.3"
          y1="375.60001"
          x2="202.2"
          y2="375.60001"
          id="line88"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.39999"
          y1="375.60001"
          x2="202.3"
          y2="375.60001"
          id="line90"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.7"
          y1="368.79999"
          x2="157.60001"
          y2="367.5"
          id="line92"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.60001"
          y1="375.60001"
          x2="202.39999"
          y2="375.60001"
          id="line94"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.7"
          y1="375.60001"
          x2="202.60001"
          y2="375.60001"
          id="line96"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.89999"
          y1="375.60001"
          x2="202.7"
          y2="375.60001"
          id="line98"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203"
          y1="375.60001"
          x2="202.89999"
          y2="375.60001"
          id="line100"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.89999"
          y1="483.10001"
          x2="186.89999"
          y2="483.10001"
          id="line102"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="375.60001"
          x2="203"
          y2="375.60001"
          id="line104"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.3"
          y1="361"
          x2="190.7"
          y2="361.20001"
          id="line106"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="375.60001"
          x2="203.10001"
          y2="375.60001"
          id="line108"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196"
          y1="362.60001"
          x2="196.3"
          y2="362.70001"
          id="line110"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.39999"
          y1="375.60001"
          x2="203.3"
          y2="375.60001"
          id="line112"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="199.6,379.3 196.4,383.9 196.1,384.4 195.6,386.4   "
          id="polyline114"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.60001"
          y1="375.60001"
          x2="203.39999"
          y2="375.60001"
          id="line116"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.7"
          y1="375.60001"
          x2="203.60001"
          y2="375.60001"
          id="line118"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="375.60001"
          x2="203.7"
          y2="375.60001"
          id="line120"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204"
          y1="375.60001"
          x2="203.8"
          y2="375.60001"
          id="line122"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.8"
          y1="483.10001"
          x2="186.8"
          y2="483.10001"
          id="line124"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="375.60001"
          x2="204"
          y2="375.60001"
          id="line126"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.10001"
          y1="361.60001"
          x2="192"
          y2="361.5"
          id="line128"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="375.60001"
          x2="204.10001"
          y2="375.60001"
          id="line130"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.39999"
          y1="375.60001"
          x2="204.3"
          y2="375.60001"
          id="line132"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="375.60001"
          x2="204.39999"
          y2="375.60001"
          id="line134"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line136"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.7"
          y1="375.60001"
          x2="204.5"
          y2="375.60001"
          id="line138"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.2"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line140"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="375.60001"
          x2="204.7"
          y2="375.60001"
          id="line142"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="375.60001"
          x2="204.8"
          y2="375.60001"
          id="line144"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.10001"
          y1="375.60001"
          x2="205"
          y2="375.60001"
          id="line146"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.2"
          y1="375.60001"
          x2="205.10001"
          y2="375.60001"
          id="line148"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.39999"
          y1="375.60001"
          x2="205.2"
          y2="375.60001"
          id="line150"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="433.79999"
          y1="366.89999"
          x2="433.79999"
          y2="366.89999"
          id="line152"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.5"
          y1="375.60001"
          x2="205.39999"
          y2="375.60001"
          id="line154"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.7"
          y1="375.60001"
          x2="205.5"
          y2="375.60001"
          id="line156"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.8"
          y1="375.60001"
          x2="205.7"
          y2="375.60001"
          id="line158"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.89999"
          y1="375.60001"
          x2="205.8"
          y2="375.60001"
          id="line160"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="392.5"
          y1="366.39999"
          x2="385.10001"
          y2="366.10001"
          id="line162"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.10001"
          y1="375.60001"
          x2="205.89999"
          y2="375.60001"
          id="line164"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.2"
          y1="375.60001"
          x2="206.10001"
          y2="375.60001"
          id="line166"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.39999"
          y1="375.60001"
          x2="206.2"
          y2="375.60001"
          id="line168"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.5"
          y1="375.60001"
          x2="206.39999"
          y2="375.60001"
          id="line170"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.60001"
          y1="375.60001"
          x2="206.5"
          y2="375.60001"
          id="line172"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.8"
          y1="375.60001"
          x2="206.60001"
          y2="375.60001"
          id="line174"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="476.89999"
          x2="204.2"
          y2="476"
          id="line176"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.89999"
          y1="375.60001"
          x2="206.8"
          y2="375.60001"
          id="line178"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.7"
          y1="361.20001"
          x2="192"
          y2="361.5"
          id="line180"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="375.60001"
          x2="206.89999"
          y2="375.60001"
          id="line182"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.2"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line184"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.60001"
          y1="366.79999"
          x2="206.60001"
          y2="366.79999"
          id="line186"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.7"
          y1="366.79999"
          x2="206.60001"
          y2="366.79999"
          id="line188"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.5"
          y1="466.70001"
          x2="180"
          y2="465.10001"
          id="line190"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.8"
          y1="366.79999"
          x2="206.7"
          y2="366.79999"
          id="line192"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.8"
          y1="366.79999"
          x2="206.8"
          y2="366.79999"
          id="line194"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="379.29999"
          x2="199.39999"
          y2="379.5"
          id="line196"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.89999"
          y1="366.79999"
          x2="206.8"
          y2="366.79999"
          id="line198"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="366.79999"
          x2="206.89999"
          y2="366.79999"
          id="line200"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="366.89999"
          x2="203.3"
          y2="366.89999"
          id="line202"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="366.79999"
          x2="207"
          y2="366.79999"
          id="line204"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290"
          y1="362.60001"
          x2="290.29999"
          y2="362.60001"
          id="line206"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="289.89999"
          y1="362.60001"
          x2="290"
          y2="362.60001"
          id="line208"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="289.70001"
          y1="362.60001"
          x2="289.89999"
          y2="362.60001"
          id="line210"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="284.5"
          y1="362.60001"
          x2="285.79999"
          y2="362.60001"
          id="line212"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="282"
          y1="362.60001"
          x2="284.5"
          y2="362.60001"
          id="line214"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="279.39999"
          y1="362.60001"
          x2="282"
          y2="362.60001"
          id="line216"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="276.79999"
          y1="362.60001"
          x2="279.39999"
          y2="362.60001"
          id="line218"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="274.20001"
          y1="362.60001"
          x2="276.79999"
          y2="362.60001"
          id="line220"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="271.60001"
          y1="362.60001"
          x2="274.20001"
          y2="362.60001"
          id="line222"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="269.10001"
          y1="362.60001"
          x2="271.60001"
          y2="362.60001"
          id="line224"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="266.5"
          y1="362.60001"
          x2="269.10001"
          y2="362.60001"
          id="line226"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="263.89999"
          y1="362.60001"
          x2="266.5"
          y2="362.60001"
          id="line228"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="261.29999"
          y1="362.60001"
          x2="263.89999"
          y2="362.60001"
          id="line230"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="258.70001"
          y1="362.60001"
          x2="261.29999"
          y2="362.60001"
          id="line232"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="256.20001"
          y1="362.60001"
          x2="258.70001"
          y2="362.60001"
          id="line234"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="253.60001"
          y1="362.60001"
          x2="256.20001"
          y2="362.60001"
          id="line236"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="251"
          y1="362.60001"
          x2="253.60001"
          y2="362.60001"
          id="line238"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="248.39999"
          y1="362.60001"
          x2="251"
          y2="362.60001"
          id="line240"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="245.89999"
          y1="362.60001"
          x2="248.39999"
          y2="362.60001"
          id="line242"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="243.3"
          y1="362.60001"
          x2="245.89999"
          y2="362.60001"
          id="line244"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="240.7"
          y1="362.60001"
          x2="243.3"
          y2="362.60001"
          id="line246"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="238.10001"
          y1="362.60001"
          x2="240.7"
          y2="362.60001"
          id="line248"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="235.5"
          y1="362.60001"
          x2="238.10001"
          y2="362.60001"
          id="line250"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="233"
          y1="362.60001"
          x2="235.5"
          y2="362.60001"
          id="line252"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="230.39999"
          y1="362.60001"
          x2="233"
          y2="362.60001"
          id="line254"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="227.8"
          y1="362.60001"
          x2="230.39999"
          y2="362.60001"
          id="line256"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="225.2"
          y1="362.60001"
          x2="227.8"
          y2="362.60001"
          id="line258"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="222.60001"
          y1="362.60001"
          x2="225.2"
          y2="362.60001"
          id="line260"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="220.10001"
          y1="362.60001"
          x2="222.60001"
          y2="362.60001"
          id="line262"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="217.5"
          y1="362.60001"
          x2="220.10001"
          y2="362.60001"
          id="line264"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="214.89999"
          y1="362.60001"
          x2="217.5"
          y2="362.60001"
          id="line266"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="212.3"
          y1="362.60001"
          x2="214.89999"
          y2="362.60001"
          id="line268"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="209.7"
          y1="362.60001"
          x2="212.3"
          y2="362.60001"
          id="line270"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.2"
          y1="362.60001"
          x2="209.7"
          y2="362.60001"
          id="line272"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="373.60001"
          y1="376.89999"
          x2="376.10001"
          y2="376.79999"
          id="line274"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="371.10001"
          y1="377"
          x2="373.60001"
          y2="376.89999"
          id="line276"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="368.60001"
          y1="377.10001"
          x2="371.10001"
          y2="377"
          id="line278"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="366.10001"
          y1="377.20001"
          x2="368.60001"
          y2="377.10001"
          id="line280"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="363.60001"
          y1="377.29999"
          x2="366.10001"
          y2="377.20001"
          id="line282"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="361"
          y1="377.5"
          x2="363.60001"
          y2="377.29999"
          id="line284"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="358.5"
          y1="377.60001"
          x2="361"
          y2="377.5"
          id="line286"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="356"
          y1="377.70001"
          x2="358.5"
          y2="377.60001"
          id="line288"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="353.5"
          y1="377.79999"
          x2="356"
          y2="377.70001"
          id="line290"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="351"
          y1="377.89999"
          x2="353.5"
          y2="377.79999"
          id="line292"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="348.5"
          y1="378"
          x2="351"
          y2="377.89999"
          id="line294"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="345.89999"
          y1="378.10001"
          x2="348.5"
          y2="378"
          id="line296"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="343.39999"
          y1="378.29999"
          x2="345.89999"
          y2="378.10001"
          id="line298"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="340.89999"
          y1="378.39999"
          x2="343.39999"
          y2="378.29999"
          id="line300"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.2"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line302"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.2"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line304"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="338.39999"
          y1="378.5"
          x2="340.89999"
          y2="378.39999"
          id="line306"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="335.89999"
          y1="378.60001"
          x2="338.39999"
          y2="378.5"
          id="line308"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="333.29999"
          y1="378.70001"
          x2="335.89999"
          y2="378.60001"
          id="line310"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="330.79999"
          y1="378.79999"
          x2="333.29999"
          y2="378.70001"
          id="line312"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="328.29999"
          y1="378.89999"
          x2="330.79999"
          y2="378.79999"
          id="line314"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="325.79999"
          y1="379"
          x2="328.29999"
          y2="378.89999"
          id="line316"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="323.29999"
          y1="379.10001"
          x2="325.79999"
          y2="379"
          id="line318"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="320.79999"
          y1="379.20001"
          x2="323.29999"
          y2="379.10001"
          id="line320"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="318.20001"
          y1="379.29999"
          x2="320.79999"
          y2="379.20001"
          id="line322"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="315.70001"
          y1="379.39999"
          x2="318.20001"
          y2="379.29999"
          id="line324"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="313.20001"
          y1="379.5"
          x2="315.70001"
          y2="379.39999"
          id="line326"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.70001"
          y1="379.5"
          x2="313.20001"
          y2="379.5"
          id="line328"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="308.10001"
          y1="379.60001"
          x2="310.70001"
          y2="379.5"
          id="line330"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="305.60001"
          y1="379.70001"
          x2="308.10001"
          y2="379.60001"
          id="line332"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="303.10001"
          y1="379.70001"
          x2="305.60001"
          y2="379.70001"
          id="line334"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.60001"
          y1="359"
          x2="173.60001"
          y2="359"
          id="line336"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.3"
          y1="359"
          x2="173.60001"
          y2="359"
          id="line338"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="359"
          x2="173.3"
          y2="359"
          id="line340"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.7"
          y1="359"
          x2="173"
          y2="359"
          id="line342"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.5"
          y1="359"
          x2="172.7"
          y2="359"
          id="line344"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.10001"
          y1="359"
          x2="172.5"
          y2="359"
          id="line346"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="359"
          x2="172.10001"
          y2="359"
          id="line348"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.2"
          y1="359"
          x2="173"
          y2="359"
          id="line350"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="300.60001"
          y1="379.79999"
          x2="303.10001"
          y2="379.70001"
          id="line352"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="298.10001"
          y1="379.79999"
          x2="300.60001"
          y2="379.79999"
          id="line354"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="295.5"
          y1="379.79999"
          x2="298.10001"
          y2="379.79999"
          id="line356"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="293"
          y1="379.79999"
          x2="295.5"
          y2="379.79999"
          id="line358"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.5"
          y1="379.89999"
          x2="293"
          y2="379.79999"
          id="line360"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="288"
          y1="379.89999"
          x2="290.5"
          y2="379.89999"
          id="line362"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="285.5"
          y1="379.89999"
          x2="288"
          y2="379.89999"
          id="line364"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="481.20001"
          x2="199.5"
          y2="481.29999"
          id="line366"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="481.10001"
          x2="199.60001"
          y2="481.20001"
          id="line368"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.2"
          y1="480.89999"
          x2="199.89999"
          y2="481.10001"
          id="line370"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201"
          y1="480.5"
          x2="200.2"
          y2="480.89999"
          id="line372"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="282.89999"
          y1="379.89999"
          x2="285.5"
          y2="379.89999"
          id="line374"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="280.39999"
          y1="379.89999"
          x2="282.89999"
          y2="379.89999"
          id="line376"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="437.20001"
          y1="366.89999"
          x2="435.5"
          y2="366.89999"
          id="line378"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 437.2,366.9"
          id="path380"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="156.5"
          y1="379.70001"
          x2="156.89999"
          y2="379.29999"
          id="line382"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="156.3"
          y1="380.29999"
          x2="156.5"
          y2="379.70001"
          id="line384"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="156.39999"
          y1="380.79999"
          x2="156.3"
          y2="380.29999"
          id="line386"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.2"
          y1="387.60001"
          x2="156.39999"
          y2="380.79999"
          id="line388"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.89999"
          y1="394.5"
          x2="158.2"
          y2="387.60001"
          id="line390"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.2"
          y1="394.89999"
          x2="159.89999"
          y2="394.5"
          id="line392"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.60001"
          y1="395.29999"
          x2="160.2"
          y2="394.89999"
          id="line394"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.10001"
          y1="395.5"
          x2="160.60001"
          y2="395.29999"
          id="line396"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.39999"
          y1="395.5"
          x2="161.10001"
          y2="395.5"
          id="line398"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.39999"
          y1="395.70001"
          x2="162.39999"
          y2="395.5"
          id="line400"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="277.89999"
          y1="379.89999"
          x2="280.39999"
          y2="379.89999"
          id="line402"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="275.39999"
          y1="379.89999"
          x2="277.89999"
          y2="379.89999"
          id="line404"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="272.79999"
          y1="379.89999"
          x2="275.39999"
          y2="379.89999"
          id="line406"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="270.29999"
          y1="379.89999"
          x2="272.79999"
          y2="379.89999"
          id="line408"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.60001"
          y1="367"
          x2="157.60001"
          y2="367.39999"
          id="line410"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.7"
          y1="366.39999"
          x2="157.60001"
          y2="367"
          id="line412"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="266.39999"
          y1="379.89999"
          x2="270.29999"
          y2="379.89999"
          id="line414"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="262.39999"
          y1="379.89999"
          x2="266.39999"
          y2="379.89999"
          id="line416"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.89999"
          y1="376.60001"
          x2="158.89999"
          y2="376.60001"
          id="line418"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.10001"
          y1="377.5"
          x2="158.89999"
          y2="376.60001"
          id="line420"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.3"
          y1="378.5"
          x2="159.10001"
          y2="377.5"
          id="line422"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="258.5"
          y1="379.89999"
          x2="262.39999"
          y2="379.89999"
          id="line424"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="254.5"
          y1="379.89999"
          x2="258.5"
          y2="379.89999"
          id="line426"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="250.5"
          y1="379.89999"
          x2="254.5"
          y2="379.89999"
          id="line428"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.2"
          y1="469"
          x2="180.5"
          y2="466.70001"
          id="line430"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.89999"
          y1="471.39999"
          x2="181.2"
          y2="469"
          id="line432"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.3"
          y1="472.5"
          x2="181.89999"
          y2="471.39999"
          id="line434"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="246.60001"
          y1="379.89999"
          x2="250.5"
          y2="379.89999"
          id="line436"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="242.60001"
          y1="379.89999"
          x2="246.60001"
          y2="379.89999"
          id="line438"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="238.7"
          y1="379.79999"
          x2="242.60001"
          y2="379.89999"
          id="line440"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.89999"
          y1="477.70001"
          x2="184.2"
          y2="478.5"
          id="line442"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.7"
          y1="476.89999"
          x2="183.89999"
          y2="477.70001"
          id="line444"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.39999"
          y1="476"
          x2="183.7"
          y2="476.89999"
          id="line446"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.10001"
          y1="475.10001"
          x2="183.39999"
          y2="476"
          id="line448"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="474.20001"
          x2="183.10001"
          y2="475.10001"
          id="line450"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.5"
          y1="473.10001"
          x2="182.8"
          y2="474.20001"
          id="line452"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.5"
          y1="473.10001"
          x2="182.5"
          y2="473.10001"
          id="line454"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="234.7"
          y1="379.79999"
          x2="238.7"
          y2="379.79999"
          id="line456"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="230.8"
          y1="379.79999"
          x2="234.7"
          y2="379.79999"
          id="line458"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="226.8"
          y1="379.79999"
          x2="230.8"
          y2="379.79999"
          id="line460"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="306.29999"
          y1="362.89999"
          x2="305"
          y2="362.89999"
          id="line462"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="307.70001"
          y1="362.89999"
          x2="306.29999"
          y2="362.89999"
          id="line464"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="222.89999"
          y1="379.79999"
          x2="226.8"
          y2="379.79999"
          id="line466"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="218.89999"
          y1="379.79999"
          x2="222.89999"
          y2="379.79999"
          id="line468"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.89999"
          y1="480.29999"
          x2="185.10001"
          y2="480.79999"
          id="line470"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.7"
          y1="479.79999"
          x2="184.89999"
          y2="480.29999"
          id="line472"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.3"
          y1="478.60001"
          x2="184.7"
          y2="479.79999"
          id="line474"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="478.5"
          x2="184.3"
          y2="478.60001"
          id="line476"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="292.10001"
          y1="362.60001"
          x2="291.5"
          y2="362.60001"
          id="line478"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="293.39999"
          y1="362.70001"
          x2="292.10001"
          y2="362.60001"
          id="line480"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.2"
          y1="362.60001"
          x2="207.2"
          y2="362.60001"
          id="line482"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.60001"
          x2="207.2"
          y2="362.60001"
          id="line484"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.60001"
          x2="207.10001"
          y2="362.60001"
          id="line486"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.60001"
          id="line488"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.70001"
          id="line490"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.70001"
          id="line492"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.70001"
          id="line494"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.70001"
          id="line496"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.70001"
          id="line498"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.79999"
          id="line500"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.79999"
          id="line502"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.79999"
          id="line504"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.79999"
          id="line506"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.89999"
          x2="207.10001"
          y2="362.79999"
          id="line508"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="362.89999"
          x2="207.10001"
          y2="362.89999"
          id="line510"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="362.89999"
          x2="207"
          y2="362.89999"
          id="line512"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="362.89999"
          x2="207"
          y2="362.89999"
          id="line514"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363"
          x2="207"
          y2="362.89999"
          id="line516"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363.10001"
          x2="207"
          y2="363"
          id="line518"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363.29999"
          x2="207"
          y2="363.10001"
          id="line520"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363.60001"
          x2="207"
          y2="363.29999"
          id="line522"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363.79999"
          x2="207"
          y2="363.60001"
          id="line524"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="364.10001"
          x2="207"
          y2="363.79999"
          id="line526"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="364.29999"
          x2="207"
          y2="364.10001"
          id="line528"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="365.20001"
          x2="207"
          y2="364.29999"
          id="line530"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="366.10001"
          x2="207"
          y2="365.20001"
          id="line532"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="367"
          x2="207"
          y2="366.10001"
          id="line534"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="367.89999"
          x2="207"
          y2="367"
          id="line536"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="368.79999"
          x2="207"
          y2="367.89999"
          id="line538"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="369.60001"
          x2="207"
          y2="368.79999"
          id="line540"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="370.5"
          x2="207"
          y2="369.60001"
          id="line542"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="371.39999"
          x2="207"
          y2="370.5"
          id="line544"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="372.29999"
          x2="207"
          y2="371.39999"
          id="line546"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="373.20001"
          x2="207"
          y2="372.29999"
          id="line548"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="374.10001"
          x2="207"
          y2="373.20001"
          id="line550"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="374.89999"
          x2="207"
          y2="374.10001"
          id="line552"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="375.79999"
          x2="207"
          y2="374.89999"
          id="line554"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="376.70001"
          x2="207"
          y2="375.79999"
          id="line556"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="377.60001"
          x2="207"
          y2="376.70001"
          id="line558"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="378.5"
          x2="207"
          y2="377.60001"
          id="line560"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="378.70001"
          x2="207"
          y2="378.5"
          id="line562"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379"
          x2="207"
          y2="378.70001"
          id="line564"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379.5"
          x2="207"
          y2="379"
          id="line566"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379.79999"
          x2="207"
          y2="379.5"
          id="line568"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379.79999"
          x2="207"
          y2="379.79999"
          id="line570"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="294.70001"
          y1="362.70001"
          x2="293.39999"
          y2="362.70001"
          id="line572"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="297.20001"
          y1="362.70001"
          x2="294.70001"
          y2="362.70001"
          id="line574"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="299.79999"
          y1="362.79999"
          x2="297.20001"
          y2="362.70001"
          id="line576"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="302.39999"
          y1="362.79999"
          x2="299.79999"
          y2="362.79999"
          id="line578"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="214.89999"
          y1="379.79999"
          x2="218.89999"
          y2="379.79999"
          id="line580"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="211"
          y1="379.79999"
          x2="214.89999"
          y2="379.79999"
          id="line582"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.7"
          y1="481.70001"
          x2="186.10001"
          y2="482.29999"
          id="line584"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="480.79999"
          x2="185.7"
          y2="481.70001"
          id="line586"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.39999"
          y1="481.29999"
          x2="185.10001"
          y2="480.79999"
          id="line588"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379.79999"
          x2="211"
          y2="379.79999"
          id="line590"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.5"
          y1="367"
          x2="199.3"
          y2="367"
          id="line592"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="367"
          x2="199.5"
          y2="367"
          id="line594"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.8"
          y1="367"
          x2="199.7"
          y2="367"
          id="line596"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200"
          y1="367"
          x2="199.8"
          y2="367"
          id="line598"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.2"
          y1="367"
          x2="200"
          y2="367"
          id="line600"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.10001"
          y1="442.10001"
          x2="173.60001"
          y2="440.20001"
          id="line602"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175"
          y1="445.70001"
          x2="174.10001"
          y2="442.10001"
          id="line604"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.8"
          y1="449.10001"
          x2="175"
          y2="445.70001"
          id="line606"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.60001"
          y1="452.29999"
          x2="175.8"
          y2="449.10001"
          id="line608"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.3"
          y1="455.20001"
          x2="176.60001"
          y2="452.29999"
          id="line610"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178"
          y1="457.70001"
          x2="177.3"
          y2="455.20001"
          id="line612"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.60001"
          y1="460.20001"
          x2="178"
          y2="457.70001"
          id="line614"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="461.79999"
          x2="178.60001"
          y2="460.20001"
          id="line616"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.5"
          y1="463.5"
          x2="179.10001"
          y2="461.79999"
          id="line618"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180"
          y1="465.10001"
          x2="179.5"
          y2="463.5"
          id="line620"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="308.39999"
          y1="363"
          x2="309.10001"
          y2="363"
          id="line622"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="307.70001"
          y1="362.89999"
          x2="308.39999"
          y2="363"
          id="line624"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.3"
          y1="367"
          x2="200.2"
          y2="367"
          id="line626"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.5"
          y1="367"
          x2="200.3"
          y2="367"
          id="line628"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="303.70001"
          y1="362.79999"
          x2="305"
          y2="362.89999"
          id="line630"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="302.39999"
          y1="362.79999"
          x2="303.70001"
          y2="362.79999"
          id="line632"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.60001"
          y1="367"
          x2="200.5"
          y2="367"
          id="line634"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.8"
          y1="367"
          x2="200.60001"
          y2="367"
          id="line636"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="433.79999"
          y1="366.89999"
          x2="435.5"
          y2="366.89999"
          id="line638"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="432"
          y1="366.89999"
          x2="433.79999"
          y2="366.89999"
          id="line640"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.60001"
          y1="440.10001"
          x2="173"
          y2="437.5"
          id="line642"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.60001"
          y1="440.20001"
          x2="173.60001"
          y2="440.10001"
          id="line644"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="437.5"
          x2="173.60001"
          y2="440.20001"
          id="line646"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="430.29999"
          y1="366.89999"
          x2="432"
          y2="366.89999"
          id="line648"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.89999"
          y1="367"
          x2="200.8"
          y2="367"
          id="line650"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.10001"
          y1="367"
          x2="200.89999"
          y2="367"
          id="line652"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.2"
          y1="394.89999"
          x2="162.7"
          y2="392.89999"
          id="line654"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.7"
          y1="397.10001"
          x2="163.2"
          y2="394.89999"
          id="line656"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164.3"
          y1="399.5"
          x2="163.7"
          y2="397.10001"
          id="line658"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164.89999"
          y1="401.89999"
          x2="164.3"
          y2="399.5"
          id="line660"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="165.39999"
          y1="404.39999"
          x2="164.89999"
          y2="401.89999"
          id="line662"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166"
          y1="406.89999"
          x2="165.39999"
          y2="404.39999"
          id="line664"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166.89999"
          y1="410.79999"
          x2="166"
          y2="406.89999"
          id="line666"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="167.7"
          y1="414.70001"
          x2="166.89999"
          y2="410.79999"
          id="line668"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="168.5"
          y1="418.10001"
          x2="167.7"
          y2="414.70001"
          id="line670"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="169.2"
          y1="421.39999"
          x2="168.5"
          y2="418.10001"
          id="line672"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="170"
          y1="425.10001"
          x2="169.2"
          y2="421.39999"
          id="line674"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="170.89999"
          y1="428.70001"
          x2="170"
          y2="425.10001"
          id="line676"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.39999"
          y1="430.89999"
          x2="170.89999"
          y2="428.70001"
          id="line678"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.89999"
          y1="433.10001"
          x2="171.39999"
          y2="430.89999"
          id="line680"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.39999"
          y1="435.29999"
          x2="171.89999"
          y2="433.10001"
          id="line682"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="437.5"
          x2="172.39999"
          y2="435.29999"
          id="line684"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.2"
          y1="367"
          x2="201.10001"
          y2="367"
          id="line686"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.39999"
          y1="367"
          x2="201.2"
          y2="367"
          id="line688"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.5"
          y1="367"
          x2="201.39999"
          y2="367"
          id="line690"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.60001"
          y1="367"
          x2="201.5"
          y2="367"
          id="line692"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.8"
          y1="367"
          x2="201.60001"
          y2="367"
          id="line694"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.89999"
          y1="367"
          x2="201.8"
          y2="367"
          id="line696"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202"
          y1="367"
          x2="201.89999"
          y2="367"
          id="line698"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.2"
          y1="367"
          x2="202"
          y2="367"
          id="line700"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.3"
          y1="367"
          x2="202.2"
          y2="367"
          id="line702"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.39999"
          y1="367"
          x2="202.3"
          y2="367"
          id="line704"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.5"
          y1="367"
          x2="202.39999"
          y2="367"
          id="line706"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.60001"
          y1="367"
          x2="202.5"
          y2="367"
          id="line708"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.7"
          y1="367"
          x2="202.60001"
          y2="367"
          id="line710"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.8"
          y1="367"
          x2="202.7"
          y2="367"
          id="line712"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.89999"
          y1="367"
          x2="202.8"
          y2="367"
          id="line714"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="384.70001"
          y1="366.10001"
          x2="386.70001"
          y2="366.20001"
          id="line716"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="382.79999"
          y1="366"
          x2="384.70001"
          y2="366.10001"
          id="line718"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="380.89999"
          y1="366"
          x2="382.79999"
          y2="366"
          id="line720"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="378.89999"
          y1="365.89999"
          x2="380.89999"
          y2="366"
          id="line722"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="377"
          y1="365.79999"
          x2="378.89999"
          y2="365.89999"
          id="line724"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="375.10001"
          y1="365.70001"
          x2="377"
          y2="365.79999"
          id="line726"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="373.10001"
          y1="365.60001"
          x2="375.10001"
          y2="365.70001"
          id="line728"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="371.20001"
          y1="365.60001"
          x2="373.10001"
          y2="365.60001"
          id="line730"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="369.20001"
          y1="365.5"
          x2="371.20001"
          y2="365.60001"
          id="line732"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="367.29999"
          y1="365.39999"
          x2="369.20001"
          y2="365.5"
          id="line734"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="365.39999"
          y1="365.29999"
          x2="367.29999"
          y2="365.39999"
          id="line736"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="363.39999"
          y1="365.20001"
          x2="365.39999"
          y2="365.29999"
          id="line738"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="361.5"
          y1="365.10001"
          x2="363.39999"
          y2="365.20001"
          id="line740"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="359.5"
          y1="365"
          x2="361.5"
          y2="365.10001"
          id="line742"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="357.60001"
          y1="365"
          x2="359.5"
          y2="365"
          id="line744"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="355.70001"
          y1="364.89999"
          x2="357.60001"
          y2="365"
          id="line746"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="353.70001"
          y1="364.79999"
          x2="355.70001"
          y2="364.89999"
          id="line748"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="351.79999"
          y1="364.70001"
          x2="353.70001"
          y2="364.79999"
          id="line750"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="349.79999"
          y1="364.60001"
          x2="351.79999"
          y2="364.70001"
          id="line752"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="347.89999"
          y1="364.5"
          x2="349.79999"
          y2="364.60001"
          id="line754"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="346"
          y1="364.39999"
          x2="347.89999"
          y2="364.5"
          id="line756"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="344"
          y1="364.29999"
          x2="346"
          y2="364.39999"
          id="line758"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="342.10001"
          y1="364.29999"
          x2="344"
          y2="364.29999"
          id="line760"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="340.10001"
          y1="364.20001"
          x2="342.10001"
          y2="364.29999"
          id="line762"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="338.20001"
          y1="364.10001"
          x2="340.10001"
          y2="364.20001"
          id="line764"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="336.29999"
          y1="364"
          x2="338.20001"
          y2="364.10001"
          id="line766"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="334.29999"
          y1="363.89999"
          x2="336.29999"
          y2="364"
          id="line768"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="331.29999"
          y1="363.79999"
          x2="334.29999"
          y2="363.89999"
          id="line770"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="328.39999"
          y1="363.70001"
          x2="331.29999"
          y2="363.79999"
          id="line772"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="325.39999"
          y1="363.60001"
          x2="328.39999"
          y2="363.70001"
          id="line774"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="322.39999"
          y1="363.39999"
          x2="325.39999"
          y2="363.60001"
          id="line776"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="319.5"
          y1="363.29999"
          x2="322.39999"
          y2="363.39999"
          id="line778"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="316.5"
          y1="363.20001"
          x2="319.5"
          y2="363.29999"
          id="line780"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="315"
          y1="363.20001"
          x2="316.5"
          y2="363.20001"
          id="line782"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="313.5"
          y1="363.10001"
          x2="315"
          y2="363.20001"
          id="line784"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="312"
          y1="363.10001"
          x2="313.5"
          y2="363.10001"
          id="line786"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203"
          y1="366.89999"
          x2="202.89999"
          y2="367"
          id="line788"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203"
          y1="366.89999"
          x2="203"
          y2="366.89999"
          id="line790"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="366.89999"
          x2="203"
          y2="366.89999"
          id="line792"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="366.89999"
          x2="203.10001"
          y2="366.89999"
          id="line794"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188"
          y1="483.70001"
          x2="188.89999"
          y2="483.79999"
          id="line796"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.5"
          y1="483.5"
          x2="188"
          y2="483.70001"
          id="line798"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.2"
          y1="483.60001"
          x2="190"
          y2="483.70001"
          id="line800"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.10001"
          y1="372.10001"
          x2="158.39999"
          y2="373"
          id="line802"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.8"
          y1="370.29999"
          x2="158.10001"
          y2="372.10001"
          id="line804"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.7"
          y1="368.79999"
          x2="157.8"
          y2="370.29999"
          id="line806"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.39999"
          y1="479.10001"
          x2="203.2"
          y2="478.10001"
          id="line808"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.2"
          y1="479.29999"
          x2="202.39999"
          y2="479.10001"
          id="line810"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.10001"
          y1="479.39999"
          x2="202.2"
          y2="479.29999"
          id="line812"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="157.4,379.1 158.4,379 159.2,378.9 159.4,378.9   "
          id="polyline814"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.10001"
          y1="479.5"
          x2="201.8"
          y2="479.79999"
          id="line816"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.10001"
          y1="479.39999"
          x2="202.10001"
          y2="479.5"
          id="line818"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.8"
          y1="467.70001"
          x2="181.2"
          y2="469"
          id="line820"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.5"
          y1="466.70001"
          x2="180.8"
          y2="467.70001"
          id="line822"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="156.89999"
          y1="379.29999"
          x2="157.39999"
          y2="379.10001"
          id="line824"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.39999"
          y1="359.10001"
          x2="171.10001"
          y2="359.20001"
          id="line826"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.7"
          y1="476.89999"
          x2="183.10001"
          y2="475.10001"
          id="line828"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="478.5"
          x2="183.7"
          y2="476.89999"
          id="line830"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="474.5"
          x2="204.5"
          y2="474.79999"
          id="line832"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="474.5"
          x2="204.60001"
          y2="474.5"
          id="line834"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.60001"
          y1="482.89999"
          x2="186.39999"
          y2="482.70001"
          id="line836"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187"
          y1="483.20001"
          x2="186.60001"
          y2="482.89999"
          id="line838"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.7"
          y1="359"
          x2="171.39999"
          y2="359.10001"
          id="line840"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.8"
          y1="367"
          x2="197.8"
          y2="367"
          id="line842"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198"
          y1="367"
          x2="197.8"
          y2="367"
          id="line844"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.89999"
          y1="360.70001"
          x2="193.39999"
          y2="361.79999"
          id="line846"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.39999"
          y1="360.39999"
          x2="187.89999"
          y2="360.70001"
          id="line848"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.7"
          y1="360.29999"
          x2="186.39999"
          y2="360.39999"
          id="line850"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.39999"
          y1="360.29999"
          x2="185.7"
          y2="360.29999"
          id="line852"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.39999"
          y1="359.39999"
          x2="185.39999"
          y2="360.29999"
          id="line854"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.89999"
          y1="359.39999"
          x2="178.39999"
          y2="359.39999"
          id="line856"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177"
          y1="359.20001"
          x2="177.89999"
          y2="359.39999"
          id="line858"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.5"
          y1="359"
          x2="177"
          y2="359.20001"
          id="line860"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.2"
          y1="359"
          x2="175.5"
          y2="359"
          id="line862"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.10001"
          y1="367"
          x2="198"
          y2="367"
          id="line864"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.2"
          y1="367"
          x2="198.10001"
          y2="367"
          id="line866"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.3"
          y1="367"
          x2="198.2"
          y2="367"
          id="line868"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.5"
          y1="367"
          x2="198.3"
          y2="367"
          id="line870"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.7"
          y1="367"
          x2="198.5"
          y2="367"
          id="line872"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.8"
          y1="367"
          x2="198.7"
          y2="367"
          id="line874"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199"
          y1="367"
          x2="198.8"
          y2="367"
          id="line876"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.2"
          y1="367"
          x2="199"
          y2="367"
          id="line878"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.3"
          y1="367"
          x2="199.2"
          y2="367"
          id="line880"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.8"
          y1="363"
          x2="196.60001"
          y2="362.79999"
          id="line882"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197"
          y1="363.20001"
          x2="196.8"
          y2="363"
          id="line884"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.60001"
          y1="362.79999"
          x2="197"
          y2="363.20001"
          id="line886"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 436.5,375.5"
          id="path888"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="434"
          y1="375.5"
          x2="436.5"
          y2="375.5"
          id="line890"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="431.5"
          y1="375.5"
          x2="434"
          y2="375.5"
          id="line892"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.10001"
          y1="362.60001"
          x2="196.3"
          y2="362.70001"
          id="line894"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.39999"
          y1="361.79999"
          x2="196.10001"
          y2="362.60001"
          id="line896"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="429"
          y1="375.5"
          x2="431.5"
          y2="375.5"
          id="line898"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="426.39999"
          y1="375.5"
          x2="429"
          y2="375.5"
          id="line900"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.2"
          y1="363.70001"
          x2="197"
          y2="363.20001"
          id="line902"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="378.20001"
          x2="197.2"
          y2="363.70001"
          id="line904"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="378.70001"
          x2="199.89999"
          y2="378.20001"
          id="line906"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="423.89999"
          y1="375.5"
          x2="426.39999"
          y2="375.5"
          id="line908"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="421.39999"
          y1="375.60001"
          x2="423.89999"
          y2="375.5"
          id="line910"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="418.89999"
          y1="375.60001"
          x2="421.39999"
          y2="375.60001"
          id="line912"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="416.39999"
          y1="375.60001"
          x2="418.89999"
          y2="375.60001"
          id="line914"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="413.89999"
          y1="375.60001"
          x2="416.39999"
          y2="375.60001"
          id="line916"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="411.39999"
          y1="375.70001"
          x2="413.89999"
          y2="375.60001"
          id="line918"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="408.79999"
          y1="375.70001"
          x2="411.39999"
          y2="375.70001"
          id="line920"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="406.29999"
          y1="375.70001"
          x2="408.79999"
          y2="375.70001"
          id="line922"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="403.79999"
          y1="375.79999"
          x2="406.29999"
          y2="375.70001"
          id="line924"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="401.29999"
          y1="375.89999"
          x2="403.79999"
          y2="375.79999"
          id="line926"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="398.70001"
          y1="375.89999"
          x2="401.29999"
          y2="375.89999"
          id="line928"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="396.20001"
          y1="376"
          x2="398.70001"
          y2="375.89999"
          id="line930"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="393.60001"
          y1="376.10001"
          x2="396.20001"
          y2="376"
          id="line932"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.3"
          y1="463.5"
          x2="205.39999"
          y2="465.5"
          id="line934"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="459.60001"
          x2="205.3"
          y2="463.5"
          id="line936"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="459.60001"
          x2="205"
          y2="459.60001"
          id="line938"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="394.39999"
          y1="366.5"
          x2="392.5"
          y2="366.39999"
          id="line940"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="396.29999"
          y1="366.5"
          x2="394.39999"
          y2="366.5"
          id="line942"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="399.20001"
          y1="366.60001"
          x2="396.29999"
          y2="366.5"
          id="line944"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="402.10001"
          y1="366.70001"
          x2="399.20001"
          y2="366.60001"
          id="line946"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="405"
          y1="366.70001"
          x2="402.10001"
          y2="366.70001"
          id="line948"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="407.89999"
          y1="366.79999"
          x2="405"
          y2="366.70001"
          id="line950"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="409.79999"
          y1="366.79999"
          x2="407.89999"
          y2="366.79999"
          id="line952"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="411.70001"
          y1="366.79999"
          x2="409.79999"
          y2="366.79999"
          id="line954"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="413.70001"
          y1="366.79999"
          x2="411.70001"
          y2="366.79999"
          id="line956"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="415.60001"
          y1="366.79999"
          x2="413.70001"
          y2="366.79999"
          id="line958"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="417.5"
          y1="366.89999"
          x2="415.60001"
          y2="366.79999"
          id="line960"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="419.5"
          y1="366.89999"
          x2="417.5"
          y2="366.89999"
          id="line962"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="421.39999"
          y1="366.89999"
          x2="419.5"
          y2="366.89999"
          id="line964"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="423.29999"
          y1="366.89999"
          x2="421.39999"
          y2="366.89999"
          id="line966"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="378.70001"
          x2="199.7"
          y2="379"
          id="line968"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="379"
          x2="199.89999"
          y2="378.70001"
          id="line970"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="379.29999"
          x2="199.7"
          y2="379"
          id="line972"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="379.10001"
          x2="199.60001"
          y2="379.29999"
          id="line974"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="425.10001"
          y1="366.89999"
          x2="423.29999"
          y2="366.89999"
          id="line976"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="426.79999"
          y1="366.89999"
          x2="425.10001"
          y2="366.89999"
          id="line978"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="428.5"
          y1="366.89999"
          x2="426.79999"
          y2="366.89999"
          id="line980"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="430.29999"
          y1="366.89999"
          x2="428.5"
          y2="366.89999"
          id="line982"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="391"
          y1="376.20001"
          x2="393.60001"
          y2="376.10001"
          id="line984"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="388.60001"
          y1="376.29999"
          x2="391"
          y2="376.20001"
          id="line986"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="386.10001"
          y1="376.39999"
          x2="388.60001"
          y2="376.29999"
          id="line988"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="383.60001"
          y1="376.5"
          x2="386.10001"
          y2="376.39999"
          id="line990"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="381.10001"
          y1="376.60001"
          x2="383.60001"
          y2="376.5"
          id="line992"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="378.60001"
          y1="376.70001"
          x2="381.10001"
          y2="376.60001"
          id="line994"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="376.10001"
          y1="376.79999"
          x2="378.60001"
          y2="376.70001"
          id="line996"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="289.39999"
          y1="362.60001"
          x2="289.70001"
          y2="362.60001"
          id="line998"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="289.10001"
          y1="362.60001"
          x2="289.39999"
          y2="362.60001"
          id="line1000"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="288.39999"
          y1="362.60001"
          x2="289.10001"
          y2="362.60001"
          id="line1002"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="287.10001"
          y1="362.60001"
          x2="288.39999"
          y2="362.60001"
          id="line1004"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="285.79999"
          y1="362.60001"
          x2="287.10001"
          y2="362.60001"
          id="line1006"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.89999"
          y1="362.60001"
          x2="291.5"
          y2="362.60001"
          id="line1008"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.29999"
          y1="362.60001"
          x2="290.89999"
          y2="362.60001"
          id="line1010"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="366.89999"
          x2="203.3"
          y2="366.89999"
          id="line1012"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.39999"
          y1="366.89999"
          x2="203.3"
          y2="366.89999"
          id="line1014"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.5"
          y1="366.89999"
          x2="203.39999"
          y2="366.89999"
          id="line1016"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.5"
          y1="366.89999"
          x2="203.5"
          y2="366.89999"
          id="line1018"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.60001"
          y1="366.89999"
          x2="203.5"
          y2="366.89999"
          id="line1020"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.60001"
          y1="366.89999"
          x2="203.60001"
          y2="366.89999"
          id="line1022"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.7"
          y1="366.89999"
          x2="203.60001"
          y2="366.89999"
          id="line1024"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="366.89999"
          x2="203.7"
          y2="366.89999"
          id="line1026"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="366.89999"
          x2="203.8"
          y2="366.89999"
          id="line1028"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.89999"
          y1="366.89999"
          x2="203.8"
          y2="366.89999"
          id="line1030"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204"
          y1="366.89999"
          x2="203.89999"
          y2="366.89999"
          id="line1032"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204"
          y1="366.89999"
          x2="204"
          y2="366.89999"
          id="line1034"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="473.79999"
          x2="204.60001"
          y2="474.39999"
          id="line1036"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="474.5"
          x2="204.8"
          y2="473.79999"
          id="line1038"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="475"
          x2="204.60001"
          y2="474.5"
          id="line1040"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="474.79999"
          x2="204.5"
          y2="475"
          id="line1042"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="366.89999"
          x2="204"
          y2="366.89999"
          id="line1044"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="366.89999"
          x2="204.10001"
          y2="366.89999"
          id="line1046"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.2"
          y1="366.89999"
          x2="204.10001"
          y2="366.89999"
          id="line1048"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="366.89999"
          x2="204.2"
          y2="366.89999"
          id="line1050"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="366.89999"
          x2="204.3"
          y2="366.89999"
          id="line1052"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.39999"
          y1="366.89999"
          x2="204.3"
          y2="366.89999"
          id="line1054"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="366.89999"
          x2="204.39999"
          y2="366.89999"
          id="line1056"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="366.89999"
          x2="204.5"
          y2="366.89999"
          id="line1058"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="366.89999"
          x2="204.5"
          y2="366.89999"
          id="line1060"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="366.89999"
          x2="204.60001"
          y2="366.89999"
          id="line1062"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.7"
          y1="366.89999"
          x2="204.60001"
          y2="366.89999"
          id="line1064"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="366.89999"
          x2="204.7"
          y2="366.89999"
          id="line1066"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="366.89999"
          x2="204.8"
          y2="366.89999"
          id="line1068"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.89999"
          y1="366.89999"
          x2="204.8"
          y2="366.89999"
          id="line1070"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="366.89999"
          x2="204.89999"
          y2="366.89999"
          id="line1072"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="366.89999"
          x2="205"
          y2="366.89999"
          id="line1074"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.10001"
          y1="366.89999"
          x2="205"
          y2="366.89999"
          id="line1076"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.10001"
          y1="366.89999"
          x2="205.10001"
          y2="366.89999"
          id="line1078"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.2"
          y1="366.89999"
          x2="205.10001"
          y2="366.89999"
          id="line1080"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.3"
          y1="366.89999"
          x2="205.2"
          y2="366.89999"
          id="line1082"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.3"
          y1="366.89999"
          x2="205.3"
          y2="366.89999"
          id="line1084"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.39999"
          y1="366.89999"
          x2="205.3"
          y2="366.89999"
          id="line1086"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.5"
          y1="366.89999"
          x2="205.39999"
          y2="366.89999"
          id="line1088"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.5"
          y1="366.79999"
          x2="205.5"
          y2="366.89999"
          id="line1090"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.60001"
          y1="366.79999"
          x2="205.5"
          y2="366.79999"
          id="line1092"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.60001"
          y1="366.79999"
          x2="205.60001"
          y2="366.79999"
          id="line1094"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.8"
          y1="366.79999"
          x2="205.7"
          y2="366.79999"
          id="line1096"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.60001"
          y1="367.10001"
          x2="201.5"
          y2="367.10001"
          id="line1098"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.7"
          y1="367.10001"
          x2="201.60001"
          y2="367.10001"
          id="line1100"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.89999"
          y1="367.10001"
          x2="201.7"
          y2="367.10001"
          id="line1102"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202"
          y1="367.10001"
          x2="201.89999"
          y2="367.10001"
          id="line1104"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.2"
          y1="367.10001"
          x2="202"
          y2="367.10001"
          id="line1106"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.3"
          y1="367.10001"
          x2="202.2"
          y2="367.10001"
          id="line1108"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.39999"
          y1="367.10001"
          x2="202.3"
          y2="367.10001"
          id="line1110"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.60001"
          y1="367.10001"
          x2="202.39999"
          y2="367.10001"
          id="line1112"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.7"
          y1="367.10001"
          x2="202.60001"
          y2="367.10001"
          id="line1114"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.89999"
          y1="367.10001"
          x2="202.7"
          y2="367.10001"
          id="line1116"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203"
          y1="367.10001"
          x2="202.89999"
          y2="367.10001"
          id="line1118"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="367.10001"
          x2="203"
          y2="367.10001"
          id="line1120"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="367.10001"
          x2="203.10001"
          y2="367.10001"
          id="line1122"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.39999"
          y1="367.10001"
          x2="203.3"
          y2="367.10001"
          id="line1124"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.60001"
          y1="367.10001"
          x2="203.39999"
          y2="367.10001"
          id="line1126"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.7"
          y1="367.10001"
          x2="203.60001"
          y2="367.10001"
          id="line1128"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="367.10001"
          x2="203.7"
          y2="367.10001"
          id="line1130"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204"
          y1="367.10001"
          x2="203.8"
          y2="367.10001"
          id="line1132"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="367.10001"
          x2="204"
          y2="367.10001"
          id="line1134"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="367.10001"
          x2="204.10001"
          y2="367.10001"
          id="line1136"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.39999"
          y1="367.10001"
          x2="204.3"
          y2="367.10001"
          id="line1138"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="367.10001"
          x2="204.39999"
          y2="367.10001"
          id="line1140"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.7"
          y1="367.10001"
          x2="204.5"
          y2="367.10001"
          id="line1142"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="367.10001"
          x2="204.7"
          y2="367.10001"
          id="line1144"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="367.10001"
          x2="204.8"
          y2="367.10001"
          id="line1146"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.10001"
          y1="367.10001"
          x2="205"
          y2="367.10001"
          id="line1148"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.2"
          y1="367.10001"
          x2="205.10001"
          y2="367.10001"
          id="line1150"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.39999"
          y1="367.10001"
          x2="205.2"
          y2="367.10001"
          id="line1152"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.5"
          y1="367.10001"
          x2="205.39999"
          y2="367.10001"
          id="line1154"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.7"
          y1="367.10001"
          x2="205.5"
          y2="367.10001"
          id="line1156"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.8"
          y1="367.10001"
          x2="205.7"
          y2="367.10001"
          id="line1158"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.89999"
          y1="367.10001"
          x2="205.8"
          y2="367.10001"
          id="line1160"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.10001"
          y1="367.10001"
          x2="205.89999"
          y2="367.10001"
          id="line1162"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.2"
          y1="367.10001"
          x2="206.10001"
          y2="367.10001"
          id="line1164"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.39999"
          y1="367.10001"
          x2="206.2"
          y2="367.10001"
          id="line1166"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.5"
          y1="367.10001"
          x2="206.39999"
          y2="367.10001"
          id="line1168"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.60001"
          y1="367.10001"
          x2="206.5"
          y2="367.10001"
          id="line1170"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.8"
          y1="367.10001"
          x2="206.60001"
          y2="367.10001"
          id="line1172"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.89999"
          y1="367.10001"
          x2="206.8"
          y2="367.10001"
          id="line1174"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="367.10001"
          x2="206.89999"
          y2="367.10001"
          id="line1176"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="436.60001"
          y1="374.10001"
          x2="438.10001"
          y2="374.10001"
          id="line1178"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="434.10001"
          y1="374.10001"
          x2="436.60001"
          y2="374.10001"
          id="line1180"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="431.60001"
          y1="374.10001"
          x2="434.10001"
          y2="374.10001"
          id="line1182"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="429"
          y1="374.20001"
          x2="431.60001"
          y2="374.10001"
          id="line1184"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="426.5"
          y1="374.20001"
          x2="429"
          y2="374.20001"
          id="line1186"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="424"
          y1="374.20001"
          x2="426.5"
          y2="374.20001"
          id="line1188"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="421.5"
          y1="374.20001"
          x2="424"
          y2="374.20001"
          id="line1190"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="419"
          y1="374.20001"
          x2="421.5"
          y2="374.20001"
          id="line1192"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="416.39999"
          y1="374.20001"
          x2="419"
          y2="374.20001"
          id="line1194"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="413.89999"
          y1="374.29999"
          x2="416.39999"
          y2="374.20001"
          id="line1196"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="411.39999"
          y1="374.29999"
          x2="413.89999"
          y2="374.29999"
          id="line1198"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="408.89999"
          y1="374.29999"
          x2="411.39999"
          y2="374.29999"
          id="line1200"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="406.29999"
          y1="374.39999"
          x2="408.89999"
          y2="374.29999"
          id="line1202"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="403.79999"
          y1="374.39999"
          x2="406.29999"
          y2="374.39999"
          id="line1204"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="401.29999"
          y1="374.5"
          x2="403.79999"
          y2="374.39999"
          id="line1206"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="398.70001"
          y1="374.60001"
          x2="401.29999"
          y2="374.5"
          id="line1208"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="396.20001"
          y1="374.60001"
          x2="398.70001"
          y2="374.60001"
          id="line1210"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="393.60001"
          y1="374.70001"
          x2="396.20001"
          y2="374.60001"
          id="line1212"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="391"
          y1="374.79999"
          x2="393.60001"
          y2="374.70001"
          id="line1214"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="388.5"
          y1="374.89999"
          x2="391"
          y2="374.79999"
          id="line1216"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="386"
          y1="375"
          x2="388.5"
          y2="374.89999"
          id="line1218"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="383.5"
          y1="375.10001"
          x2="386"
          y2="375"
          id="line1220"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="381"
          y1="375.20001"
          x2="383.5"
          y2="375.10001"
          id="line1222"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="378.5"
          y1="375.29999"
          x2="381"
          y2="375.20001"
          id="line1224"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="376"
          y1="375.39999"
          x2="378.5"
          y2="375.29999"
          id="line1226"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="373.60001"
          y1="375.5"
          x2="376"
          y2="375.39999"
          id="line1228"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="371.10001"
          y1="375.60001"
          x2="373.60001"
          y2="375.5"
          id="line1230"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="368.5"
          y1="375.70001"
          x2="371.10001"
          y2="375.60001"
          id="line1232"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="366"
          y1="375.89999"
          x2="368.5"
          y2="375.70001"
          id="line1234"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="363.5"
          y1="376"
          x2="366"
          y2="375.89999"
          id="line1236"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="361"
          y1="376.10001"
          x2="363.5"
          y2="376"
          id="line1238"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="358.5"
          y1="376.20001"
          x2="361"
          y2="376.10001"
          id="line1240"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="355.89999"
          y1="376.29999"
          x2="358.5"
          y2="376.20001"
          id="line1242"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="353.39999"
          y1="376.39999"
          x2="355.89999"
          y2="376.29999"
          id="line1244"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="350.89999"
          y1="376.5"
          x2="353.39999"
          y2="376.39999"
          id="line1246"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="348.39999"
          y1="376.70001"
          x2="350.89999"
          y2="376.5"
          id="line1248"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="345.89999"
          y1="376.79999"
          x2="348.39999"
          y2="376.70001"
          id="line1250"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="343.29999"
          y1="376.89999"
          x2="345.89999"
          y2="376.79999"
          id="line1252"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="340.79999"
          y1="377"
          x2="343.29999"
          y2="376.89999"
          id="line1254"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="338.29999"
          y1="377.10001"
          x2="340.79999"
          y2="377"
          id="line1256"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="335.79999"
          y1="377.20001"
          x2="338.29999"
          y2="377.10001"
          id="line1258"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="333.29999"
          y1="377.29999"
          x2="335.79999"
          y2="377.20001"
          id="line1260"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="330.79999"
          y1="377.39999"
          x2="333.29999"
          y2="377.29999"
          id="line1262"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="328.20001"
          y1="377.5"
          x2="330.79999"
          y2="377.39999"
          id="line1264"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="325.70001"
          y1="377.60001"
          x2="328.20001"
          y2="377.5"
          id="line1266"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="323.20001"
          y1="377.70001"
          x2="325.70001"
          y2="377.60001"
          id="line1268"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="320.70001"
          y1="377.79999"
          x2="323.20001"
          y2="377.70001"
          id="line1270"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="318.20001"
          y1="377.89999"
          x2="320.70001"
          y2="377.79999"
          id="line1272"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="315.60001"
          y1="378"
          x2="318.20001"
          y2="377.89999"
          id="line1274"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="313.10001"
          y1="378.10001"
          x2="315.60001"
          y2="378"
          id="line1276"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.60001"
          y1="378.20001"
          x2="313.10001"
          y2="378.10001"
          id="line1278"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="308.10001"
          y1="378.20001"
          x2="310.60001"
          y2="378.20001"
          id="line1280"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="305.60001"
          y1="378.29999"
          x2="308.10001"
          y2="378.20001"
          id="line1282"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="303.10001"
          y1="378.39999"
          x2="305.60001"
          y2="378.29999"
          id="line1284"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="300.5"
          y1="378.39999"
          x2="303.10001"
          y2="378.39999"
          id="line1286"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="298"
          y1="378.39999"
          x2="300.5"
          y2="378.39999"
          id="line1288"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="295.5"
          y1="378.39999"
          x2="298"
          y2="378.39999"
          id="line1290"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="293"
          y1="378.5"
          x2="295.5"
          y2="378.39999"
          id="line1292"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.5"
          y1="378.5"
          x2="293"
          y2="378.5"
          id="line1294"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="288"
          y1="378.5"
          x2="290.5"
          y2="378.5"
          id="line1296"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="285.39999"
          y1="378.5"
          x2="288"
          y2="378.5"
          id="line1298"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="282.89999"
          y1="378.5"
          x2="285.39999"
          y2="378.5"
          id="line1300"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="280.39999"
          y1="378.5"
          x2="282.89999"
          y2="378.5"
          id="line1302"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="277.89999"
          y1="378.5"
          x2="280.39999"
          y2="378.5"
          id="line1304"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="275.39999"
          y1="378.5"
          x2="277.89999"
          y2="378.5"
          id="line1306"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="272.79999"
          y1="378.5"
          x2="275.39999"
          y2="378.5"
          id="line1308"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="270.29999"
          y1="378.5"
          x2="272.79999"
          y2="378.5"
          id="line1310"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="266.39999"
          y1="378.5"
          x2="270.29999"
          y2="378.5"
          id="line1312"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="262.39999"
          y1="378.5"
          x2="266.39999"
          y2="378.5"
          id="line1314"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="258.39999"
          y1="378.5"
          x2="262.39999"
          y2="378.5"
          id="line1316"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="254.5"
          y1="378.5"
          x2="258.39999"
          y2="378.5"
          id="line1318"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="250.5"
          y1="378.5"
          x2="254.5"
          y2="378.5"
          id="line1320"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="246.60001"
          y1="378.5"
          x2="250.5"
          y2="378.5"
          id="line1322"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="242.60001"
          y1="378.5"
          x2="246.60001"
          y2="378.5"
          id="line1324"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="238.7"
          y1="378.5"
          x2="242.60001"
          y2="378.5"
          id="line1326"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="234.7"
          y1="378.5"
          x2="238.7"
          y2="378.5"
          id="line1328"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="230.8"
          y1="378.5"
          x2="234.7"
          y2="378.5"
          id="line1330"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="226.8"
          y1="378.5"
          x2="230.8"
          y2="378.5"
          id="line1332"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="222.8"
          y1="378.5"
          x2="226.8"
          y2="378.5"
          id="line1334"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="218.89999"
          y1="378.5"
          x2="222.8"
          y2="378.5"
          id="line1336"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="214.89999"
          y1="378.5"
          x2="218.89999"
          y2="378.5"
          id="line1338"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="211"
          y1="378.5"
          x2="214.89999"
          y2="378.5"
          id="line1340"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="378.5"
          x2="211"
          y2="378.5"
          id="line1342"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.3"
          y1="463.5"
          x2="205.39999"
          y2="465.5"
          id="line1344"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="459.60001"
          x2="205.3"
          y2="463.5"
          id="line1346"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="459.60001"
          x2="205"
          y2="459.60001"
          id="line1348"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.7"
          y1="379.60001"
          x2="182.7"
          y2="379.60001"
          id="line1350"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="379.39999"
          x2="182.7"
          y2="379.60001"
          id="line1352"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.60001"
          y1="372.79999"
          x2="160.7"
          y2="372.5"
          id="line1354"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.5"
          y1="373.20001"
          x2="160.60001"
          y2="372.79999"
          id="line1356"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.39999"
          y1="373.60001"
          x2="160.5"
          y2="373.20001"
          id="line1358"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.3"
          y1="374.20001"
          x2="160.39999"
          y2="373.60001"
          id="line1360"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.3"
          y1="374.70001"
          x2="160.3"
          y2="374.20001"
          id="line1362"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.39999"
          y1="375.20001"
          x2="160.3"
          y2="374.70001"
          id="line1364"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.39999"
          y1="375.70001"
          x2="160.39999"
          y2="375.20001"
          id="line1366"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.5"
          y1="376.20001"
          x2="160.39999"
          y2="375.70001"
          id="line1368"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.5"
          y1="376.79999"
          x2="160.5"
          y2="376.20001"
          id="line1370"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.7"
          y1="377.60001"
          x2="160.5"
          y2="376.79999"
          id="line1372"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.8"
          y1="378.60001"
          x2="160.7"
          y2="377.60001"
          id="line1374"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.10001"
          y1="379.60001"
          x2="160.8"
          y2="378.60001"
          id="line1376"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.2"
          y1="380.20001"
          x2="161.10001"
          y2="379.60001"
          id="line1378"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.39999"
          y1="381"
          x2="161.2"
          y2="380.20001"
          id="line1380"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.60001"
          y1="381.79999"
          x2="161.39999"
          y2="381"
          id="line1382"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.89999"
          y1="383.10001"
          x2="161.60001"
          y2="381.79999"
          id="line1384"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.2"
          y1="384.5"
          x2="161.89999"
          y2="383.10001"
          id="line1386"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.5"
          y1="385.39999"
          x2="162.2"
          y2="384.5"
          id="line1388"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.89999"
          y1="387.39999"
          x2="162.5"
          y2="385.39999"
          id="line1390"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.39999"
          y1="389.60001"
          x2="162.89999"
          y2="387.39999"
          id="line1392"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.7"
          y1="390.79999"
          x2="163.39999"
          y2="389.60001"
          id="line1394"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164"
          y1="392.10001"
          x2="163.7"
          y2="390.79999"
          id="line1396"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164.3"
          y1="393.20001"
          x2="164"
          y2="392.10001"
          id="line1398"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164.8"
          y1="395.20001"
          x2="164.3"
          y2="393.20001"
          id="line1400"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="165.2"
          y1="397.29999"
          x2="164.8"
          y2="395.20001"
          id="line1402"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="165.8"
          y1="399.5"
          x2="165.2"
          y2="397.29999"
          id="line1404"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166.3"
          y1="401.79999"
          x2="165.8"
          y2="399.5"
          id="line1406"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166.8"
          y1="404.20001"
          x2="166.3"
          y2="401.79999"
          id="line1408"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166.89999"
          y1="404.70001"
          x2="166.8"
          y2="404.20001"
          id="line1410"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="366.89999"
          x2="203.3"
          y2="366.89999"
          id="line1412"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.39999"
          y1="366.89999"
          x2="203.3"
          y2="366.89999"
          id="line1414"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.5"
          y1="366.89999"
          x2="203.39999"
          y2="366.89999"
          id="line1416"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.5"
          y1="366.89999"
          x2="203.5"
          y2="366.89999"
          id="line1418"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.60001"
          y1="366.89999"
          x2="203.5"
          y2="366.89999"
          id="line1420"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.60001"
          y1="366.89999"
          x2="203.60001"
          y2="366.89999"
          id="line1422"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.7"
          y1="366.89999"
          x2="203.60001"
          y2="366.89999"
          id="line1424"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="366.89999"
          x2="203.7"
          y2="366.89999"
          id="line1426"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="366.89999"
          x2="203.8"
          y2="366.89999"
          id="line1428"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.89999"
          y1="366.89999"
          x2="203.8"
          y2="366.89999"
          id="line1430"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204"
          y1="366.89999"
          x2="203.89999"
          y2="366.89999"
          id="line1432"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204"
          y1="366.89999"
          x2="204"
          y2="366.89999"
          id="line1434"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="366.89999"
          x2="204"
          y2="366.89999"
          id="line1436"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="366.89999"
          x2="204.10001"
          y2="366.89999"
          id="line1438"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.2"
          y1="366.89999"
          x2="204.10001"
          y2="366.89999"
          id="line1440"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="366.89999"
          x2="204.2"
          y2="366.89999"
          id="line1442"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="366.89999"
          x2="204.3"
          y2="366.89999"
          id="line1444"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.39999"
          y1="366.89999"
          x2="204.3"
          y2="366.89999"
          id="line1446"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="366.89999"
          x2="204.39999"
          y2="366.89999"
          id="line1448"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="366.89999"
          x2="204.5"
          y2="366.89999"
          id="line1450"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="366.89999"
          x2="204.5"
          y2="366.89999"
          id="line1452"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="366.89999"
          x2="204.60001"
          y2="366.89999"
          id="line1454"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.7"
          y1="366.89999"
          x2="204.60001"
          y2="366.89999"
          id="line1456"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="366.89999"
          x2="204.7"
          y2="366.89999"
          id="line1458"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="366.89999"
          x2="204.8"
          y2="366.89999"
          id="line1460"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.89999"
          y1="366.89999"
          x2="204.8"
          y2="366.89999"
          id="line1462"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="366.89999"
          x2="204.89999"
          y2="366.89999"
          id="line1464"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="366.89999"
          x2="205"
          y2="366.89999"
          id="line1466"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.10001"
          y1="366.89999"
          x2="205"
          y2="366.89999"
          id="line1468"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.10001"
          y1="366.89999"
          x2="205.10001"
          y2="366.89999"
          id="line1470"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.2"
          y1="366.89999"
          x2="205.10001"
          y2="366.89999"
          id="line1472"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.3"
          y1="366.89999"
          x2="205.2"
          y2="366.89999"
          id="line1474"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.3"
          y1="366.89999"
          x2="205.3"
          y2="366.89999"
          id="line1476"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.39999"
          y1="366.89999"
          x2="205.3"
          y2="366.89999"
          id="line1478"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.5"
          y1="366.89999"
          x2="205.39999"
          y2="366.89999"
          id="line1480"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.5"
          y1="366.79999"
          x2="205.5"
          y2="366.89999"
          id="line1482"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.60001"
          y1="366.79999"
          x2="205.5"
          y2="366.79999"
          id="line1484"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.60001"
          y1="366.79999"
          x2="205.60001"
          y2="366.79999"
          id="line1486"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.7"
          y1="366.79999"
          x2="205.60001"
          y2="366.79999"
          id="line1488"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.8"
          y1="366.79999"
          x2="205.7"
          y2="366.79999"
          id="line1490"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.8"
          y1="366.79999"
          x2="205.8"
          y2="366.79999"
          id="line1492"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.89999"
          y1="366.79999"
          x2="205.8"
          y2="366.79999"
          id="line1494"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206"
          y1="366.79999"
          x2="205.89999"
          y2="366.79999"
          id="line1496"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206"
          y1="366.79999"
          x2="206"
          y2="366.79999"
          id="line1498"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.10001"
          y1="366.79999"
          x2="206"
          y2="366.79999"
          id="line1500"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.10001"
          y1="366.79999"
          x2="206.10001"
          y2="366.79999"
          id="line1502"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.2"
          y1="366.79999"
          x2="206.10001"
          y2="366.79999"
          id="line1504"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.3"
          y1="366.79999"
          x2="206.2"
          y2="366.79999"
          id="line1506"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.3"
          y1="366.79999"
          x2="206.3"
          y2="366.79999"
          id="line1508"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.39999"
          y1="366.79999"
          x2="206.3"
          y2="366.79999"
          id="line1510"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.39999"
          y1="366.79999"
          x2="206.39999"
          y2="366.79999"
          id="line1512"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.5"
          y1="366.79999"
          x2="206.39999"
          y2="366.79999"
          id="line1514"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.60001"
          y1="366.79999"
          x2="206.5"
          y2="366.79999"
          id="line1516"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.60001"
          y1="366.79999"
          x2="206.60001"
          y2="366.79999"
          id="line1518"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.7"
          y1="366.79999"
          x2="206.60001"
          y2="366.79999"
          id="line1520"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.8"
          y1="366.79999"
          x2="206.7"
          y2="366.79999"
          id="line1522"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.8"
          y1="366.79999"
          x2="206.8"
          y2="366.79999"
          id="line1524"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.89999"
          y1="366.79999"
          x2="206.8"
          y2="366.79999"
          id="line1526"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="366.79999"
          x2="206.89999"
          y2="366.79999"
          id="line1528"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="366.79999"
          x2="207"
          y2="366.79999"
          id="line1530"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.60001"
          y1="359"
          x2="173.60001"
          y2="359"
          id="line1532"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.3"
          y1="359"
          x2="173.60001"
          y2="359"
          id="line1534"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="359"
          x2="173.3"
          y2="359"
          id="line1536"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.7"
          y1="359"
          x2="173"
          y2="359"
          id="line1538"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.5"
          y1="359"
          x2="172.7"
          y2="359"
          id="line1540"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.10001"
          y1="359"
          x2="172.5"
          y2="359"
          id="line1542"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="359"
          x2="172.10001"
          y2="359"
          id="line1544"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.2"
          y1="359"
          x2="173"
          y2="359"
          id="line1546"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="199.6,481.2 199.5,481.3 190.5,483.5 190.7,483.4   "
          id="polyline1548"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="481.10001"
          x2="199.60001"
          y2="481.20001"
          id="line1550"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.2"
          y1="480.89999"
          x2="199.89999"
          y2="481.10001"
          id="line1552"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201"
          y1="480.5"
          x2="200.2"
          y2="480.89999"
          id="line1554"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="359"
          x2="173"
          y2="359"
          id="line1556"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.60001"
          y1="359"
          x2="173"
          y2="359"
          id="line1558"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="156.5"
          y1="379.70001"
          x2="156.89999"
          y2="379.29999"
          id="line1560"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="156.3"
          y1="380.29999"
          x2="156.5"
          y2="379.70001"
          id="line1562"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="156.39999"
          y1="380.79999"
          x2="156.3"
          y2="380.29999"
          id="line1564"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.2"
          y1="387.60001"
          x2="156.39999"
          y2="380.79999"
          id="line1566"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.89999"
          y1="394.5"
          x2="158.2"
          y2="387.60001"
          id="line1568"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.2"
          y1="394.89999"
          x2="159.89999"
          y2="394.5"
          id="line1570"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.60001"
          y1="395.29999"
          x2="160.2"
          y2="394.89999"
          id="line1572"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.10001"
          y1="395.5"
          x2="160.60001"
          y2="395.29999"
          id="line1574"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.39999"
          y1="395.5"
          x2="161.10001"
          y2="395.5"
          id="line1576"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.39999"
          y1="395.70001"
          x2="162.39999"
          y2="395.5"
          id="line1578"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.89999"
          y1="364.10001"
          x2="159.5"
          y2="363.60001"
          id="line1580"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158"
          y1="365"
          x2="158.89999"
          y2="364.10001"
          id="line1582"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.7"
          y1="366.39999"
          x2="158"
          y2="365"
          id="line1584"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.60001"
          y1="367.5"
          x2="157.7"
          y2="366.39999"
          id="line1586"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.60001"
          y1="367"
          x2="157.60001"
          y2="367.39999"
          id="line1588"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.7"
          y1="366.39999"
          x2="157.60001"
          y2="367"
          id="line1590"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.89999"
          y1="376.60001"
          x2="158.89999"
          y2="376.60001"
          id="line1592"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.3"
          y1="378.5"
          x2="159.10001"
          y2="377.5"
          id="line1594"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.2"
          y1="469"
          x2="180.5"
          y2="466.70001"
          id="line1596"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.89999"
          y1="471.39999"
          x2="181.2"
          y2="469"
          id="line1598"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="181.9,471.4 182.3,472.5 182.5,473.1 182.8,474.2   "
          id="polyline1600"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.89999"
          y1="477.70001"
          x2="184.2"
          y2="478.5"
          id="line1602"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.7"
          y1="476.89999"
          x2="183.89999"
          y2="477.70001"
          id="line1604"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.39999"
          y1="476"
          x2="183.7"
          y2="476.89999"
          id="line1606"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.10001"
          y1="475.10001"
          x2="183.39999"
          y2="476"
          id="line1608"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="474.20001"
          x2="183.10001"
          y2="475.10001"
          id="line1610"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.89999"
          y1="480.29999"
          x2="185.10001"
          y2="480.79999"
          id="line1612"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.7"
          y1="479.79999"
          x2="184.89999"
          y2="480.29999"
          id="line1614"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.3"
          y1="478.60001"
          x2="184.7"
          y2="479.79999"
          id="line1616"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="478.5"
          x2="184.3"
          y2="478.60001"
          id="line1618"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.60001"
          y1="479.60001"
          x2="184.2"
          y2="478.5"
          id="line1620"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.89999"
          y1="480.10001"
          x2="184.60001"
          y2="479.60001"
          id="line1622"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="480.60001"
          x2="184.89999"
          y2="480.10001"
          id="line1624"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="480.60001"
          x2="185.10001"
          y2="480.60001"
          id="line1626"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.7"
          y1="481.70001"
          x2="186.10001"
          y2="482.29999"
          id="line1628"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="480.79999"
          x2="185.7"
          y2="481.70001"
          id="line1630"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.39999"
          y1="481.29999"
          x2="185.10001"
          y2="480.79999"
          id="line1632"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="421.10001"
          x2="183.39999"
          y2="417.20001"
          id="line1634"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="421.10001"
          x2="184.2"
          y2="421.10001"
          id="line1636"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.39999"
          y1="417.20001"
          x2="184.2"
          y2="421.10001"
          id="line1638"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.10001"
          y1="442.10001"
          x2="173.60001"
          y2="440.20001"
          id="line1640"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175"
          y1="445.70001"
          x2="174.10001"
          y2="442.10001"
          id="line1642"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.8"
          y1="449.10001"
          x2="175"
          y2="445.70001"
          id="line1644"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.60001"
          y1="452.29999"
          x2="175.8"
          y2="449.10001"
          id="line1646"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.3"
          y1="455.20001"
          x2="176.60001"
          y2="452.29999"
          id="line1648"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178"
          y1="457.70001"
          x2="177.3"
          y2="455.20001"
          id="line1650"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.60001"
          y1="460.20001"
          x2="178"
          y2="457.70001"
          id="line1652"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="461.79999"
          x2="178.60001"
          y2="460.20001"
          id="line1654"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.5"
          y1="463.5"
          x2="179.10001"
          y2="461.79999"
          id="line1656"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180"
          y1="465.10001"
          x2="179.5"
          y2="463.5"
          id="line1658"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.60001"
          y1="440.10001"
          x2="173"
          y2="437.5"
          id="line1660"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.60001"
          y1="440.20001"
          x2="173.60001"
          y2="440.10001"
          id="line1662"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="437.5"
          x2="173.60001"
          y2="440.20001"
          id="line1664"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.2"
          y1="394.89999"
          x2="162.7"
          y2="392.89999"
          id="line1666"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.7"
          y1="397.10001"
          x2="163.2"
          y2="394.89999"
          id="line1668"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164.3"
          y1="399.5"
          x2="163.7"
          y2="397.10001"
          id="line1670"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164.89999"
          y1="401.89999"
          x2="164.3"
          y2="399.5"
          id="line1672"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="165.39999"
          y1="404.39999"
          x2="164.89999"
          y2="401.89999"
          id="line1674"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166"
          y1="406.89999"
          x2="165.39999"
          y2="404.39999"
          id="line1676"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166.89999"
          y1="410.79999"
          x2="166"
          y2="406.89999"
          id="line1678"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="167.7"
          y1="414.70001"
          x2="166.89999"
          y2="410.79999"
          id="line1680"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="168.5"
          y1="418.10001"
          x2="167.7"
          y2="414.70001"
          id="line1682"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="169.2"
          y1="421.39999"
          x2="168.5"
          y2="418.10001"
          id="line1684"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="170"
          y1="425.10001"
          x2="169.2"
          y2="421.39999"
          id="line1686"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="170.89999"
          y1="428.70001"
          x2="170"
          y2="425.10001"
          id="line1688"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.39999"
          y1="430.89999"
          x2="170.89999"
          y2="428.70001"
          id="line1690"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.89999"
          y1="433.10001"
          x2="171.39999"
          y2="430.89999"
          id="line1692"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.39999"
          y1="435.29999"
          x2="171.89999"
          y2="433.10001"
          id="line1694"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="437.5"
          x2="172.39999"
          y2="435.29999"
          id="line1696"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.10001"
          y1="390.29999"
          x2="162.5"
          y2="391.70001"
          id="line1698"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.8"
          y1="389"
          x2="162.10001"
          y2="390.29999"
          id="line1700"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.3"
          y1="386.70001"
          x2="161.8"
          y2="389"
          id="line1702"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.8"
          y1="389.10001"
          x2="161.3"
          y2="386.70001"
          id="line1704"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.10001"
          y1="390.39999"
          x2="161.8"
          y2="389.10001"
          id="line1706"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.7"
          y1="392.89999"
          x2="162.10001"
          y2="390.39999"
          id="line1708"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.7"
          y1="380.10001"
          x2="159.3"
          y2="378.5"
          id="line1710"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.89999"
          y1="381"
          x2="159.7"
          y2="380.10001"
          id="line1712"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.3"
          y1="382.39999"
          x2="159.89999"
          y2="381"
          id="line1714"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.2"
          y1="382.10001"
          x2="160.3"
          y2="382.39999"
          id="line1716"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.89999"
          y1="381"
          x2="160.2"
          y2="382.10001"
          id="line1718"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.89999"
          y1="376.70001"
          x2="182.89999"
          y2="379"
          id="line1720"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.89999"
          y1="374.39999"
          x2="182.89999"
          y2="376.70001"
          id="line1722"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.89999"
          y1="374.10001"
          x2="182.89999"
          y2="374.39999"
          id="line1724"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.89999"
          y1="373.79999"
          x2="182.89999"
          y2="374.10001"
          id="line1726"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="373.5"
          x2="182.89999"
          y2="373.79999"
          id="line1728"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.7"
          y1="373.20001"
          x2="182.8"
          y2="373.5"
          id="line1730"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.3"
          y1="372.20001"
          x2="182.7"
          y2="373.20001"
          id="line1732"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.89999"
          y1="371.10001"
          x2="182.3"
          y2="372.20001"
          id="line1734"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.39999"
          y1="370.10001"
          x2="181.89999"
          y2="371.10001"
          id="line1736"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181"
          y1="369"
          x2="181.39999"
          y2="370.10001"
          id="line1738"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.8"
          y1="368.70001"
          x2="181"
          y2="369"
          id="line1740"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.60001"
          y1="368.5"
          x2="180.8"
          y2="368.70001"
          id="line1742"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.60001"
          y1="368.5"
          x2="180.60001"
          y2="368.5"
          id="line1744"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.8"
          y1="379.79999"
          x2="182.7"
          y2="379.60001"
          id="line1746"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.89999"
          y1="380"
          x2="181.8"
          y2="379.79999"
          id="line1748"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.8"
          y1="467.70001"
          x2="181.2"
          y2="469"
          id="line1750"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.5"
          y1="466.70001"
          x2="180.8"
          y2="467.70001"
          id="line1752"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.7"
          y1="476.89999"
          x2="183.10001"
          y2="475.10001"
          id="line1754"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="478.5"
          x2="183.7"
          y2="476.89999"
          id="line1756"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.60001"
          y1="482.89999"
          x2="186.39999"
          y2="482.70001"
          id="line1758"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187"
          y1="483.20001"
          x2="186.60001"
          y2="482.89999"
          id="line1760"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.39999"
          y1="395.5"
          x2="161.7"
          y2="395.5"
          id="line1762"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.10001"
          y1="395.60001"
          x2="162.39999"
          y2="395.5"
          id="line1764"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.39999"
          y1="395.60001"
          x2="163.10001"
          y2="395.60001"
          id="line1766"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.89999"
          y1="360.70001"
          x2="193.39999"
          y2="361.79999"
          id="line1768"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.39999"
          y1="360.39999"
          x2="187.89999"
          y2="360.70001"
          id="line1770"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.7"
          y1="360.29999"
          x2="186.39999"
          y2="360.39999"
          id="line1772"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.39999"
          y1="360.29999"
          x2="185.7"
          y2="360.29999"
          id="line1774"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.39999"
          y1="359.39999"
          x2="185.39999"
          y2="360.29999"
          id="line1776"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.89999"
          y1="359.39999"
          x2="178.39999"
          y2="359.39999"
          id="line1778"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177"
          y1="359.20001"
          x2="177.89999"
          y2="359.39999"
          id="line1780"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.5"
          y1="359"
          x2="177"
          y2="359.20001"
          id="line1782"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.2"
          y1="359"
          x2="175.5"
          y2="359"
          id="line1784"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.8"
          y1="363"
          x2="196.60001"
          y2="362.79999"
          id="line1786"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197"
          y1="363.20001"
          x2="196.8"
          y2="363"
          id="line1788"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.60001"
          y1="362.79999"
          x2="197"
          y2="363.20001"
          id="line1790"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.10001"
          y1="362.60001"
          x2="196.3"
          y2="362.70001"
          id="line1792"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.39999"
          y1="361.79999"
          x2="196.10001"
          y2="362.60001"
          id="line1794"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.2"
          y1="363.70001"
          x2="197"
          y2="363.20001"
          id="line1796"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="378.20001"
          x2="197.2"
          y2="363.70001"
          id="line1798"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="378.70001"
          x2="199.89999"
          y2="378.20001"
          id="line1800"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="472.70001"
          x2="204.8"
          y2="473.79999"
          id="line1802"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.2"
          y1="471.60001"
          x2="205"
          y2="472.70001"
          id="line1804"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.3"
          y1="470.60001"
          x2="205.2"
          y2="471.60001"
          id="line1806"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.39999"
          y1="468.39999"
          x2="205.3"
          y2="470.60001"
          id="line1808"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.39999"
          y1="466.10001"
          x2="205.39999"
          y2="468.39999"
          id="line1810"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.39999"
          y1="465.5"
          x2="205.39999"
          y2="466.10001"
          id="line1812"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="459.60001"
          x2="205.39999"
          y2="465.5"
          id="line1814"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="458.39999"
          x2="205"
          y2="459.60001"
          id="line1816"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="454.10001"
          x2="204.8"
          y2="458.39999"
          id="line1818"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.89999"
          y1="451.70001"
          x2="204.3"
          y2="454.10001"
          id="line1820"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.89999"
          y1="439.60001"
          x2="203.89999"
          y2="451.70001"
          id="line1822"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.39999"
          y1="436.60001"
          x2="201.89999"
          y2="439.60001"
          id="line1824"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.8"
          y1="427.70001"
          x2="201.39999"
          y2="436.60001"
          id="line1826"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199"
          y1="423.60001"
          x2="199.8"
          y2="427.70001"
          id="line1828"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198"
          y1="417.70001"
          x2="199"
          y2="423.60001"
          id="line1830"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197"
          y1="411.5"
          x2="198"
          y2="417.70001"
          id="line1832"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196"
          y1="404.10001"
          x2="197"
          y2="411.5"
          id="line1834"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.8"
          y1="402.29999"
          x2="196"
          y2="404.10001"
          id="line1836"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.5"
          y1="399.29999"
          x2="195.8"
          y2="402.29999"
          id="line1838"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.39999"
          y1="398.5"
          x2="195.5"
          y2="399.29999"
          id="line1840"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.2"
          y1="395.70001"
          x2="195.39999"
          y2="398.5"
          id="line1842"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.10001"
          y1="394.29999"
          x2="195.2"
          y2="395.70001"
          id="line1844"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.10001"
          y1="392.89999"
          x2="195.10001"
          y2="394.29999"
          id="line1846"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.2"
          y1="390.10001"
          x2="195.10001"
          y2="392.89999"
          id="line1848"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.2"
          y1="388.79999"
          x2="195.2"
          y2="390.10001"
          id="line1850"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.39999"
          y1="387.60001"
          x2="195.2"
          y2="388.79999"
          id="line1852"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="386.39999"
          x2="195.39999"
          y2="387.60001"
          id="line1854"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.2"
          y1="380.79999"
          x2="198.89999"
          y2="380"
          id="line1856"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 196.1,384.4 0.9,-1.9 c 0,0 0.2,-0.4 0.4,-0.6 l 0.8,-1.1"
          id="path1858"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="378.70001"
          x2="199.7"
          y2="379"
          id="line1860"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="379"
          x2="199.89999"
          y2="378.70001"
          id="line1862"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="379.29999"
          x2="199.7"
          y2="379"
          id="line1864"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="379.10001"
          x2="199.60001"
          y2="379.29999"
          id="line1866"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 190.5,474.9"
          id="path1868"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.8"
          y1="378.29999"
          x2="171"
          y2="377"
          id="line1870"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.8"
          y1="380.20001"
          x2="171.8"
          y2="378.29999"
          id="line1872"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.5"
          y1="384"
          x2="172.8"
          y2="380.20001"
          id="line1874"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.3"
          y1="386.10001"
          x2="174.5"
          y2="384"
          id="line1876"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.5"
          y1="386.70001"
          x2="175.3"
          y2="386.10001"
          id="line1878"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.89999"
          y1="390.89999"
          x2="175.5"
          y2="386.70001"
          id="line1880"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.7"
          y1="397.5"
          x2="176.89999"
          y2="390.89999"
          id="line1882"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.60001"
          y1="405.29999"
          x2="178.7"
          y2="397.5"
          id="line1884"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="421.10001"
          x2="180.60001"
          y2="405.29999"
          id="line1886"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.7"
          y1="423.39999"
          x2="184.2"
          y2="421.10001"
          id="line1888"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.8"
          y1="438.39999"
          x2="184.7"
          y2="423.39999"
          id="line1890"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.3"
          y1="446.29999"
          x2="187.8"
          y2="438.39999"
          id="line1892"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.8"
          y1="449.10001"
          x2="189.3"
          y2="446.29999"
          id="line1894"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.39999"
          y1="460.20001"
          x2="189.8"
          y2="449.10001"
          id="line1896"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.89999"
          y1="466.60001"
          x2="191.39999"
          y2="460.20001"
          id="line1898"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 191.9,466.6 v 0.4 c 0,0 0,5.7 -1.2,7.9 0,0 -1,2.8 -3.1,2.6 h -0.3"
          id="path1900"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 165.6,371.6"
          id="path1902"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 162.9,371 0.2,-0.1 c 1.2,-0.4 2.5,0.6 2.5,0.6 l 1.3,0.9 c 0.1,0.1 0.2,0.1 0.3,0.2 0.5,0.4 2.1,1.8 2.2,2 l 1.6,2.2"
          id="path1904"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="473.79999"
          x2="204.60001"
          y2="474.39999"
          id="line1906"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="474.5"
          x2="204.8"
          y2="473.79999"
          id="line1908"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="475"
          x2="204.60001"
          y2="474.5"
          id="line1910"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="474.79999"
          x2="204.5"
          y2="475"
          id="line1912"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="476.89999"
          x2="203.2"
          y2="478.10001"
          id="line1914"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="475"
          x2="203.8"
          y2="476.89999"
          id="line1916"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.2"
          y1="395.70001"
          x2="195.5"
          y2="399.29999"
          id="line1918"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.2"
          y1="388.79999"
          x2="195.2"
          y2="395.70001"
          id="line1920"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.8"
          y1="363.70001"
          x2="195.8"
          y2="364"
          id="line1922"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.7"
          y1="363.5"
          x2="195.8"
          y2="363.70001"
          id="line1924"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.7"
          y1="363.29999"
          x2="195.7"
          y2="363.5"
          id="line1926"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.7"
          y1="363.10001"
          x2="195.7"
          y2="363.29999"
          id="line1928"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="362.89999"
          x2="195.7"
          y2="363.10001"
          id="line1930"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="362.79999"
          x2="195.60001"
          y2="362.89999"
          id="line1932"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="362.70001"
          x2="195.60001"
          y2="362.79999"
          id="line1934"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="362.60001"
          x2="195.60001"
          y2="362.70001"
          id="line1936"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.8"
          y1="362.60001"
          x2="195.60001"
          y2="362.60001"
          id="line1938"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.10001"
          y1="362.60001"
          x2="195.8"
          y2="362.60001"
          id="line1940"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.39999"
          y1="365.79999"
          x2="184.39999"
          y2="366"
          id="line1942"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.39999"
          y1="365.60001"
          x2="184.39999"
          y2="365.79999"
          id="line1944"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.3"
          y1="365.29999"
          x2="184.39999"
          y2="365.60001"
          id="line1946"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.3"
          y1="365.10001"
          x2="184.3"
          y2="365.29999"
          id="line1948"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="364.89999"
          x2="184.3"
          y2="365.10001"
          id="line1950"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="364.79999"
          x2="184.2"
          y2="364.89999"
          id="line1952"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="364.70001"
          x2="184.2"
          y2="364.79999"
          id="line1954"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="364.60001"
          x2="184.2"
          y2="364.70001"
          id="line1956"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184"
          y1="364.70001"
          x2="184.2"
          y2="364.60001"
          id="line1958"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.8"
          y1="364.79999"
          x2="184"
          y2="364.70001"
          id="line1960"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.60001"
          y1="365"
          x2="183.8"
          y2="364.79999"
          id="line1962"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.39999"
          y1="365.20001"
          x2="183.60001"
          y2="365"
          id="line1964"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.2"
          y1="365.5"
          x2="183.39999"
          y2="365.20001"
          id="line1966"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.2"
          y1="365.70001"
          x2="183.2"
          y2="365.5"
          id="line1968"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.10001"
          y1="366"
          x2="183.2"
          y2="365.70001"
          id="line1970"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.10001"
          y1="366.20001"
          x2="183.10001"
          y2="366"
          id="line1972"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.89999"
          y1="359.39999"
          x2="177.89999"
          y2="359.39999"
          id="line1974"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.39999"
          y1="359.39999"
          x2="177.89999"
          y2="359.39999"
          id="line1976"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.8"
          y1="359.5"
          x2="177.39999"
          y2="359.39999"
          id="line1978"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.3"
          y1="359.70001"
          x2="177.8"
          y2="359.5"
          id="line1980"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181"
          y1="359.89999"
          x2="179.3"
          y2="359.70001"
          id="line1982"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="360.10001"
          x2="181"
          y2="359.89999"
          id="line1984"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.89999"
          y1="360.29999"
          x2="182.8"
          y2="360.10001"
          id="line1986"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.39999"
          y1="360.29999"
          x2="184.89999"
          y2="360.29999"
          id="line1988"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.5"
          y1="483.5"
          x2="190.2"
          y2="483.60001"
          id="line1990"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.89999"
          y1="483.29999"
          x2="190.7"
          y2="483.39999"
          id="line1992"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.2"
          y1="483.10001"
          x2="190.89999"
          y2="483.29999"
          id="line1994"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.39999"
          y1="482.89999"
          x2="191.2"
          y2="483.10001"
          id="line1996"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.60001"
          y1="482.70001"
          x2="191.39999"
          y2="482.89999"
          id="line1998"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.89999"
          y1="482.5"
          x2="191.60001"
          y2="482.70001"
          id="line2000"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.10001"
          y1="482.20001"
          x2="191.89999"
          y2="482.5"
          id="line2002"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.39999"
          y1="482"
          x2="192.10001"
          y2="482.20001"
          id="line2004"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.60001"
          y1="481.70001"
          x2="192.39999"
          y2="482"
          id="line2006"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.8"
          y1="481.39999"
          x2="192.60001"
          y2="481.70001"
          id="line2008"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.10001"
          y1="481"
          x2="192.8"
          y2="481.39999"
          id="line2010"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.3"
          y1="480.60001"
          x2="193.10001"
          y2="481"
          id="line2012"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.5"
          y1="480.20001"
          x2="193.3"
          y2="480.60001"
          id="line2014"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.7"
          y1="479.70001"
          x2="193.5"
          y2="480.20001"
          id="line2016"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.89999"
          y1="479.20001"
          x2="193.7"
          y2="479.70001"
          id="line2018"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.10001"
          y1="478.60001"
          x2="193.89999"
          y2="479.20001"
          id="line2020"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.3"
          y1="478"
          x2="194.10001"
          y2="478.60001"
          id="line2022"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.5"
          y1="477.20001"
          x2="194.3"
          y2="478"
          id="line2024"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.60001"
          y1="476.5"
          x2="194.5"
          y2="477.20001"
          id="line2026"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.7"
          y1="475.70001"
          x2="194.60001"
          y2="476.5"
          id="line2028"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.8"
          y1="474.89999"
          x2="194.7"
          y2="475.70001"
          id="line2030"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.89999"
          y1="473.89999"
          x2="194.8"
          y2="474.89999"
          id="line2032"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195"
          y1="472.89999"
          x2="194.89999"
          y2="473.89999"
          id="line2034"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195"
          y1="471.89999"
          x2="195"
          y2="472.89999"
          id="line2036"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195"
          y1="470.70001"
          x2="195"
          y2="471.89999"
          id="line2038"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195"
          y1="469.5"
          x2="195"
          y2="470.70001"
          id="line2040"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195"
          y1="468"
          x2="195"
          y2="469.5"
          id="line2042"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.89999"
          y1="466.60001"
          x2="195"
          y2="468"
          id="line2044"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.8"
          y1="465.20001"
          x2="194.89999"
          y2="466.60001"
          id="line2046"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.60001"
          y1="463.79999"
          x2="194.8"
          y2="465.20001"
          id="line2048"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.5"
          y1="462.29999"
          x2="194.60001"
          y2="463.79999"
          id="line2050"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.3"
          y1="460.79999"
          x2="194.5"
          y2="462.29999"
          id="line2052"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.10001"
          y1="459.29999"
          x2="194.3"
          y2="460.79999"
          id="line2054"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.89999"
          y1="457.70001"
          x2="194.10001"
          y2="459.29999"
          id="line2056"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.60001"
          y1="456.10001"
          x2="193.89999"
          y2="457.70001"
          id="line2058"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.39999"
          y1="454.5"
          x2="193.60001"
          y2="456.10001"
          id="line2060"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.10001"
          y1="452.79999"
          x2="193.39999"
          y2="454.5"
          id="line2062"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.89999"
          y1="451.20001"
          x2="193.10001"
          y2="452.79999"
          id="line2064"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.60001"
          y1="449.5"
          x2="192.89999"
          y2="451.20001"
          id="line2066"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.3"
          y1="447.79999"
          x2="192.60001"
          y2="449.5"
          id="line2068"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192"
          y1="446.10001"
          x2="192.3"
          y2="447.79999"
          id="line2070"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.8"
          y1="444.39999"
          x2="192"
          y2="446.10001"
          id="line2072"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.5"
          y1="442.70001"
          x2="191.8"
          y2="444.39999"
          id="line2074"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.2"
          y1="441"
          x2="191.5"
          y2="442.70001"
          id="line2076"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.89999"
          y1="439.29999"
          x2="191.2"
          y2="441"
          id="line2078"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.5"
          y1="437.60001"
          x2="190.89999"
          y2="439.29999"
          id="line2080"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.2"
          y1="435.89999"
          x2="190.5"
          y2="437.60001"
          id="line2082"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.89999"
          y1="434.10001"
          x2="190.2"
          y2="435.89999"
          id="line2084"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.60001"
          y1="432.39999"
          x2="189.89999"
          y2="434.10001"
          id="line2086"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.3"
          y1="430.60001"
          x2="189.60001"
          y2="432.39999"
          id="line2088"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189"
          y1="428.79999"
          x2="189.3"
          y2="430.60001"
          id="line2090"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.60001"
          y1="427.10001"
          x2="189"
          y2="428.79999"
          id="line2092"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.3"
          y1="425.29999"
          x2="188.60001"
          y2="427.10001"
          id="line2094"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188"
          y1="423.5"
          x2="188.3"
          y2="425.29999"
          id="line2096"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.60001"
          y1="421.70001"
          x2="188"
          y2="423.5"
          id="line2098"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="419.89999"
          x2="187.60001"
          y2="421.70001"
          id="line2100"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.89999"
          y1="418.10001"
          x2="187.3"
          y2="419.89999"
          id="line2102"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.60001"
          y1="416.39999"
          x2="186.89999"
          y2="418.10001"
          id="line2104"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.3"
          y1="414.60001"
          x2="186.60001"
          y2="416.39999"
          id="line2106"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.89999"
          y1="412.79999"
          x2="186.3"
          y2="414.60001"
          id="line2108"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.60001"
          y1="411"
          x2="185.89999"
          y2="412.79999"
          id="line2110"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.3"
          y1="409.20001"
          x2="185.60001"
          y2="411"
          id="line2112"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185"
          y1="407.5"
          x2="185.3"
          y2="409.20001"
          id="line2114"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.60001"
          y1="405.20001"
          x2="185"
          y2="407.5"
          id="line2116"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.3"
          y1="403"
          x2="184.60001"
          y2="405.20001"
          id="line2118"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184"
          y1="401.20001"
          x2="184.3"
          y2="403"
          id="line2120"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.8"
          y1="399.39999"
          x2="184"
          y2="401.20001"
          id="line2122"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.60001"
          y1="397.70001"
          x2="183.8"
          y2="399.39999"
          id="line2124"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.5"
          y1="396"
          x2="183.60001"
          y2="397.70001"
          id="line2126"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.39999"
          y1="393.10001"
          x2="183.5"
          y2="396"
          id="line2128"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.39999"
          y1="391.60001"
          x2="183.39999"
          y2="393.10001"
          id="line2130"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.60001"
          y1="390.10001"
          x2="183.39999"
          y2="391.60001"
          id="line2132"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.7"
          y1="389"
          x2="183.60001"
          y2="390.10001"
          id="line2134"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184"
          y1="387.79999"
          x2="183.7"
          y2="389"
          id="line2136"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.3"
          y1="386.70001"
          x2="184"
          y2="387.79999"
          id="line2138"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.7"
          y1="385.70001"
          x2="184.3"
          y2="386.70001"
          id="line2140"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="384.89999"
          x2="184.7"
          y2="385.70001"
          id="line2142"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.60001"
          y1="384"
          x2="185.10001"
          y2="384.89999"
          id="line2144"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.10001"
          y1="383.29999"
          x2="185.60001"
          y2="384"
          id="line2146"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.60001"
          y1="382.60001"
          x2="186.10001"
          y2="383.29999"
          id="line2148"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="381.79999"
          x2="186.60001"
          y2="382.60001"
          id="line2150"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.10001"
          y1="380.70001"
          x2="187.10001"
          y2="380.5"
          id="line2152"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.2"
          y1="380.89999"
          x2="187.10001"
          y2="380.70001"
          id="line2154"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.2"
          y1="381.10001"
          x2="187.2"
          y2="380.89999"
          id="line2156"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.2"
          y1="381.29999"
          x2="187.2"
          y2="381.10001"
          id="line2158"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="381.39999"
          x2="187.2"
          y2="381.29999"
          id="line2160"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="381.60001"
          x2="187.3"
          y2="381.39999"
          id="line2162"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="381.70001"
          x2="187.3"
          y2="381.60001"
          id="line2164"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="381.79999"
          x2="187.3"
          y2="381.70001"
          id="line2166"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.10001"
          y1="381.79999"
          x2="187.3"
          y2="381.79999"
          id="line2168"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.89999"
          y1="381.79999"
          x2="187.10001"
          y2="381.79999"
          id="line2170"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.60001"
          y1="381.70001"
          x2="186.89999"
          y2="381.79999"
          id="line2172"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.39999"
          y1="381.5"
          x2="186.60001"
          y2="381.70001"
          id="line2174"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.2"
          y1="381.39999"
          x2="186.39999"
          y2="381.5"
          id="line2176"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186"
          y1="381.20001"
          x2="186.2"
          y2="381.39999"
          id="line2178"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.89999"
          y1="380.89999"
          x2="186"
          y2="381.20001"
          id="line2180"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.8"
          y1="380.70001"
          x2="185.89999"
          y2="380.89999"
          id="line2182"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.3"
          y1="399.5"
          x2="195.5"
          y2="399.29999"
          id="line2184"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.7"
          y1="399.60001"
          x2="195.3"
          y2="399.5"
          id="line2186"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.10001"
          y1="399.70001"
          x2="194.7"
          y2="399.60001"
          id="line2188"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.39999"
          y1="402.70001"
          x2="194.10001"
          y2="399.70001"
          id="line2190"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.60001"
          y1="404.5"
          x2="194.39999"
          y2="402.70001"
          id="line2192"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.10001"
          y1="408.20001"
          x2="194.60001"
          y2="404.5"
          id="line2194"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.39999"
          y1="410.10001"
          x2="195.10001"
          y2="408.20001"
          id="line2196"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="411.89999"
          x2="195.39999"
          y2="410.10001"
          id="line2198"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.10001"
          y1="415"
          x2="195.60001"
          y2="411.89999"
          id="line2200"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.7"
          y1="418.20001"
          x2="196.10001"
          y2="415"
          id="line2202"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.2"
          y1="421.10001"
          x2="196.7"
          y2="418.20001"
          id="line2204"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.7"
          y1="424.10001"
          x2="197.2"
          y2="421.10001"
          id="line2206"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.5"
          y1="378.79999"
          x2="198.5"
          y2="378.5"
          id="line2208"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.60001"
          y1="379"
          x2="198.5"
          y2="378.79999"
          id="line2210"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.60001"
          y1="379.29999"
          x2="198.60001"
          y2="379"
          id="line2212"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.60001"
          y1="379.39999"
          x2="198.60001"
          y2="379.29999"
          id="line2214"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.7"
          y1="379.60001"
          x2="198.60001"
          y2="379.39999"
          id="line2216"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.7"
          y1="379.70001"
          x2="198.7"
          y2="379.60001"
          id="line2218"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.7"
          y1="379.79999"
          x2="198.7"
          y2="379.70001"
          id="line2220"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199"
          y1="379.79999"
          x2="198.7"
          y2="379.79999"
          id="line2222"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.2"
          y1="379.70001"
          x2="199"
          y2="379.79999"
          id="line2224"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.39999"
          y1="379.5"
          x2="199.2"
          y2="379.70001"
          id="line2226"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.8"
          y1="423.79999"
          x2="199"
          y2="423.60001"
          id="line2228"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.3"
          y1="424"
          x2="198.8"
          y2="423.79999"
          id="line2230"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.7"
          y1="424.10001"
          x2="198.3"
          y2="424"
          id="line2232"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.10001"
          y1="426.20001"
          x2="197.7"
          y2="424.10001"
          id="line2234"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.5"
          y1="428.29999"
          x2="198.10001"
          y2="426.20001"
          id="line2236"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.89999"
          y1="430.5"
          x2="198.5"
          y2="428.29999"
          id="line2238"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.3"
          y1="432.79999"
          x2="198.89999"
          y2="430.5"
          id="line2240"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="435"
          x2="199.3"
          y2="432.79999"
          id="line2242"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.10001"
          y1="437.20001"
          x2="199.7"
          y2="435"
          id="line2244"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.39999"
          y1="438.79999"
          x2="200.10001"
          y2="437.20001"
          id="line2246"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.7"
          y1="440.29999"
          x2="200.39999"
          y2="438.79999"
          id="line2248"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.89999"
          y1="441.79999"
          x2="200.7"
          y2="440.29999"
          id="line2250"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.2"
          y1="443.39999"
          x2="200.89999"
          y2="441.79999"
          id="line2252"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.5"
          y1="444.89999"
          x2="201.2"
          y2="443.39999"
          id="line2254"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.7"
          y1="446.39999"
          x2="201.5"
          y2="444.89999"
          id="line2256"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202"
          y1="448"
          x2="201.7"
          y2="446.39999"
          id="line2258"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.2"
          y1="449.5"
          x2="202"
          y2="448"
          id="line2260"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.39999"
          y1="451"
          x2="202.2"
          y2="449.5"
          id="line2262"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.7"
          y1="452.39999"
          x2="202.39999"
          y2="451"
          id="line2264"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203"
          y1="454.89999"
          x2="202.7"
          y2="452.39999"
          id="line2266"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="457.10001"
          x2="203"
          y2="454.89999"
          id="line2268"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.60001"
          y1="459.29999"
          x2="203.3"
          y2="457.10001"
          id="line2270"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="460.5"
          x2="203.60001"
          y2="459.29999"
          id="line2272"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.89999"
          y1="462.10001"
          x2="203.8"
          y2="460.5"
          id="line2274"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="463.70001"
          x2="203.89999"
          y2="462.10001"
          id="line2276"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.2"
          y1="465.10001"
          x2="204.10001"
          y2="463.70001"
          id="line2278"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="466.60001"
          x2="204.2"
          y2="465.10001"
          id="line2280"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="467.10001"
          x2="204.3"
          y2="466.60001"
          id="line2282"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="468.29999"
          x2="204.3"
          y2="467.10001"
          id="line2284"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="469.5"
          x2="204.3"
          y2="468.29999"
          id="line2286"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="470.60001"
          x2="204.3"
          y2="469.5"
          id="line2288"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.2"
          y1="471.70001"
          x2="204.3"
          y2="470.60001"
          id="line2290"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="472.79999"
          x2="204.2"
          y2="471.70001"
          id="line2292"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.89999"
          y1="473.79999"
          x2="204.10001"
          y2="472.79999"
          id="line2294"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.7"
          y1="475"
          x2="203.89999"
          y2="473.79999"
          id="line2296"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.5"
          y1="475.70001"
          x2="203.7"
          y2="475"
          id="line2298"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="476.70001"
          x2="203.5"
          y2="475.70001"
          id="line2300"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.89999"
          y1="477.5"
          x2="203.3"
          y2="476.70001"
          id="line2302"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.60001"
          y1="478.20001"
          x2="202.89999"
          y2="477.5"
          id="line2304"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.3"
          y1="478.79999"
          x2="202.60001"
          y2="478.20001"
          id="line2306"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.89999"
          y1="479.29999"
          x2="202.3"
          y2="478.79999"
          id="line2308"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.60001"
          y1="479.79999"
          x2="201.89999"
          y2="479.29999"
          id="line2310"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.3"
          y1="480"
          x2="201.60001"
          y2="479.79999"
          id="line2312"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.10001"
          y1="480.20001"
          x2="201.3"
          y2="480"
          id="line2314"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.89999"
          y1="480.39999"
          x2="201.10001"
          y2="480.20001"
          id="line2316"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.7"
          y1="480.60001"
          x2="200.89999"
          y2="480.39999"
          id="line2318"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.5"
          y1="480.79999"
          x2="200.7"
          y2="480.60001"
          id="line2320"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.2"
          y1="480.89999"
          x2="200.5"
          y2="480.79999"
          id="line2322"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.39999"
          y1="373.5"
          x2="183.10001"
          y2="366.20001"
          id="line2324"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.8"
          y1="380.70001"
          x2="184.39999"
          y2="373.5"
          id="line2326"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.89999"
          y1="380.5"
          x2="187.10001"
          y2="380.5"
          id="line2328"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.7"
          y1="380.5"
          x2="186.89999"
          y2="380.5"
          id="line2330"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.5"
          y1="380.60001"
          x2="186.7"
          y2="380.5"
          id="line2332"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.3"
          y1="380.60001"
          x2="186.5"
          y2="380.60001"
          id="line2334"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.2"
          y1="380.70001"
          x2="186.3"
          y2="380.60001"
          id="line2336"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186"
          y1="380.70001"
          x2="186.2"
          y2="380.70001"
          id="line2338"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.89999"
          y1="380.70001"
          x2="186"
          y2="380.70001"
          id="line2340"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.8"
          y1="380.70001"
          x2="185.89999"
          y2="380.70001"
          id="line2342"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="381.60001"
          x2="185.8"
          y2="380.70001"
          id="line2344"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.5"
          y1="382.20001"
          x2="185.10001"
          y2="381.60001"
          id="line2346"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184"
          y1="383"
          x2="184.5"
          y2="382.20001"
          id="line2348"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.60001"
          y1="383.89999"
          x2="184"
          y2="383"
          id="line2350"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.10001"
          y1="384.79999"
          x2="183.60001"
          y2="383.89999"
          id="line2352"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="385.79999"
          x2="183.10001"
          y2="384.79999"
          id="line2354"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.5"
          y1="387"
          x2="182.8"
          y2="385.79999"
          id="line2356"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.2"
          y1="388.29999"
          x2="182.5"
          y2="387"
          id="line2358"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182"
          y1="389.5"
          x2="182.2"
          y2="388.29999"
          id="line2360"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.89999"
          y1="391"
          x2="182"
          y2="389.5"
          id="line2362"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.89999"
          y1="392.70001"
          x2="181.89999"
          y2="391"
          id="line2364"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.89999"
          y1="394.20001"
          x2="181.89999"
          y2="392.70001"
          id="line2366"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182"
          y1="395.79999"
          x2="181.89999"
          y2="394.20001"
          id="line2368"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.2"
          y1="397.5"
          x2="182"
          y2="395.79999"
          id="line2370"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.39999"
          y1="399.29999"
          x2="182.2"
          y2="397.5"
          id="line2372"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.60001"
          y1="401.10001"
          x2="182.39999"
          y2="399.29999"
          id="line2374"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.89999"
          y1="403"
          x2="182.60001"
          y2="401.10001"
          id="line2376"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.2"
          y1="405.20001"
          x2="182.89999"
          y2="403"
          id="line2378"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.60001"
          y1="407.5"
          x2="183.2"
          y2="405.20001"
          id="line2380"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.89999"
          y1="409.29999"
          x2="183.60001"
          y2="407.5"
          id="line2382"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="411.10001"
          x2="183.89999"
          y2="409.29999"
          id="line2384"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.60001"
          y1="412.89999"
          x2="184.2"
          y2="411.10001"
          id="line2386"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.89999"
          y1="414.70001"
          x2="184.60001"
          y2="412.89999"
          id="line2388"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.2"
          y1="416.5"
          x2="184.89999"
          y2="414.70001"
          id="line2390"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.60001"
          y1="418.20001"
          x2="185.2"
          y2="416.5"
          id="line2392"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.89999"
          y1="420"
          x2="185.60001"
          y2="418.20001"
          id="line2394"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.2"
          y1="421.79999"
          x2="185.89999"
          y2="420"
          id="line2396"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.60001"
          y1="423.5"
          x2="186.2"
          y2="421.79999"
          id="line2398"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.89999"
          y1="425.29999"
          x2="186.60001"
          y2="423.5"
          id="line2400"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.2"
          y1="427.10001"
          x2="186.89999"
          y2="425.29999"
          id="line2402"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.60001"
          y1="428.79999"
          x2="187.2"
          y2="427.10001"
          id="line2404"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.89999"
          y1="430.60001"
          x2="187.60001"
          y2="428.79999"
          id="line2406"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.2"
          y1="432.29999"
          x2="187.89999"
          y2="430.60001"
          id="line2408"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.5"
          y1="434.10001"
          x2="188.2"
          y2="432.29999"
          id="line2410"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.8"
          y1="435.79999"
          x2="188.5"
          y2="434.10001"
          id="line2412"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.10001"
          y1="437.5"
          x2="188.8"
          y2="435.79999"
          id="line2414"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.39999"
          y1="439.20001"
          x2="189.10001"
          y2="437.5"
          id="line2416"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.7"
          y1="440.89999"
          x2="189.39999"
          y2="439.20001"
          id="line2418"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190"
          y1="442.60001"
          x2="189.7"
          y2="440.89999"
          id="line2420"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.3"
          y1="444.29999"
          x2="190"
          y2="442.60001"
          id="line2422"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.60001"
          y1="446"
          x2="190.3"
          y2="444.29999"
          id="line2424"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.89999"
          y1="447.70001"
          x2="190.60001"
          y2="446"
          id="line2426"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.2"
          y1="449.29999"
          x2="190.89999"
          y2="447.70001"
          id="line2428"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.39999"
          y1="451"
          x2="191.2"
          y2="449.29999"
          id="line2430"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.7"
          y1="452.60001"
          x2="191.39999"
          y2="451"
          id="line2432"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.89999"
          y1="454.20001"
          x2="191.7"
          y2="452.60001"
          id="line2434"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.2"
          y1="455.79999"
          x2="191.89999"
          y2="454.20001"
          id="line2436"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.39999"
          y1="457.39999"
          x2="192.2"
          y2="455.79999"
          id="line2438"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.60001"
          y1="458.89999"
          x2="192.39999"
          y2="457.39999"
          id="line2440"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.8"
          y1="460.5"
          x2="192.60001"
          y2="458.89999"
          id="line2442"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.89999"
          y1="461.89999"
          x2="192.8"
          y2="460.5"
          id="line2444"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.10001"
          y1="463.39999"
          x2="192.89999"
          y2="461.89999"
          id="line2446"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.2"
          y1="464.79999"
          x2="193.10001"
          y2="463.39999"
          id="line2448"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.3"
          y1="466.10001"
          x2="193.2"
          y2="464.79999"
          id="line2450"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.39999"
          y1="467.39999"
          x2="193.3"
          y2="466.10001"
          id="line2452"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.5"
          y1="468.89999"
          x2="193.39999"
          y2="467.39999"
          id="line2454"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.5"
          y1="470.10001"
          x2="193.5"
          y2="468.89999"
          id="line2456"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.39999"
          y1="471.20001"
          x2="193.5"
          y2="470.10001"
          id="line2458"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.39999"
          y1="472.20001"
          x2="193.39999"
          y2="471.20001"
          id="line2460"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.3"
          y1="473.20001"
          x2="193.39999"
          y2="472.20001"
          id="line2462"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.2"
          y1="474.10001"
          x2="193.3"
          y2="473.20001"
          id="line2464"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.10001"
          y1="474.89999"
          x2="193.2"
          y2="474.10001"
          id="line2466"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193"
          y1="475.70001"
          x2="193.10001"
          y2="474.89999"
          id="line2468"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.8"
          y1="476.39999"
          x2="193"
          y2="475.70001"
          id="line2470"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.60001"
          y1="477.10001"
          x2="192.8"
          y2="476.39999"
          id="line2472"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.5"
          y1="477.70001"
          x2="192.60001"
          y2="477.10001"
          id="line2474"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.3"
          y1="478.29999"
          x2="192.5"
          y2="477.70001"
          id="line2476"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.10001"
          y1="478.79999"
          x2="192.3"
          y2="478.29999"
          id="line2478"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.8"
          y1="479.29999"
          x2="192.10001"
          y2="478.79999"
          id="line2480"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.60001"
          y1="479.70001"
          x2="191.8"
          y2="479.29999"
          id="line2482"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.39999"
          y1="480.10001"
          x2="191.60001"
          y2="479.70001"
          id="line2484"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.2"
          y1="480.39999"
          x2="191.39999"
          y2="480.10001"
          id="line2486"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.89999"
          y1="480.79999"
          x2="191.2"
          y2="480.39999"
          id="line2488"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.7"
          y1="481.10001"
          x2="190.89999"
          y2="480.79999"
          id="line2490"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.5"
          y1="481.29999"
          x2="190.7"
          y2="481.10001"
          id="line2492"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.2"
          y1="481.60001"
          x2="190.5"
          y2="481.29999"
          id="line2494"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190"
          y1="481.79999"
          x2="190.2"
          y2="481.60001"
          id="line2496"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.7"
          y1="482"
          x2="190"
          y2="481.79999"
          id="line2498"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.5"
          y1="482.20001"
          x2="189.7"
          y2="482"
          id="line2500"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.3"
          y1="482.29999"
          x2="189.5"
          y2="482.20001"
          id="line2502"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189"
          y1="482.5"
          x2="189.3"
          y2="482.29999"
          id="line2504"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.8"
          y1="482.60001"
          x2="189"
          y2="482.5"
          id="line2506"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.60001"
          y1="482.70001"
          x2="188.8"
          y2="482.60001"
          id="line2508"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.3"
          y1="482.79999"
          x2="188.60001"
          y2="482.70001"
          id="line2510"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.10001"
          y1="482.79999"
          x2="188.3"
          y2="482.79999"
          id="line2512"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.89999"
          y1="482.79999"
          x2="188.10001"
          y2="482.79999"
          id="line2514"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.7"
          y1="482.89999"
          x2="187.89999"
          y2="482.79999"
          id="line2516"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.5"
          y1="482.79999"
          x2="187.7"
          y2="482.89999"
          id="line2518"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="482.79999"
          x2="187.5"
          y2="482.79999"
          id="line2520"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.10001"
          y1="482.79999"
          x2="187.3"
          y2="482.79999"
          id="line2522"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.89999"
          y1="482.70001"
          x2="187.10001"
          y2="482.79999"
          id="line2524"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.60001"
          y1="482.60001"
          x2="186.89999"
          y2="482.70001"
          id="line2526"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.39999"
          y1="482.39999"
          x2="186.60001"
          y2="482.60001"
          id="line2528"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.2"
          y1="482.29999"
          x2="186.39999"
          y2="482.39999"
          id="line2530"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186"
          y1="482.10001"
          x2="186.2"
          y2="482.29999"
          id="line2532"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.8"
          y1="481.89999"
          x2="186"
          y2="482.10001"
          id="line2534"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.60001"
          y1="481.60001"
          x2="185.8"
          y2="481.89999"
          id="line2536"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="366.10001"
          x2="184.39999"
          y2="366"
          id="line2538"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184"
          y1="366.10001"
          x2="184.2"
          y2="366.10001"
          id="line2540"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.8"
          y1="366.10001"
          x2="184"
          y2="366.10001"
          id="line2542"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.7"
          y1="366.20001"
          x2="183.8"
          y2="366.10001"
          id="line2544"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.5"
          y1="366.20001"
          x2="183.7"
          y2="366.20001"
          id="line2546"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.3"
          y1="366.20001"
          x2="183.5"
          y2="366.20001"
          id="line2548"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.2"
          y1="366.20001"
          x2="183.3"
          y2="366.20001"
          id="line2550"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.10001"
          y1="366.20001"
          x2="183.2"
          y2="366.20001"
          id="line2552"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.89999"
          y1="365.39999"
          x2="183.10001"
          y2="366.20001"
          id="line2554"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.10001"
          y1="364.60001"
          x2="178.89999"
          y2="365.39999"
          id="line2556"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173"
          y1="364.20001"
          x2="175.10001"
          y2="364.60001"
          id="line2558"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171"
          y1="363.89999"
          x2="173"
          y2="364.20001"
          id="line2560"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="169.2"
          y1="363.60001"
          x2="171"
          y2="363.89999"
          id="line2562"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="167.5"
          y1="363.39999"
          x2="169.2"
          y2="363.60001"
          id="line2564"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166"
          y1="363.10001"
          x2="167.5"
          y2="363.39999"
          id="line2566"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164.60001"
          y1="363"
          x2="166"
          y2="363.10001"
          id="line2568"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.3"
          y1="362.89999"
          x2="164.60001"
          y2="363"
          id="line2570"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.10001"
          y1="362.89999"
          x2="163.3"
          y2="362.89999"
          id="line2572"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.2"
          y1="363"
          x2="162.10001"
          y2="362.89999"
          id="line2574"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.3"
          y1="363.20001"
          x2="161.2"
          y2="363"
          id="line2576"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.5"
          y1="363.60001"
          x2="160.3"
          y2="363.20001"
          id="line2578"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.2"
          y1="475.10001"
          x2="182.60001"
          y2="473.39999"
          id="line2580"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.60001"
          y1="476"
          x2="183.2"
          y2="475.10001"
          id="line2582"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.8"
          y1="476.5"
          x2="183.60001"
          y2="476"
          id="line2584"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="477.29999"
          x2="183.8"
          y2="476.5"
          id="line2586"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.60001"
          y1="477.89999"
          x2="184.2"
          y2="477.29999"
          id="line2588"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.89999"
          y1="478.29999"
          x2="184.60001"
          y2="477.89999"
          id="line2590"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.2"
          y1="478.60001"
          x2="184.89999"
          y2="478.29999"
          id="line2592"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.60001"
          y1="478.89999"
          x2="185.2"
          y2="478.60001"
          id="line2594"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.89999"
          y1="479.10001"
          x2="185.60001"
          y2="478.89999"
          id="line2596"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.10001"
          y1="479.20001"
          x2="185.89999"
          y2="479.10001"
          id="line2598"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.39999"
          y1="479.29999"
          x2="186.10001"
          y2="479.20001"
          id="line2600"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.7"
          y1="479.29999"
          x2="186.39999"
          y2="479.29999"
          id="line2602"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.10001"
          y1="479.29999"
          x2="186.7"
          y2="479.29999"
          id="line2604"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.5"
          y1="479.10001"
          x2="187.10001"
          y2="479.29999"
          id="line2606"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188"
          y1="478.89999"
          x2="187.5"
          y2="479.10001"
          id="line2608"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.39999"
          y1="478.60001"
          x2="188"
          y2="478.89999"
          id="line2610"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.7"
          y1="478.20001"
          x2="188.39999"
          y2="478.60001"
          id="line2612"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.10001"
          y1="477.79999"
          x2="188.7"
          y2="478.20001"
          id="line2614"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.5"
          y1="477.39999"
          x2="189.10001"
          y2="477.79999"
          id="line2616"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.8"
          y1="476.89999"
          x2="189.5"
          y2="477.39999"
          id="line2618"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.10001"
          y1="476.5"
          x2="189.8"
          y2="476.89999"
          id="line2620"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.39999"
          y1="476"
          x2="190.10001"
          y2="476.5"
          id="line2622"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.89999"
          y1="475.20001"
          x2="190.39999"
          y2="476"
          id="line2624"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.2"
          y1="474.10001"
          x2="190.89999"
          y2="475.20001"
          id="line2626"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.5"
          y1="473.29999"
          x2="191.2"
          y2="474.10001"
          id="line2628"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.8"
          y1="471.60001"
          x2="191.5"
          y2="473.29999"
          id="line2630"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192"
          y1="469.60001"
          x2="191.8"
          y2="471.60001"
          id="line2632"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192"
          y1="467.39999"
          x2="192"
          y2="469.60001"
          id="line2634"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192"
          y1="467.10001"
          x2="192"
          y2="467.39999"
          id="line2636"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192"
          y1="465.60001"
          x2="192"
          y2="467.10001"
          id="line2638"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.89999"
          y1="464"
          x2="192"
          y2="465.60001"
          id="line2640"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.7"
          y1="462.29999"
          x2="191.89999"
          y2="464"
          id="line2642"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.5"
          y1="460.5"
          x2="191.7"
          y2="462.29999"
          id="line2644"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.2"
          y1="457.79999"
          x2="191.5"
          y2="460.5"
          id="line2646"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.8"
          y1="455.10001"
          x2="191.2"
          y2="457.79999"
          id="line2648"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.39999"
          y1="452.20001"
          x2="190.8"
          y2="455.10001"
          id="line2650"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.89999"
          y1="449.29999"
          x2="190.39999"
          y2="452.20001"
          id="line2652"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.39999"
          y1="446.5"
          x2="189.89999"
          y2="449.29999"
          id="line2654"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.60001"
          y1="442.60001"
          x2="189.39999"
          y2="446.5"
          id="line2656"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.89999"
          y1="438.60001"
          x2="188.60001"
          y2="442.60001"
          id="line2658"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.10001"
          y1="434.79999"
          x2="187.89999"
          y2="438.60001"
          id="line2660"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.3"
          y1="431.10001"
          x2="187.10001"
          y2="434.79999"
          id="line2662"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.60001"
          y1="427.29999"
          x2="186.3"
          y2="431.10001"
          id="line2664"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.7"
          y1="423.5"
          x2="185.60001"
          y2="427.29999"
          id="line2666"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="421.10001"
          x2="184.7"
          y2="423.5"
          id="line2668"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.39999"
          y1="417.20001"
          x2="184.2"
          y2="421.10001"
          id="line2670"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.5"
          y1="413.20001"
          x2="183.39999"
          y2="417.20001"
          id="line2672"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.60001"
          y1="409.20001"
          x2="182.5"
          y2="413.20001"
          id="line2674"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.7"
          y1="405.20001"
          x2="181.60001"
          y2="409.20001"
          id="line2676"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.7"
          y1="401.29999"
          x2="180.7"
          y2="405.20001"
          id="line2678"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.7"
          y1="397.39999"
          x2="179.7"
          y2="401.29999"
          id="line2680"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.8"
          y1="394"
          x2="178.7"
          y2="397.39999"
          id="line2682"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.89999"
          y1="390.70001"
          x2="177.8"
          y2="394"
          id="line2684"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.2"
          y1="388.60001"
          x2="176.89999"
          y2="390.70001"
          id="line2686"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.5"
          y1="386.5"
          x2="176.2"
          y2="388.60001"
          id="line2688"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.3"
          y1="385.89999"
          x2="175.5"
          y2="386.5"
          id="line2690"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.39999"
          y1="383.60001"
          x2="175.3"
          y2="385.89999"
          id="line2692"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.5"
          y1="381.60001"
          x2="174.39999"
          y2="383.60001"
          id="line2694"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.7"
          y1="379.70001"
          x2="173.5"
          y2="381.60001"
          id="line2696"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.7"
          y1="377.79999"
          x2="172.7"
          y2="379.70001"
          id="line2698"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="170.89999"
          y1="376.5"
          x2="171.7"
          y2="377.79999"
          id="line2700"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="170"
          y1="375.29999"
          x2="170.89999"
          y2="376.5"
          id="line2702"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="169.2"
          y1="374.20001"
          x2="170"
          y2="375.29999"
          id="line2704"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="168.3"
          y1="373.20001"
          x2="169.2"
          y2="374.20001"
          id="line2706"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="167.5"
          y1="372.39999"
          x2="168.3"
          y2="373.20001"
          id="line2708"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166.7"
          y1="371.70001"
          x2="167.5"
          y2="372.39999"
          id="line2710"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="165.8"
          y1="371.10001"
          x2="166.7"
          y2="371.70001"
          id="line2712"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="165"
          y1="370.60001"
          x2="165.8"
          y2="371.10001"
          id="line2714"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="164.3"
          y1="370.29999"
          x2="165"
          y2="370.60001"
          id="line2716"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.7"
          y1="370.10001"
          x2="164.3"
          y2="370.29999"
          id="line2718"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.2"
          y1="369.89999"
          x2="163.7"
          y2="370.10001"
          id="line2720"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.60001"
          y1="369.79999"
          x2="163.2"
          y2="369.89999"
          id="line2722"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162"
          y1="369.79999"
          x2="162.60001"
          y2="369.79999"
          id="line2724"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.60001"
          y1="369.79999"
          x2="162"
          y2="369.79999"
          id="line2726"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.2"
          y1="369.89999"
          x2="161.60001"
          y2="369.79999"
          id="line2728"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.8"
          y1="370"
          x2="161.2"
          y2="369.89999"
          id="line2730"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.39999"
          y1="370.10001"
          x2="160.8"
          y2="370"
          id="line2732"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.10001"
          y1="370.20001"
          x2="160.39999"
          y2="370.10001"
          id="line2734"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.8"
          y1="370.39999"
          x2="160.10001"
          y2="370.20001"
          id="line2736"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.60001"
          y1="370.60001"
          x2="159.8"
          y2="370.39999"
          id="line2738"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.3"
          y1="370.79999"
          x2="159.60001"
          y2="370.60001"
          id="line2740"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.10001"
          y1="371"
          x2="159.3"
          y2="370.79999"
          id="line2742"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159"
          y1="371.29999"
          x2="159.10001"
          y2="371"
          id="line2744"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.8"
          y1="371.70001"
          x2="159"
          y2="371.29999"
          id="line2746"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.7"
          y1="372.10001"
          x2="158.8"
          y2="371.70001"
          id="line2748"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.60001"
          y1="372.5"
          x2="158.7"
          y2="372.10001"
          id="line2750"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.60001"
          y1="373.10001"
          x2="158.60001"
          y2="372.5"
          id="line2752"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.60001"
          y1="373.60001"
          x2="158.60001"
          y2="373.10001"
          id="line2754"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.60001"
          y1="374.10001"
          x2="158.60001"
          y2="373.60001"
          id="line2756"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="158.6,374.1 158.7,374.6 158.9,376.6 159.1,377.5   "
          id="polyline2758"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.8"
          y1="375.29999"
          x2="179"
          y2="375.29999"
          id="line2760"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.7"
          y1="375"
          x2="178.8"
          y2="375.29999"
          id="line2762"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.7"
          y1="374.70001"
          x2="178.7"
          y2="375"
          id="line2764"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.60001"
          y1="374.5"
          x2="178.7"
          y2="374.70001"
          id="line2766"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.60001"
          y1="374.20001"
          x2="178.60001"
          y2="374.5"
          id="line2768"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.8"
          y1="374.10001"
          x2="178.60001"
          y2="374.20001"
          id="line2770"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="379"
          x2="198.5"
          y2="378.5"
          id="line2772"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.8"
          y1="379.5"
          x2="195.60001"
          y2="379"
          id="line2774"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.89999"
          y1="380"
          x2="192.8"
          y2="379.5"
          id="line2776"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.10001"
          y1="380.5"
          x2="189.89999"
          y2="380"
          id="line2778"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.39999"
          y1="376.89999"
          x2="187.10001"
          y2="380.5"
          id="line2780"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.7"
          y1="373.29999"
          x2="186.39999"
          y2="376.89999"
          id="line2782"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="369.60001"
          x2="185.7"
          y2="373.29999"
          id="line2784"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.39999"
          y1="366"
          x2="185.10001"
          y2="369.60001"
          id="line2786"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="365.5"
          x2="184.39999"
          y2="366"
          id="line2788"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.10001"
          y1="365"
          x2="187.3"
          y2="365.5"
          id="line2790"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193"
          y1="364.5"
          x2="190.10001"
          y2="365"
          id="line2792"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.8"
          y1="364"
          x2="193"
          y2="364.5"
          id="line2794"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.8"
          y1="378.20001"
          x2="199.89999"
          y2="378.20001"
          id="line2796"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="378.29999"
          x2="199.8"
          y2="378.20001"
          id="line2798"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.39999"
          y1="378.29999"
          x2="199.60001"
          y2="378.29999"
          id="line2800"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.10001"
          y1="378.39999"
          x2="199.39999"
          y2="378.29999"
          id="line2802"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.8"
          y1="378.39999"
          x2="199.10001"
          y2="378.39999"
          id="line2804"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.5"
          y1="378.5"
          x2="198.8"
          y2="378.39999"
          id="line2806"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.8"
          y1="374.79999"
          x2="198.5"
          y2="378.5"
          id="line2808"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.10001"
          y1="371.20001"
          x2="197.8"
          y2="374.79999"
          id="line2810"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.5"
          y1="367.60001"
          x2="197.10001"
          y2="371.20001"
          id="line2812"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.8"
          y1="364"
          x2="196.5"
          y2="367.60001"
          id="line2814"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.10001"
          y1="363.89999"
          x2="195.8"
          y2="364"
          id="line2816"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.39999"
          y1="363.89999"
          x2="196.10001"
          y2="363.89999"
          id="line2818"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.7"
          y1="363.79999"
          x2="196.39999"
          y2="363.89999"
          id="line2820"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197"
          y1="363.79999"
          x2="196.7"
          y2="363.79999"
          id="line2822"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.10001"
          y1="363.70001"
          x2="197"
          y2="363.79999"
          id="line2824"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.2"
          y1="363.70001"
          x2="197.10001"
          y2="363.70001"
          id="line2826"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="379"
          x2="199.7"
          y2="379.10001"
          id="line2828"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="379"
          x2="199.7"
          y2="379"
          id="line2830"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.5"
          y1="375.60001"
          x2="199.39999"
          y2="375.60001"
          id="line2832"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="375.60001"
          x2="199.5"
          y2="375.60001"
          id="line2834"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.8"
          y1="375.60001"
          x2="199.60001"
          y2="375.60001"
          id="line2836"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="375.60001"
          x2="199.8"
          y2="375.60001"
          id="line2838"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.10001"
          y1="375.60001"
          x2="199.89999"
          y2="375.60001"
          id="line2840"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.2"
          y1="375.60001"
          x2="200.10001"
          y2="375.60001"
          id="line2842"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.3"
          y1="375.60001"
          x2="200.2"
          y2="375.60001"
          id="line2844"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.5"
          y1="375.60001"
          x2="200.3"
          y2="375.60001"
          id="line2846"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.60001"
          y1="375.60001"
          x2="200.5"
          y2="375.60001"
          id="line2848"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.8"
          y1="375.60001"
          x2="200.60001"
          y2="375.60001"
          id="line2850"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.89999"
          y1="375.60001"
          x2="200.8"
          y2="375.60001"
          id="line2852"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201"
          y1="375.60001"
          x2="200.89999"
          y2="375.60001"
          id="line2854"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.2"
          y1="375.60001"
          x2="201"
          y2="375.60001"
          id="line2856"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.3"
          y1="375.60001"
          x2="201.2"
          y2="375.60001"
          id="line2858"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.5"
          y1="375.60001"
          x2="201.3"
          y2="375.60001"
          id="line2860"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.60001"
          y1="375.60001"
          x2="201.5"
          y2="375.60001"
          id="line2862"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.7"
          y1="375.60001"
          x2="201.60001"
          y2="375.60001"
          id="line2864"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.89999"
          y1="375.60001"
          x2="201.7"
          y2="375.60001"
          id="line2866"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202"
          y1="375.60001"
          x2="201.89999"
          y2="375.60001"
          id="line2868"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.2"
          y1="375.60001"
          x2="202"
          y2="375.60001"
          id="line2870"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.3"
          y1="375.60001"
          x2="202.2"
          y2="375.60001"
          id="line2872"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.39999"
          y1="375.60001"
          x2="202.3"
          y2="375.60001"
          id="line2874"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.60001"
          y1="375.60001"
          x2="202.39999"
          y2="375.60001"
          id="line2876"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.7"
          y1="375.60001"
          x2="202.60001"
          y2="375.60001"
          id="line2878"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.89999"
          y1="375.60001"
          x2="202.7"
          y2="375.60001"
          id="line2880"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203"
          y1="375.60001"
          x2="202.89999"
          y2="375.60001"
          id="line2882"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="375.60001"
          x2="203"
          y2="375.60001"
          id="line2884"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="375.60001"
          x2="203.10001"
          y2="375.60001"
          id="line2886"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.39999"
          y1="375.60001"
          x2="203.3"
          y2="375.60001"
          id="line2888"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.60001"
          y1="375.60001"
          x2="203.39999"
          y2="375.60001"
          id="line2890"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.7"
          y1="375.60001"
          x2="203.60001"
          y2="375.60001"
          id="line2892"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="375.60001"
          x2="203.7"
          y2="375.60001"
          id="line2894"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204"
          y1="375.60001"
          x2="203.8"
          y2="375.60001"
          id="line2896"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.10001"
          y1="375.60001"
          x2="204"
          y2="375.60001"
          id="line2898"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.3"
          y1="375.60001"
          x2="204.10001"
          y2="375.60001"
          id="line2900"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.39999"
          y1="375.60001"
          x2="204.3"
          y2="375.60001"
          id="line2902"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.5"
          y1="375.60001"
          x2="204.39999"
          y2="375.60001"
          id="line2904"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.7"
          y1="375.60001"
          x2="204.5"
          y2="375.60001"
          id="line2906"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.8"
          y1="375.60001"
          x2="204.7"
          y2="375.60001"
          id="line2908"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205"
          y1="375.60001"
          x2="204.8"
          y2="375.60001"
          id="line2910"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.10001"
          y1="375.60001"
          x2="205"
          y2="375.60001"
          id="line2912"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.2"
          y1="375.60001"
          x2="205.10001"
          y2="375.60001"
          id="line2914"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.39999"
          y1="375.60001"
          x2="205.2"
          y2="375.60001"
          id="line2916"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.5"
          y1="375.60001"
          x2="205.39999"
          y2="375.60001"
          id="line2918"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.7"
          y1="375.60001"
          x2="205.5"
          y2="375.60001"
          id="line2920"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.8"
          y1="375.60001"
          x2="205.7"
          y2="375.60001"
          id="line2922"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="205.89999"
          y1="375.60001"
          x2="205.8"
          y2="375.60001"
          id="line2924"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.10001"
          y1="375.60001"
          x2="205.89999"
          y2="375.60001"
          id="line2926"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.2"
          y1="375.60001"
          x2="206.10001"
          y2="375.60001"
          id="line2928"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.39999"
          y1="375.60001"
          x2="206.2"
          y2="375.60001"
          id="line2930"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.5"
          y1="375.60001"
          x2="206.39999"
          y2="375.60001"
          id="line2932"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.60001"
          y1="375.60001"
          x2="206.5"
          y2="375.60001"
          id="line2934"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.8"
          y1="375.60001"
          x2="206.60001"
          y2="375.60001"
          id="line2936"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="206.89999"
          y1="375.60001"
          x2="206.8"
          y2="375.60001"
          id="line2938"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="375.60001"
          x2="206.89999"
          y2="375.60001"
          id="line2940"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.2"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line2942"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.2"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line2944"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179"
          y1="375.29999"
          x2="179"
          y2="375.39999"
          id="line2946"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.89999"
          y1="375"
          x2="179"
          y2="375.29999"
          id="line2948"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.8"
          y1="374.70001"
          x2="178.89999"
          y2="375"
          id="line2950"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.8"
          y1="374.39999"
          x2="178.8"
          y2="374.70001"
          id="line2952"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.7"
          y1="374.10001"
          x2="178.8"
          y2="374.39999"
          id="line2954"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.8"
          y1="374"
          x2="178.7"
          y2="374.10001"
          id="line2956"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.60001"
          y1="380.10001"
          x2="180.7"
          y2="380.10001"
          id="line2958"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.39999"
          y1="380"
          x2="180.60001"
          y2="380.10001"
          id="line2960"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.3"
          y1="379.79999"
          x2="180.39999"
          y2="380"
          id="line2962"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.2"
          y1="379.60001"
          x2="180.3"
          y2="379.79999"
          id="line2964"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.89999"
          y1="376.5"
          x2="180.2"
          y2="379.60001"
          id="line2966"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179"
          y1="375.89999"
          x2="178.89999"
          y2="376.5"
          id="line2968"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179"
          y1="375.29999"
          x2="179"
          y2="375.89999"
          id="line2970"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="374.60001"
          x2="179"
          y2="375.29999"
          id="line2972"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.39999"
          y1="374.60001"
          x2="179.10001"
          y2="374.60001"
          id="line2974"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.39999"
          y2="374.60001"
          id="line2976"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.5"
          y1="369"
          x2="178.60001"
          y2="369"
          id="line2978"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.39999"
          y1="369.20001"
          x2="178.5"
          y2="369"
          id="line2980"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.3"
          y1="369.39999"
          x2="178.39999"
          y2="369.20001"
          id="line2982"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.3"
          y1="369.60001"
          x2="178.3"
          y2="369.39999"
          id="line2984"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.3"
          y1="370.39999"
          x2="178.3"
          y2="369.60001"
          id="line2986"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.3"
          y1="371.29999"
          x2="178.3"
          y2="370.39999"
          id="line2988"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.3"
          y1="372.10001"
          x2="178.3"
          y2="371.29999"
          id="line2990"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.3"
          y1="373"
          x2="178.3"
          y2="372.10001"
          id="line2992"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.5"
          y1="373.5"
          x2="178.3"
          y2="373"
          id="line2994"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.8"
          y1="374.10001"
          x2="178.5"
          y2="373.5"
          id="line2996"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="374.60001"
          x2="178.8"
          y2="374.10001"
          id="line2998"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.10001"
          y2="374.60001"
          id="line3000"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.39999"
          y1="368.5"
          x2="180.60001"
          y2="368.5"
          id="line3002"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.60001"
          y1="369"
          x2="180.39999"
          y2="368.5"
          id="line3004"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.89999"
          y1="377.70001"
          x2="181.8"
          y2="377.79999"
          id="line3006"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182"
          y1="377.60001"
          x2="181.89999"
          y2="377.70001"
          id="line3008"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182"
          y1="377.39999"
          x2="182"
          y2="377.60001"
          id="line3010"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182"
          y1="377.20001"
          x2="182"
          y2="377.39999"
          id="line3012"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182"
          y1="376.29999"
          x2="182"
          y2="377.20001"
          id="line3014"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.10001"
          y1="375.39999"
          x2="182"
          y2="376.29999"
          id="line3016"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.10001"
          y1="374.5"
          x2="182.10001"
          y2="375.39999"
          id="line3018"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.10001"
          y1="374.29999"
          x2="182.10001"
          y2="374.5"
          id="line3020"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182"
          y1="374"
          x2="182.10001"
          y2="374.29999"
          id="line3022"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.89999"
          y1="373.39999"
          x2="182"
          y2="374"
          id="line3024"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.5"
          y1="372.60001"
          x2="181.89999"
          y2="373.39999"
          id="line3026"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.2"
          y1="371.79999"
          x2="181.5"
          y2="372.60001"
          id="line3028"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.89999"
          y1="371"
          x2="181.2"
          y2="371.79999"
          id="line3030"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.8"
          y1="370.79999"
          x2="180.89999"
          y2="371"
          id="line3032"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.60001"
          y1="370.70001"
          x2="180.8"
          y2="370.79999"
          id="line3034"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.5"
          y1="370.60001"
          x2="180.60001"
          y2="370.70001"
          id="line3036"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.39999"
          y1="377.89999"
          x2="181.8"
          y2="377.79999"
          id="line3038"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.10001"
          y1="378"
          x2="181.39999"
          y2="377.89999"
          id="line3040"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.7"
          y1="378.10001"
          x2="181.10001"
          y2="378"
          id="line3042"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.60001"
          y2="374.5"
          id="line3044"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.60001"
          y2="374.5"
          id="line3046"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.60001"
          y2="374.5"
          id="line3048"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374"
          x2="179.60001"
          y2="374.5"
          id="line3050"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.7"
          y1="374.79999"
          x2="179.8"
          y2="375.10001"
          id="line3052"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.7"
          y2="374.79999"
          id="line3054"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.60001"
          y2="374.5"
          id="line3056"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.60001"
          y2="374.5"
          id="line3058"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.60001"
          y2="374.5"
          id="line3060"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.8"
          y1="371.10001"
          x2="182.7"
          y2="373.20001"
          id="line3062"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.89999"
          y1="369"
          x2="181.8"
          y2="371.10001"
          id="line3064"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.60001"
          y1="368.5"
          x2="180.39999"
          y2="368.5"
          id="line3066"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.7"
          y1="368.60001"
          x2="180.60001"
          y2="368.5"
          id="line3068"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.8"
          y1="368.79999"
          x2="180.7"
          y2="368.60001"
          id="line3070"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.89999"
          y1="369"
          x2="180.8"
          y2="368.79999"
          id="line3072"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181"
          y1="369"
          x2="180.89999"
          y2="369"
          id="line3074"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="373.79999"
          x2="182.89999"
          y2="374.39999"
          id="line3076"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.7"
          y1="373.20001"
          x2="182.8"
          y2="373.79999"
          id="line3078"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.7"
          y1="373.20001"
          x2="182.7"
          y2="373.20001"
          id="line3080"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="376.70001"
          x2="182.8"
          y2="379"
          id="line3082"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.89999"
          y1="374.39999"
          x2="182.8"
          y2="376.70001"
          id="line3084"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.89999"
          y1="374.39999"
          x2="182.89999"
          y2="374.39999"
          id="line3086"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.7"
          y1="379.39999"
          x2="182.5"
          y2="379.60001"
          id="line3088"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="379.20001"
          x2="182.7"
          y2="379.39999"
          id="line3090"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.8"
          y1="379"
          x2="182.8"
          y2="379.20001"
          id="line3092"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="182.8,379 182.9,379 182.8,379.4   "
          id="polyline3094"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="371.39999"
          x2="179.60001"
          y2="371.29999"
          id="line3096"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="372.29999"
          x2="179.10001"
          y2="371.39999"
          id="line3098"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="373.20001"
          x2="179.10001"
          y2="372.29999"
          id="line3100"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179"
          y1="374.10001"
          x2="179.10001"
          y2="373.20001"
          id="line3102"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="179.8,370.8 179.9,370.7 180.4,370.6 180.5,370.6   "
          id="polyline3104"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.7"
          y1="370.89999"
          x2="179.8"
          y2="370.79999"
          id="line3106"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="371.29999"
          x2="179.7"
          y2="370.89999"
          id="line3108"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="372.70001"
          x2="179.60001"
          y2="371.29999"
          id="line3110"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374"
          x2="179.60001"
          y2="372.70001"
          id="line3112"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179"
          y1="374.10001"
          x2="179.60001"
          y2="374"
          id="line3114"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="374.60001"
          x2="179"
          y2="374.10001"
          id="line3116"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.89999"
          y1="376.79999"
          x2="180.3"
          y2="377.60001"
          id="line3118"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="376"
          x2="179.89999"
          y2="376.79999"
          id="line3120"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.3"
          y1="375.20001"
          x2="179.60001"
          y2="376"
          id="line3122"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.3"
          y1="376.29999"
          x2="180.8"
          y2="377.5"
          id="line3124"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.8"
          y1="375.10001"
          x2="180.3"
          y2="376.29999"
          id="line3126"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.5"
          y1="375.10001"
          x2="179.8"
          y2="375.10001"
          id="line3128"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.3"
          y1="375.20001"
          x2="179.5"
          y2="375.10001"
          id="line3130"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="374.60001"
          x2="179.3"
          y2="375.20001"
          id="line3132"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.10001"
          y1="377.89999"
          x2="181.2"
          y2="377.89999"
          id="line3134"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181"
          y1="377.89999"
          x2="181.10001"
          y2="377.89999"
          id="line3136"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.89999"
          y1="377.70001"
          x2="181"
          y2="377.89999"
          id="line3138"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.8"
          y1="377.5"
          x2="180.89999"
          y2="377.70001"
          id="line3140"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.3"
          y1="377.60001"
          x2="180.8"
          y2="377.5"
          id="line3142"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.39999"
          y1="377.79999"
          x2="180.3"
          y2="377.60001"
          id="line3144"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.5"
          y1="377.89999"
          x2="180.39999"
          y2="377.79999"
          id="line3146"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.60001"
          y1="378"
          x2="180.5"
          y2="377.89999"
          id="line3148"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.7"
          y1="378.10001"
          x2="180.60001"
          y2="378"
          id="line3150"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.2"
          y1="362.60001"
          x2="207.2"
          y2="362.60001"
          id="line3152"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.60001"
          x2="207.2"
          y2="362.60001"
          id="line3154"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.60001"
          x2="207.10001"
          y2="362.60001"
          id="line3156"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.60001"
          id="line3158"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.70001"
          id="line3160"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.70001"
          id="line3162"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.70001"
          id="line3164"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.70001"
          x2="207.10001"
          y2="362.70001"
          id="line3166"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.70001"
          id="line3168"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.79999"
          id="line3170"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.79999"
          id="line3172"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.79999"
          id="line3174"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.79999"
          x2="207.10001"
          y2="362.79999"
          id="line3176"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.10001"
          y1="362.89999"
          x2="207.10001"
          y2="362.79999"
          id="line3178"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="362.89999"
          x2="207.10001"
          y2="362.89999"
          id="line3180"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="362.89999"
          x2="207"
          y2="362.89999"
          id="line3182"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="362.89999"
          x2="207"
          y2="362.89999"
          id="line3184"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363"
          x2="207"
          y2="362.89999"
          id="line3186"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363.10001"
          x2="207"
          y2="363"
          id="line3188"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363.29999"
          x2="207"
          y2="363.10001"
          id="line3190"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363.60001"
          x2="207"
          y2="363.29999"
          id="line3192"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="363.79999"
          x2="207"
          y2="363.60001"
          id="line3194"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="364.10001"
          x2="207"
          y2="363.79999"
          id="line3196"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="364.29999"
          x2="207"
          y2="364.10001"
          id="line3198"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="365.20001"
          x2="207"
          y2="364.29999"
          id="line3200"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="366.10001"
          x2="207"
          y2="365.20001"
          id="line3202"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="367"
          x2="207"
          y2="366.10001"
          id="line3204"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="367.89999"
          x2="207"
          y2="367"
          id="line3206"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="368.79999"
          x2="207"
          y2="367.89999"
          id="line3208"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="369.60001"
          x2="207"
          y2="368.79999"
          id="line3210"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="370.5"
          x2="207"
          y2="369.60001"
          id="line3212"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="371.39999"
          x2="207"
          y2="370.5"
          id="line3214"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="372.29999"
          x2="207"
          y2="371.39999"
          id="line3216"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="373.20001"
          x2="207"
          y2="372.29999"
          id="line3218"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="374.10001"
          x2="207"
          y2="373.20001"
          id="line3220"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="374.89999"
          x2="207"
          y2="374.10001"
          id="line3222"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="375.79999"
          x2="207"
          y2="374.89999"
          id="line3224"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="376.70001"
          x2="207"
          y2="375.79999"
          id="line3226"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="377.60001"
          x2="207"
          y2="376.70001"
          id="line3228"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="378.5"
          x2="207"
          y2="377.60001"
          id="line3230"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="378.70001"
          x2="207"
          y2="378.5"
          id="line3232"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379"
          x2="207"
          y2="378.70001"
          id="line3234"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379.5"
          x2="207"
          y2="379"
          id="line3236"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379.79999"
          x2="207"
          y2="379.5"
          id="line3238"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379.79999"
          x2="207"
          y2="379.79999"
          id="line3240"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="384.70001"
          y1="366.10001"
          x2="386.70001"
          y2="366.20001"
          id="line3242"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="382.79999"
          y1="366"
          x2="384.70001"
          y2="366.10001"
          id="line3244"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="380.89999"
          y1="366"
          x2="382.79999"
          y2="366"
          id="line3246"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="378.89999"
          y1="365.89999"
          x2="380.89999"
          y2="366"
          id="line3248"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="377"
          y1="365.79999"
          x2="378.89999"
          y2="365.89999"
          id="line3250"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="375.10001"
          y1="365.70001"
          x2="377"
          y2="365.79999"
          id="line3252"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="373.10001"
          y1="365.60001"
          x2="375.10001"
          y2="365.70001"
          id="line3254"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="371.20001"
          y1="365.60001"
          x2="373.10001"
          y2="365.60001"
          id="line3256"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="369.20001"
          y1="365.5"
          x2="371.20001"
          y2="365.60001"
          id="line3258"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="367.29999"
          y1="365.39999"
          x2="369.20001"
          y2="365.5"
          id="line3260"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="365.39999"
          y1="365.29999"
          x2="367.29999"
          y2="365.39999"
          id="line3262"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="363.39999"
          y1="365.20001"
          x2="365.39999"
          y2="365.29999"
          id="line3264"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="361.5"
          y1="365.10001"
          x2="363.39999"
          y2="365.20001"
          id="line3266"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="359.5"
          y1="365"
          x2="361.5"
          y2="365.10001"
          id="line3268"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="357.60001"
          y1="365"
          x2="359.5"
          y2="365"
          id="line3270"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="355.70001"
          y1="364.89999"
          x2="357.60001"
          y2="365"
          id="line3272"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="353.70001"
          y1="364.79999"
          x2="355.70001"
          y2="364.89999"
          id="line3274"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="351.79999"
          y1="364.70001"
          x2="353.70001"
          y2="364.79999"
          id="line3276"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="349.79999"
          y1="364.60001"
          x2="351.79999"
          y2="364.70001"
          id="line3278"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="347.89999"
          y1="364.5"
          x2="349.79999"
          y2="364.60001"
          id="line3280"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="346"
          y1="364.39999"
          x2="347.89999"
          y2="364.5"
          id="line3282"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="344"
          y1="364.29999"
          x2="346"
          y2="364.39999"
          id="line3284"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="342.10001"
          y1="364.29999"
          x2="344"
          y2="364.29999"
          id="line3286"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="340.10001"
          y1="364.20001"
          x2="342.10001"
          y2="364.29999"
          id="line3288"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="338.20001"
          y1="364.10001"
          x2="340.10001"
          y2="364.20001"
          id="line3290"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="336.29999"
          y1="364"
          x2="338.20001"
          y2="364.10001"
          id="line3292"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="334.29999"
          y1="363.89999"
          x2="336.29999"
          y2="364"
          id="line3294"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="331.29999"
          y1="363.79999"
          x2="334.29999"
          y2="363.89999"
          id="line3296"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="328.39999"
          y1="363.70001"
          x2="331.29999"
          y2="363.79999"
          id="line3298"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="325.39999"
          y1="363.60001"
          x2="328.39999"
          y2="363.70001"
          id="line3300"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="322.39999"
          y1="363.39999"
          x2="325.39999"
          y2="363.60001"
          id="line3302"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="319.5"
          y1="363.29999"
          x2="322.39999"
          y2="363.39999"
          id="line3304"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="316.5"
          y1="363.20001"
          x2="319.5"
          y2="363.29999"
          id="line3306"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="315"
          y1="363.20001"
          x2="316.5"
          y2="363.20001"
          id="line3308"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="313.5"
          y1="363.10001"
          x2="315"
          y2="363.20001"
          id="line3310"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="312"
          y1="363.10001"
          x2="313.5"
          y2="363.10001"
          id="line3312"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.7"
          y1="359"
          x2="172.10001"
          y2="359"
          id="line3314"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.10001"
          y1="359.20001"
          x2="171.7"
          y2="359"
          id="line3316"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.10001"
          y1="482.29999"
          x2="186.39999"
          y2="482.70001"
          id="line3318"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187"
          y1="483.20001"
          x2="187.5"
          y2="483.5"
          id="line3320"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.3"
          y1="362.70001"
          x2="196.60001"
          y2="362.79999"
          id="line3322"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.8"
          y1="479.79999"
          x2="201"
          y2="480.5"
          id="line3324"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.2"
          y1="476"
          x2="204.5"
          y2="475"
          id="line3326"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="480.60001"
          x2="185.39999"
          y2="481.29999"
          id="line3328"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.2"
          y1="483.60001"
          x2="190.2"
          y2="483.60001"
          id="line3330"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.60001"
          y1="359"
          x2="173.60001"
          y2="359"
          id="line3332"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.7"
          y1="368.79999"
          x2="157.60001"
          y2="367.5"
          id="line3334"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.39999"
          y1="395.5"
          x2="163.39999"
          y2="395.5"
          id="line3336"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.89999"
          y1="483.10001"
          x2="186.89999"
          y2="483.10001"
          id="line3338"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.3"
          y1="361"
          x2="190.7"
          y2="361.20001"
          id="line3340"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196"
          y1="362.60001"
          x2="196.3"
          y2="362.70001"
          id="line3342"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.7"
          y1="386"
          x2="195.60001"
          y2="386.60001"
          id="line3344"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.3"
          y1="386.70001"
          x2="160.8"
          y2="384.70001"
          id="line3346"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.8"
          y1="483.10001"
          x2="186.8"
          y2="483.10001"
          id="line3348"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.10001"
          y1="361.60001"
          x2="192"
          y2="361.5"
          id="line3350"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.60001"
          y1="374.20001"
          x2="178.60001"
          y2="373.70001"
          id="line3352"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.8"
          y1="375.29999"
          x2="179"
          y2="375.70001"
          id="line3354"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line3356"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.2"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line3358"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="433.79999"
          y1="366.89999"
          x2="433.79999"
          y2="366.89999"
          id="line3360"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="392.5"
          y1="366.39999"
          x2="385.10001"
          y2="366.10001"
          id="line3362"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.8"
          y1="476.89999"
          x2="204.2"
          y2="476"
          id="line3364"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.7"
          y1="361.20001"
          x2="192"
          y2="361.5"
          id="line3366"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.2"
          y1="366.89999"
          x2="203.2"
          y2="366.89999"
          id="line3368"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.5"
          y1="466.70001"
          x2="180"
          y2="465.10001"
          id="line3370"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.39999"
          y1="395.5"
          x2="163.3"
          y2="395.5"
          id="line3372"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="379.29999"
          x2="199.39999"
          y2="379.5"
          id="line3374"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.3"
          y1="366.89999"
          x2="203.3"
          y2="366.89999"
          id="line3376"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.7"
          y1="380.10001"
          x2="180.89999"
          y2="380"
          id="line3378"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.7"
          y1="371.79999"
          x2="162"
          y2="371.60001"
          id="line3380"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162"
          y1="371.60001"
          x2="162.2"
          y2="371.39999"
          id="line3382"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.2"
          y1="371.39999"
          x2="162.5"
          y2="371.20001"
          id="line3384"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.5"
          y1="371.20001"
          x2="162.89999"
          y2="371"
          id="line3386"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.89999"
          y1="371"
          x2="163.10001"
          y2="371"
          id="line3388"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="178.6,451.9 180.3,457.8 180.4,458.2 181,460.6   "
          id="polyline3390"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.60001"
          y1="451.89999"
          x2="175"
          y2="438.70001"
          id="line3392"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175"
          y1="438.70001"
          x2="171.60001"
          y2="425.39999"
          id="line3394"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.60001"
          y1="425.39999"
          x2="168.5"
          y2="411.89999"
          id="line3396"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="168.5"
          y1="411.89999"
          x2="165.60001"
          y2="398.29999"
          id="line3398"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="165.60001"
          y1="398.29999"
          x2="164.89999"
          y2="394.89999"
          id="line3400"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.2"
          y1="376.29999"
          x2="161.3"
          y2="377.10001"
          id="line3402"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.3"
          y1="377.10001"
          x2="161.5"
          y2="378"
          id="line3404"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.5"
          y1="378"
          x2="161.7"
          y2="379"
          id="line3406"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.7"
          y1="379"
          x2="161.89999"
          y2="380"
          id="line3408"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.89999"
          y1="380"
          x2="162.8"
          y2="384.70001"
          id="line3410"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.8"
          y1="384.70001"
          x2="164.89999"
          y2="394.89999"
          id="line3412"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.39999"
          y1="372.39999"
          x2="161.5"
          y2="372.10001"
          id="line3414"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.5"
          y1="372.10001"
          x2="161.7"
          y2="371.79999"
          id="line3416"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 187,476.7"
          id="path3418"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 186.7,476.4 0.4,0.4 c 0,0 0.9,1 1.8,0.5"
          id="path3420"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.7"
          y1="476.39999"
          x2="186.3"
          y2="475.89999"
          id="line3422"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.3"
          y1="475.89999"
          x2="185.89999"
          y2="475.29999"
          id="line3424"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.89999"
          y1="475.29999"
          x2="185.5"
          y2="474.39999"
          id="line3426"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.5"
          y1="474.39999"
          x2="185.10001"
          y2="473.39999"
          id="line3428"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.10001"
          y1="473.39999"
          x2="184.60001"
          y2="472.10001"
          id="line3430"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.60001"
          y1="472.10001"
          x2="184.10001"
          y2="470.60001"
          id="line3432"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.2"
          y1="376.29999"
          x2="161.10001"
          y2="375.60001"
          id="line3434"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.10001"
          y1="375.60001"
          x2="161.10001"
          y2="374.89999"
          id="line3436"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.10001"
          y1="374.89999"
          x2="161.10001"
          y2="374.29999"
          id="line3438"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.10001"
          y1="374.29999"
          x2="161.10001"
          y2="373.70001"
          id="line3440"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.10001"
          y1="373.70001"
          x2="161.10001"
          y2="373.20001"
          id="line3442"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.10001"
          y1="373.20001"
          x2="161.2"
          y2="372.79999"
          id="line3444"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.2"
          y1="372.79999"
          x2="161.39999"
          y2="372.39999"
          id="line3446"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181"
          y1="460.60001"
          x2="181.7"
          y2="462.79999"
          id="line3448"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.7"
          y1="462.79999"
          x2="182.3"
          y2="465"
          id="line3450"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.3"
          y1="465"
          x2="182.89999"
          y2="467"
          id="line3452"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.89999"
          y1="467"
          x2="183.5"
          y2="468.89999"
          id="line3454"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.5"
          y1="468.89999"
          x2="184.10001"
          y2="470.60001"
          id="line3456"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.60001"
          y1="383.70001"
          x2="160.2"
          y2="382.10001"
          id="line3458"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.8"
          y1="384.70001"
          x2="160.60001"
          y2="383.70001"
          id="line3460"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 171.4,359.1 -0.3,0.1 c -0.4,0.1 -8.1,2.1 -8.1,2.1"
          id="path3462"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.7"
          y1="359"
          x2="171.39999"
          y2="359.10001"
          id="line3464"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 156.9,379.3"
          id="path3466"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 162.6,371.1"
          id="path3468"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 188,483.7 0.8,0.2 c 0,0 0.6,0.1 1.2,-0.1 l 0.2,-0.1"
          id="path3470"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.5"
          y1="483.5"
          x2="188"
          y2="483.70001"
          id="line3472"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.89999"
          y1="380"
          x2="199.39999"
          y2="379.5"
          id="line3474"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.89999"
          y1="380"
          x2="198.89999"
          y2="380"
          id="line3476"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 165.1,371.3"
          id="path3478"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 164.3,371.1"
          id="path3480"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 164.3,371.1"
          id="path3482"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.39999"
          y1="479.10001"
          x2="203.2"
          y2="478.10001"
          id="line3484"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.2"
          y1="479.29999"
          x2="202.39999"
          y2="479.10001"
          id="line3486"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.10001"
          y1="479.39999"
          x2="202.2"
          y2="479.29999"
          id="line3488"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.10001"
          y1="479.5"
          x2="201.8"
          y2="479.79999"
          id="line3490"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.10001"
          y1="479.39999"
          x2="202.10001"
          y2="479.5"
          id="line3492"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="474.5"
          x2="204.5"
          y2="474.79999"
          id="line3494"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="204.60001"
          y1="474.5"
          x2="204.60001"
          y2="474.5"
          id="line3496"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.2"
          y1="395.5"
          x2="161.10001"
          y2="395.5"
          id="line3498"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.7"
          y1="395.5"
          x2="161.2"
          y2="395.5"
          id="line3500"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.7"
          y1="395.5"
          x2="161.7"
          y2="395.5"
          id="line3502"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.10001"
          y1="372.10001"
          x2="158.7"
          y2="374.39999"
          id="line3504"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.8"
          y1="370.29999"
          x2="158.10001"
          y2="372.10001"
          id="line3506"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.7"
          y1="368.79999"
          x2="157.8"
          y2="370.29999"
          id="line3508"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.8"
          y1="371.10001"
          x2="162.89999"
          y2="371"
          id="line3510"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.8"
          y1="371.10001"
          x2="162.8"
          y2="371.10001"
          id="line3512"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.89999"
          y1="363.60001"
          x2="184.2"
          y2="364.60001"
          id="line3514"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="362.60001"
          x2="189.89999"
          y2="363.60001"
          id="line3516"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.2"
          y1="360.39999"
          x2="184.89999"
          y2="360.29999"
          id="line3518"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.89999"
          y1="360.5"
          x2="185.2"
          y2="360.39999"
          id="line3520"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.39999"
          y1="360.70001"
          x2="185.89999"
          y2="360.5"
          id="line3522"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="188.39999"
          y1="360.89999"
          x2="187.39999"
          y2="360.70001"
          id="line3524"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="189.39999"
          y1="361.10001"
          x2="188.39999"
          y2="360.89999"
          id="line3526"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="190.39999"
          y1="361.29999"
          x2="189.39999"
          y2="361.10001"
          id="line3528"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="191.39999"
          y1="361.5"
          x2="190.39999"
          y2="361.29999"
          id="line3530"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="192.5"
          y1="361.79999"
          x2="191.39999"
          y2="361.5"
          id="line3532"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.5"
          y1="362"
          x2="192.5"
          y2="361.79999"
          id="line3534"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.5"
          y1="362.29999"
          x2="193.5"
          y2="362"
          id="line3536"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.60001"
          y1="362.60001"
          x2="194.5"
          y2="362.29999"
          id="line3538"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="165.39999"
          y1="361.39999"
          x2="164.2"
          y2="361.29999"
          id="line3540"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="166.8"
          y1="361.5"
          x2="165.39999"
          y2="361.39999"
          id="line3542"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="168.3"
          y1="361.70001"
          x2="166.8"
          y2="361.5"
          id="line3544"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="169.89999"
          y1="362"
          x2="168.3"
          y2="361.70001"
          id="line3546"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.60001"
          y1="362.29999"
          x2="169.89999"
          y2="362"
          id="line3548"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.39999"
          y1="362.60001"
          x2="171.60001"
          y2="362.29999"
          id="line3550"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.39999"
          y1="362.89999"
          x2="173.39999"
          y2="362.60001"
          id="line3552"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.39999"
          y1="363.29999"
          x2="175.39999"
          y2="362.89999"
          id="line3554"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="363.70001"
          x2="177.39999"
          y2="363.29999"
          id="line3556"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.8"
          y1="364.20001"
          x2="179.60001"
          y2="363.70001"
          id="line3558"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.2"
          y1="364.60001"
          x2="181.8"
          y2="364.20001"
          id="line3560"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.7"
          y1="359.29999"
          x2="177.39999"
          y2="359.39999"
          id="line3562"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.10001"
          y1="359.20001"
          x2="176.7"
          y2="359.29999"
          id="line3564"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.5"
          y1="359.10001"
          x2="176.10001"
          y2="359.20001"
          id="line3566"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.89999"
          y1="359.10001"
          x2="175.5"
          y2="359.10001"
          id="line3568"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.60001"
          y1="359"
          x2="174.89999"
          y2="359.10001"
          id="line3570"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.3"
          y1="359"
          x2="174.60001"
          y2="359"
          id="line3572"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.89999"
          y1="359"
          x2="174.3"
          y2="359"
          id="line3574"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.60001"
          y1="359"
          x2="173.89999"
          y2="359"
          id="line3576"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193"
          y1="380.79999"
          x2="198.7"
          y2="379.79999"
          id="line3578"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.3"
          y1="381.79999"
          x2="193"
          y2="380.79999"
          id="line3580"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.3"
          y1="380.20001"
          x2="198.7"
          y2="379.79999"
          id="line3582"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198"
          y1="380.5"
          x2="198.3"
          y2="380.20001"
          id="line3584"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.60001"
          y1="380.89999"
          x2="198"
          y2="380.5"
          id="line3586"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.3"
          y1="381.29999"
          x2="197.60001"
          y2="380.89999"
          id="line3588"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.89999"
          y1="381.79999"
          x2="197.3"
          y2="381.29999"
          id="line3590"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.5"
          y1="382.39999"
          x2="196.89999"
          y2="381.79999"
          id="line3592"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="196.10001"
          y1="383.10001"
          x2="196.5"
          y2="382.39999"
          id="line3594"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.7"
          y1="383.70001"
          x2="196.10001"
          y2="383.10001"
          id="line3596"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195.3"
          y1="384.5"
          x2="195.7"
          y2="383.70001"
          id="line3598"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="195"
          y1="385.29999"
          x2="195.3"
          y2="384.5"
          id="line3600"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.7"
          y1="386.20001"
          x2="195"
          y2="385.29999"
          id="line3602"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.39999"
          y1="387.20001"
          x2="194.7"
          y2="386.20001"
          id="line3604"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.2"
          y1="388.29999"
          x2="194.39999"
          y2="387.20001"
          id="line3606"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194"
          y1="389.5"
          x2="194.2"
          y2="388.29999"
          id="line3608"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.89999"
          y1="390.79999"
          x2="194"
          y2="389.5"
          id="line3610"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.8"
          y1="392.10001"
          x2="193.89999"
          y2="390.79999"
          id="line3612"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.8"
          y1="393.39999"
          x2="193.8"
          y2="392.10001"
          id="line3614"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.89999"
          y1="394.79999"
          x2="193.8"
          y2="393.39999"
          id="line3616"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="193.89999"
          y1="396.20001"
          x2="193.89999"
          y2="394.79999"
          id="line3618"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194"
          y1="397.60001"
          x2="193.89999"
          y2="396.20001"
          id="line3620"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.10001"
          y1="399"
          x2="194"
          y2="397.60001"
          id="line3622"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="194.10001"
          y1="399.70001"
          x2="194.10001"
          y2="399"
          id="line3624"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="156.60001"
          y1="380.79999"
          x2="156.39999"
          y2="380.79999"
          id="line3626"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="157.3"
          y1="380.70001"
          x2="156.60001"
          y2="380.79999"
          id="line3628"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.3"
          y1="380.60001"
          x2="157.3"
          y2="380.70001"
          id="line3630"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="159.8"
          y1="380.60001"
          x2="158.3"
          y2="380.60001"
          id="line3632"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160"
          y1="394.5"
          x2="159.89999"
          y2="394.5"
          id="line3634"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.10001"
          y1="394.5"
          x2="160"
          y2="394.5"
          id="line3636"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.3"
          y1="394.5"
          x2="160.10001"
          y2="394.5"
          id="line3638"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.60001"
          y1="394.5"
          x2="160.3"
          y2="394.5"
          id="line3640"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="160.89999"
          y1="394.5"
          x2="160.60001"
          y2="394.5"
          id="line3642"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.2"
          y1="394.5"
          x2="160.89999"
          y2="394.5"
          id="line3644"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="161.60001"
          y1="394.5"
          x2="161.2"
          y2="394.5"
          id="line3646"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162"
          y1="394.5"
          x2="161.60001"
          y2="394.5"
          id="line3648"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.39999"
          y1="394.5"
          x2="162"
          y2="394.5"
          id="line3650"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="162.7"
          y1="394.60001"
          x2="162.39999"
          y2="394.5"
          id="line3652"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.10001"
          y1="394.70001"
          x2="162.7"
          y2="394.60001"
          id="line3654"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="163.2"
          y1="394.79999"
          x2="163.10001"
          y2="394.70001"
          id="line3656"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158"
          y1="365.5"
          x2="157.7"
          y2="366.39999"
          id="line3658"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.39999"
          y1="364.70001"
          x2="158"
          y2="365.5"
          id="line3660"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="158.89999"
          y1="364.10001"
          x2="158.39999"
          y2="364.70001"
          id="line3662"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="169.8"
          y1="417.60001"
          x2="169.3"
          y2="415.5"
          id="line3664"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="170.5"
          y1="420.89999"
          x2="169.8"
          y2="417.60001"
          id="line3666"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="170.89999"
          y1="422.70001"
          x2="170.5"
          y2="420.89999"
          id="line3668"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.3"
          y1="424.39999"
          x2="170.89999"
          y2="422.70001"
          id="line3670"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="171.7"
          y1="426.20001"
          x2="171.3"
          y2="424.39999"
          id="line3672"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.2"
          y1="428"
          x2="171.7"
          y2="426.20001"
          id="line3674"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="172.7"
          y1="430.20001"
          x2="172.2"
          y2="428"
          id="line3676"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.2"
          y1="432.39999"
          x2="172.7"
          y2="430.20001"
          id="line3678"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="173.7"
          y1="434.5"
          x2="173.2"
          y2="432.39999"
          id="line3680"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.2"
          y1="436.60001"
          x2="173.7"
          y2="434.5"
          id="line3682"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="174.8"
          y1="439.29999"
          x2="174.2"
          y2="436.60001"
          id="line3684"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.3"
          y1="441.20001"
          x2="174.8"
          y2="439.29999"
          id="line3686"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="175.7"
          y1="443"
          x2="175.3"
          y2="441.20001"
          id="line3688"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.2"
          y1="444.70001"
          x2="175.7"
          y2="443"
          id="line3690"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="176.60001"
          y1="446.39999"
          x2="176.2"
          y2="444.70001"
          id="line3692"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177"
          y1="448.10001"
          x2="176.60001"
          y2="446.39999"
          id="line3694"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.39999"
          y1="449.60001"
          x2="177"
          y2="448.10001"
          id="line3696"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="177.8"
          y1="451.20001"
          x2="177.39999"
          y2="449.60001"
          id="line3698"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="178.5"
          y1="454.10001"
          x2="177.8"
          y2="451.20001"
          id="line3700"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.10001"
          y1="456.5"
          x2="178.5"
          y2="454.10001"
          id="line3702"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.8"
          y1="458.89999"
          x2="179.10001"
          y2="456.5"
          id="line3704"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.2"
          y1="460.60001"
          x2="179.8"
          y2="458.89999"
          id="line3706"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="180.7"
          y1="462.20001"
          x2="180.2"
          y2="460.60001"
          id="line3708"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.10001"
          y1="463.79999"
          x2="180.7"
          y2="462.20001"
          id="line3710"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="181.60001"
          y1="465.29999"
          x2="181.10001"
          y2="463.79999"
          id="line3712"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.3"
          y1="467.60001"
          x2="181.60001"
          y2="465.29999"
          id="line3714"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="182.60001"
          y1="468.70001"
          x2="182.3"
          y2="467.60001"
          id="line3716"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183"
          y1="469.79999"
          x2="182.60001"
          y2="468.70001"
          id="line3718"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.3"
          y1="470.79999"
          x2="183"
          y2="469.79999"
          id="line3720"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="183.60001"
          y1="471.79999"
          x2="183.3"
          y2="470.79999"
          id="line3722"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184"
          y1="472.70001"
          x2="183.60001"
          y2="471.79999"
          id="line3724"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.3"
          y1="473.39999"
          x2="184"
          y2="472.70001"
          id="line3726"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.60001"
          y1="474.29999"
          x2="184.3"
          y2="473.39999"
          id="line3728"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="184.8"
          y1="474.79999"
          x2="184.60001"
          y2="474.29999"
          id="line3730"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.2"
          y1="475.5"
          x2="184.8"
          y2="474.79999"
          id="line3732"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.60001"
          y1="476.10001"
          x2="185.2"
          y2="475.5"
          id="line3734"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="185.89999"
          y1="476.5"
          x2="185.60001"
          y2="476.10001"
          id="line3736"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.2"
          y1="476.79999"
          x2="185.89999"
          y2="476.5"
          id="line3738"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.60001"
          y1="477.10001"
          x2="186.2"
          y2="476.79999"
          id="line3740"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="186.89999"
          y1="477.29999"
          x2="186.60001"
          y2="477.10001"
          id="line3742"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.10001"
          y1="477.39999"
          x2="186.89999"
          y2="477.29999"
          id="line3744"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="187.39999"
          y1="477.5"
          x2="187.10001"
          y2="477.39999"
          id="line3746"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 160.7,372.5 0.2,-0.3 c 0,0 0.3,-0.6 1.5,-0.9"
          id="path3748"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 162.5,371.2"
          id="path3750"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.60001"
          y2="374.5"
          id="line3752"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.60001"
          y1="374.5"
          x2="179.60001"
          y2="374.5"
          id="line3754"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.2"
          y1="371"
          x2="179.10001"
          y2="371.39999"
          id="line3756"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="179.2"
          y1="370.89999"
          x2="179.2"
          y2="371"
          id="line3758"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="179.2,370.9 179.3,370.8 180,370.7   "
          id="polyline3760"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="197.8"
          y1="367"
          x2="197.8"
          y2="367"
          id="line3762"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198"
          y1="367"
          x2="197.8"
          y2="367"
          id="line3764"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.10001"
          y1="367"
          x2="198"
          y2="367"
          id="line3766"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.2"
          y1="367"
          x2="198.10001"
          y2="367"
          id="line3768"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.3"
          y1="367"
          x2="198.2"
          y2="367"
          id="line3770"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.5"
          y1="367"
          x2="198.3"
          y2="367"
          id="line3772"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.7"
          y1="367"
          x2="198.5"
          y2="367"
          id="line3774"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.8"
          y1="367"
          x2="198.7"
          y2="367"
          id="line3776"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199"
          y1="367"
          x2="198.8"
          y2="367"
          id="line3778"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.2"
          y1="367"
          x2="199"
          y2="367"
          id="line3780"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.3"
          y1="367"
          x2="199.2"
          y2="367"
          id="line3782"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.5"
          y1="367"
          x2="199.3"
          y2="367"
          id="line3784"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.7"
          y1="367"
          x2="199.5"
          y2="367"
          id="line3786"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.8"
          y1="367"
          x2="199.7"
          y2="367"
          id="line3788"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200"
          y1="367"
          x2="199.8"
          y2="367"
          id="line3790"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.2"
          y1="367"
          x2="200"
          y2="367"
          id="line3792"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.3"
          y1="367"
          x2="200.2"
          y2="367"
          id="line3794"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.5"
          y1="367"
          x2="200.3"
          y2="367"
          id="line3796"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.60001"
          y1="367"
          x2="200.5"
          y2="367"
          id="line3798"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.8"
          y1="367"
          x2="200.60001"
          y2="367"
          id="line3800"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.89999"
          y1="367"
          x2="200.8"
          y2="367"
          id="line3802"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.10001"
          y1="367"
          x2="200.89999"
          y2="367"
          id="line3804"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.2"
          y1="367"
          x2="201.10001"
          y2="367"
          id="line3806"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.39999"
          y1="367"
          x2="201.2"
          y2="367"
          id="line3808"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.5"
          y1="367"
          x2="201.39999"
          y2="367"
          id="line3810"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.60001"
          y1="367"
          x2="201.5"
          y2="367"
          id="line3812"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.8"
          y1="367"
          x2="201.60001"
          y2="367"
          id="line3814"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.89999"
          y1="367"
          x2="201.8"
          y2="367"
          id="line3816"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202"
          y1="367"
          x2="201.89999"
          y2="367"
          id="line3818"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.2"
          y1="367"
          x2="202"
          y2="367"
          id="line3820"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.3"
          y1="367"
          x2="202.2"
          y2="367"
          id="line3822"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.39999"
          y1="367"
          x2="202.3"
          y2="367"
          id="line3824"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.5"
          y1="367"
          x2="202.39999"
          y2="367"
          id="line3826"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.60001"
          y1="367"
          x2="202.5"
          y2="367"
          id="line3828"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.7"
          y1="367"
          x2="202.60001"
          y2="367"
          id="line3830"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.8"
          y1="367"
          x2="202.7"
          y2="367"
          id="line3832"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="202.89999"
          y1="367"
          x2="202.8"
          y2="367"
          id="line3834"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203"
          y1="366.89999"
          x2="202.89999"
          y2="367"
          id="line3836"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203"
          y1="366.89999"
          x2="203"
          y2="366.89999"
          id="line3838"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="366.89999"
          x2="203"
          y2="366.89999"
          id="line3840"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="203.10001"
          y1="366.89999"
          x2="203.10001"
          y2="366.89999"
          id="line3842"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="434"
          y1="375.5"
          x2="436.5"
          y2="375.5"
          id="line3844"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="431.5"
          y1="375.5"
          x2="434"
          y2="375.5"
          id="line3846"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="429"
          y1="375.5"
          x2="431.5"
          y2="375.5"
          id="line3848"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="426.39999"
          y1="375.5"
          x2="429"
          y2="375.5"
          id="line3850"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="423.89999"
          y1="375.5"
          x2="426.39999"
          y2="375.5"
          id="line3852"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="421.39999"
          y1="375.60001"
          x2="423.89999"
          y2="375.5"
          id="line3854"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="418.89999"
          y1="375.60001"
          x2="421.39999"
          y2="375.60001"
          id="line3856"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="416.39999"
          y1="375.60001"
          x2="418.89999"
          y2="375.60001"
          id="line3858"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="413.89999"
          y1="375.60001"
          x2="416.39999"
          y2="375.60001"
          id="line3860"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="411.39999"
          y1="375.70001"
          x2="413.89999"
          y2="375.60001"
          id="line3862"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="408.79999"
          y1="375.70001"
          x2="411.39999"
          y2="375.70001"
          id="line3864"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="406.29999"
          y1="375.70001"
          x2="408.79999"
          y2="375.70001"
          id="line3866"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="403.79999"
          y1="375.79999"
          x2="406.29999"
          y2="375.70001"
          id="line3868"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="401.29999"
          y1="375.89999"
          x2="403.79999"
          y2="375.79999"
          id="line3870"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="398.70001"
          y1="375.89999"
          x2="401.29999"
          y2="375.89999"
          id="line3872"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="396.20001"
          y1="376"
          x2="398.70001"
          y2="375.89999"
          id="line3874"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="393.60001"
          y1="376.10001"
          x2="396.20001"
          y2="376"
          id="line3876"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="391"
          y1="376.20001"
          x2="393.60001"
          y2="376.10001"
          id="line3878"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="388.60001"
          y1="376.29999"
          x2="391"
          y2="376.20001"
          id="line3880"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="386.10001"
          y1="376.39999"
          x2="388.60001"
          y2="376.29999"
          id="line3882"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="383.60001"
          y1="376.5"
          x2="386.10001"
          y2="376.39999"
          id="line3884"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="381.10001"
          y1="376.60001"
          x2="383.60001"
          y2="376.5"
          id="line3886"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="378.60001"
          y1="376.70001"
          x2="381.10001"
          y2="376.60001"
          id="line3888"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="376.10001"
          y1="376.79999"
          x2="378.60001"
          y2="376.70001"
          id="line3890"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="373.60001"
          y1="376.89999"
          x2="376.10001"
          y2="376.79999"
          id="line3892"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="371.10001"
          y1="377"
          x2="373.60001"
          y2="376.89999"
          id="line3894"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="368.60001"
          y1="377.10001"
          x2="371.10001"
          y2="377"
          id="line3896"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="366.10001"
          y1="377.20001"
          x2="368.60001"
          y2="377.10001"
          id="line3898"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="363.60001"
          y1="377.29999"
          x2="366.10001"
          y2="377.20001"
          id="line3900"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="361"
          y1="377.5"
          x2="363.60001"
          y2="377.29999"
          id="line3902"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="358.5"
          y1="377.60001"
          x2="361"
          y2="377.5"
          id="line3904"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="356"
          y1="377.70001"
          x2="358.5"
          y2="377.60001"
          id="line3906"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="353.5"
          y1="377.79999"
          x2="356"
          y2="377.70001"
          id="line3908"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="351"
          y1="377.89999"
          x2="353.5"
          y2="377.79999"
          id="line3910"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="348.5"
          y1="378"
          x2="351"
          y2="377.89999"
          id="line3912"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="345.89999"
          y1="378.10001"
          x2="348.5"
          y2="378"
          id="line3914"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="343.39999"
          y1="378.29999"
          x2="345.89999"
          y2="378.10001"
          id="line3916"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="340.89999"
          y1="378.39999"
          x2="343.39999"
          y2="378.29999"
          id="line3918"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="338.39999"
          y1="378.5"
          x2="340.89999"
          y2="378.39999"
          id="line3920"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="335.89999"
          y1="378.60001"
          x2="338.39999"
          y2="378.5"
          id="line3922"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="333.29999"
          y1="378.70001"
          x2="335.89999"
          y2="378.60001"
          id="line3924"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="330.79999"
          y1="378.79999"
          x2="333.29999"
          y2="378.70001"
          id="line3926"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="328.29999"
          y1="378.89999"
          x2="330.79999"
          y2="378.79999"
          id="line3928"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="325.79999"
          y1="379"
          x2="328.29999"
          y2="378.89999"
          id="line3930"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="323.29999"
          y1="379.10001"
          x2="325.79999"
          y2="379"
          id="line3932"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="320.79999"
          y1="379.20001"
          x2="323.29999"
          y2="379.10001"
          id="line3934"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="318.20001"
          y1="379.29999"
          x2="320.79999"
          y2="379.20001"
          id="line3936"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="315.70001"
          y1="379.39999"
          x2="318.20001"
          y2="379.29999"
          id="line3938"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="313.20001"
          y1="379.5"
          x2="315.70001"
          y2="379.39999"
          id="line3940"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.70001"
          y1="379.5"
          x2="313.20001"
          y2="379.5"
          id="line3942"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="308.10001"
          y1="379.60001"
          x2="310.70001"
          y2="379.5"
          id="line3944"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="305.60001"
          y1="379.70001"
          x2="308.10001"
          y2="379.60001"
          id="line3946"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="303.10001"
          y1="379.70001"
          x2="305.60001"
          y2="379.70001"
          id="line3948"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="300.60001"
          y1="379.79999"
          x2="303.10001"
          y2="379.70001"
          id="line3950"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="298.10001"
          y1="379.79999"
          x2="300.60001"
          y2="379.79999"
          id="line3952"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="295.5"
          y1="379.79999"
          x2="298.10001"
          y2="379.79999"
          id="line3954"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="293"
          y1="379.79999"
          x2="295.5"
          y2="379.79999"
          id="line3956"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.5"
          y1="379.89999"
          x2="293"
          y2="379.79999"
          id="line3958"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="288"
          y1="379.89999"
          x2="290.5"
          y2="379.89999"
          id="line3960"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="285.5"
          y1="379.89999"
          x2="288"
          y2="379.89999"
          id="line3962"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="282.89999"
          y1="379.89999"
          x2="285.5"
          y2="379.89999"
          id="line3964"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="280.39999"
          y1="379.89999"
          x2="282.89999"
          y2="379.89999"
          id="line3966"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="277.89999"
          y1="379.89999"
          x2="280.39999"
          y2="379.89999"
          id="line3968"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="275.39999"
          y1="379.89999"
          x2="277.89999"
          y2="379.89999"
          id="line3970"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="272.79999"
          y1="379.89999"
          x2="275.39999"
          y2="379.89999"
          id="line3972"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="270.29999"
          y1="379.89999"
          x2="272.79999"
          y2="379.89999"
          id="line3974"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="266.39999"
          y1="379.89999"
          x2="270.29999"
          y2="379.89999"
          id="line3976"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="262.39999"
          y1="379.89999"
          x2="266.39999"
          y2="379.89999"
          id="line3978"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="258.5"
          y1="379.89999"
          x2="262.39999"
          y2="379.89999"
          id="line3980"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="254.5"
          y1="379.89999"
          x2="258.5"
          y2="379.89999"
          id="line3982"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="250.5"
          y1="379.89999"
          x2="254.5"
          y2="379.89999"
          id="line3984"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="246.60001"
          y1="379.89999"
          x2="250.5"
          y2="379.89999"
          id="line3986"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="242.60001"
          y1="379.89999"
          x2="246.60001"
          y2="379.89999"
          id="line3988"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="238.7"
          y1="379.79999"
          x2="242.60001"
          y2="379.89999"
          id="line3990"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="234.7"
          y1="379.79999"
          x2="238.7"
          y2="379.79999"
          id="line3992"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="230.8"
          y1="379.79999"
          x2="234.7"
          y2="379.79999"
          id="line3994"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="226.8"
          y1="379.79999"
          x2="230.8"
          y2="379.79999"
          id="line3996"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="222.89999"
          y1="379.79999"
          x2="226.8"
          y2="379.79999"
          id="line3998"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="218.89999"
          y1="379.79999"
          x2="222.89999"
          y2="379.79999"
          id="line4000"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="214.89999"
          y1="379.79999"
          x2="218.89999"
          y2="379.79999"
          id="line4002"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="211"
          y1="379.79999"
          x2="214.89999"
          y2="379.79999"
          id="line4004"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="379.79999"
          x2="211"
          y2="379.79999"
          id="line4006"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="289.39999"
          y1="362.60001"
          x2="289.70001"
          y2="362.60001"
          id="line4008"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="289.10001"
          y1="362.60001"
          x2="289.39999"
          y2="362.60001"
          id="line4010"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="288.39999"
          y1="362.60001"
          x2="289.10001"
          y2="362.60001"
          id="line4012"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="287.10001"
          y1="362.60001"
          x2="288.39999"
          y2="362.60001"
          id="line4014"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="285.79999"
          y1="362.60001"
          x2="287.10001"
          y2="362.60001"
          id="line4016"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="284.5"
          y1="362.60001"
          x2="285.79999"
          y2="362.60001"
          id="line4018"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="282"
          y1="362.60001"
          x2="284.5"
          y2="362.60001"
          id="line4020"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="279.39999"
          y1="362.60001"
          x2="282"
          y2="362.60001"
          id="line4022"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="276.79999"
          y1="362.60001"
          x2="279.39999"
          y2="362.60001"
          id="line4024"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="274.20001"
          y1="362.60001"
          x2="276.79999"
          y2="362.60001"
          id="line4026"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="271.60001"
          y1="362.60001"
          x2="274.20001"
          y2="362.60001"
          id="line4028"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="269.10001"
          y1="362.60001"
          x2="271.60001"
          y2="362.60001"
          id="line4030"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="266.5"
          y1="362.60001"
          x2="269.10001"
          y2="362.60001"
          id="line4032"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="263.89999"
          y1="362.60001"
          x2="266.5"
          y2="362.60001"
          id="line4034"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="261.29999"
          y1="362.60001"
          x2="263.89999"
          y2="362.60001"
          id="line4036"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="258.70001"
          y1="362.60001"
          x2="261.29999"
          y2="362.60001"
          id="line4038"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="256.20001"
          y1="362.60001"
          x2="258.70001"
          y2="362.60001"
          id="line4040"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="253.60001"
          y1="362.60001"
          x2="256.20001"
          y2="362.60001"
          id="line4042"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="251"
          y1="362.60001"
          x2="253.60001"
          y2="362.60001"
          id="line4044"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="248.39999"
          y1="362.60001"
          x2="251"
          y2="362.60001"
          id="line4046"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="245.89999"
          y1="362.60001"
          x2="248.39999"
          y2="362.60001"
          id="line4048"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="243.3"
          y1="362.60001"
          x2="245.89999"
          y2="362.60001"
          id="line4050"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="240.7"
          y1="362.60001"
          x2="243.3"
          y2="362.60001"
          id="line4052"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="238.10001"
          y1="362.60001"
          x2="240.7"
          y2="362.60001"
          id="line4054"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="235.5"
          y1="362.60001"
          x2="238.10001"
          y2="362.60001"
          id="line4056"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="233"
          y1="362.60001"
          x2="235.5"
          y2="362.60001"
          id="line4058"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="230.39999"
          y1="362.60001"
          x2="233"
          y2="362.60001"
          id="line4060"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="227.8"
          y1="362.60001"
          x2="230.39999"
          y2="362.60001"
          id="line4062"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="225.2"
          y1="362.60001"
          x2="227.8"
          y2="362.60001"
          id="line4064"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="222.60001"
          y1="362.60001"
          x2="225.2"
          y2="362.60001"
          id="line4066"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="220.10001"
          y1="362.60001"
          x2="222.60001"
          y2="362.60001"
          id="line4068"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="217.5"
          y1="362.60001"
          x2="220.10001"
          y2="362.60001"
          id="line4070"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="214.89999"
          y1="362.60001"
          x2="217.5"
          y2="362.60001"
          id="line4072"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="212.3"
          y1="362.60001"
          x2="214.89999"
          y2="362.60001"
          id="line4074"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="209.7"
          y1="362.60001"
          x2="212.3"
          y2="362.60001"
          id="line4076"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207.2"
          y1="362.60001"
          x2="209.7"
          y2="362.60001"
          id="line4078"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="437.20001"
          y1="366.89999"
          x2="435.5"
          y2="366.89999"
          id="line4080"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="436.5,375.5 438.1,375.5 438.1,366.9 437.2,366.9   "
          id="polyline4082"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="306.29999"
          y1="362.89999"
          x2="305"
          y2="362.89999"
          id="line4084"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="307.70001"
          y1="362.89999"
          x2="306.29999"
          y2="362.89999"
          id="line4086"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="292.10001"
          y1="362.60001"
          x2="291.5"
          y2="362.60001"
          id="line4088"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="293.39999"
          y1="362.70001"
          x2="292.10001"
          y2="362.60001"
          id="line4090"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="294.70001"
          y1="362.70001"
          x2="293.39999"
          y2="362.70001"
          id="line4092"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="297.20001"
          y1="362.70001"
          x2="294.70001"
          y2="362.70001"
          id="line4094"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="299.79999"
          y1="362.79999"
          x2="297.20001"
          y2="362.70001"
          id="line4096"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="302.39999"
          y1="362.79999"
          x2="299.79999"
          y2="362.79999"
          id="line4098"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="388.60001"
          y1="366.29999"
          x2="386.70001"
          y2="366.20001"
          id="line4100"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="390.5"
          y1="366.29999"
          x2="388.60001"
          y2="366.29999"
          id="line4102"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="308.39999"
          y1="363"
          x2="309.10001"
          y2="363"
          id="line4104"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="307.70001"
          y1="362.89999"
          x2="308.39999"
          y2="363"
          id="line4106"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="303.70001"
          y1="362.79999"
          x2="305"
          y2="362.89999"
          id="line4108"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="302.39999"
          y1="362.79999"
          x2="303.70001"
          y2="362.79999"
          id="line4110"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="433.79999"
          y1="366.89999"
          x2="435.5"
          y2="366.89999"
          id="line4112"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="432"
          y1="366.89999"
          x2="433.79999"
          y2="366.89999"
          id="line4114"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="430.29999"
          y1="366.89999"
          x2="432"
          y2="366.89999"
          id="line4116"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 438.5,368.5"
          id="path4118"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="434.20001"
          y1="368.5"
          x2="438.10001"
          y2="368.5"
          id="line4120"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="432.10001"
          y1="368.60001"
          x2="434.20001"
          y2="368.5"
          id="line4122"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="430"
          y1="368.60001"
          x2="432.10001"
          y2="368.60001"
          id="line4124"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="425.70001"
          y1="368.60001"
          x2="430"
          y2="368.60001"
          id="line4126"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="421.5"
          y1="368.60001"
          x2="425.70001"
          y2="368.60001"
          id="line4128"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="419"
          y1="368.5"
          x2="421.5"
          y2="368.60001"
          id="line4130"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="416.39999"
          y1="368.5"
          x2="419"
          y2="368.5"
          id="line4132"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="413.89999"
          y1="368.5"
          x2="416.39999"
          y2="368.5"
          id="line4134"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="411.39999"
          y1="368.5"
          x2="413.89999"
          y2="368.5"
          id="line4136"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="408.89999"
          y1="368.39999"
          x2="411.39999"
          y2="368.5"
          id="line4138"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="406.29999"
          y1="368.39999"
          x2="408.89999"
          y2="368.39999"
          id="line4140"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="403.79999"
          y1="368.39999"
          x2="406.29999"
          y2="368.39999"
          id="line4142"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="401.29999"
          y1="368.29999"
          x2="403.79999"
          y2="368.39999"
          id="line4144"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="398.70001"
          y1="368.20001"
          x2="401.29999"
          y2="368.29999"
          id="line4146"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="396.20001"
          y1="368.20001"
          x2="398.70001"
          y2="368.20001"
          id="line4148"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="393.60001"
          y1="368.10001"
          x2="396.20001"
          y2="368.20001"
          id="line4150"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="391"
          y1="368"
          x2="393.60001"
          y2="368.10001"
          id="line4152"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="388.5"
          y1="367.89999"
          x2="391"
          y2="368"
          id="line4154"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="386"
          y1="367.79999"
          x2="388.5"
          y2="367.89999"
          id="line4156"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="383.5"
          y1="367.70001"
          x2="386"
          y2="367.79999"
          id="line4158"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="381"
          y1="367.60001"
          x2="383.5"
          y2="367.70001"
          id="line4160"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="378.5"
          y1="367.5"
          x2="381"
          y2="367.60001"
          id="line4162"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="376"
          y1="367.39999"
          x2="378.5"
          y2="367.5"
          id="line4164"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="373.60001"
          y1="367.29999"
          x2="376"
          y2="367.39999"
          id="line4166"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="371.10001"
          y1="367.20001"
          x2="373.60001"
          y2="367.29999"
          id="line4168"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="368.5"
          y1="367.10001"
          x2="371.10001"
          y2="367.20001"
          id="line4170"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="366"
          y1="367"
          x2="368.5"
          y2="367.10001"
          id="line4172"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="363.5"
          y1="366.89999"
          x2="366"
          y2="367"
          id="line4174"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="361"
          y1="366.79999"
          x2="363.5"
          y2="366.89999"
          id="line4176"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="358.5"
          y1="366.70001"
          x2="361"
          y2="366.79999"
          id="line4178"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="355.89999"
          y1="366.60001"
          x2="358.5"
          y2="366.70001"
          id="line4180"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="353.39999"
          y1="366.39999"
          x2="355.89999"
          y2="366.60001"
          id="line4182"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="350.89999"
          y1="366.29999"
          x2="353.39999"
          y2="366.39999"
          id="line4184"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="348.39999"
          y1="366.20001"
          x2="350.89999"
          y2="366.29999"
          id="line4186"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="345.89999"
          y1="366.10001"
          x2="348.39999"
          y2="366.20001"
          id="line4188"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="343.29999"
          y1="366"
          x2="345.89999"
          y2="366.10001"
          id="line4190"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="340.79999"
          y1="365.89999"
          x2="343.29999"
          y2="366"
          id="line4192"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="338.29999"
          y1="365.79999"
          x2="340.79999"
          y2="365.89999"
          id="line4194"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="335.79999"
          y1="365.70001"
          x2="338.29999"
          y2="365.79999"
          id="line4196"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="333.29999"
          y1="365.60001"
          x2="335.79999"
          y2="365.70001"
          id="line4198"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="330.79999"
          y1="365.5"
          x2="333.29999"
          y2="365.60001"
          id="line4200"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="328.20001"
          y1="365.29999"
          x2="330.79999"
          y2="365.5"
          id="line4202"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="325.70001"
          y1="365.20001"
          x2="328.20001"
          y2="365.29999"
          id="line4204"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="323.20001"
          y1="365.10001"
          x2="325.70001"
          y2="365.20001"
          id="line4206"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="320.70001"
          y1="365"
          x2="323.20001"
          y2="365.10001"
          id="line4208"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="318.20001"
          y1="365"
          x2="320.70001"
          y2="365"
          id="line4210"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="315.60001"
          y1="364.89999"
          x2="318.20001"
          y2="365"
          id="line4212"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="313.10001"
          y1="364.79999"
          x2="315.60001"
          y2="364.89999"
          id="line4214"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.60001"
          y1="364.70001"
          x2="313.10001"
          y2="364.79999"
          id="line4216"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="308.10001"
          y1="364.60001"
          x2="310.60001"
          y2="364.70001"
          id="line4218"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="305.60001"
          y1="364.60001"
          x2="308.10001"
          y2="364.60001"
          id="line4220"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="303.10001"
          y1="364.5"
          x2="305.60001"
          y2="364.60001"
          id="line4222"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="300.5"
          y1="364.5"
          x2="303.10001"
          y2="364.5"
          id="line4224"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="298"
          y1="364.39999"
          x2="300.5"
          y2="364.5"
          id="line4226"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="295.5"
          y1="364.39999"
          x2="298"
          y2="364.39999"
          id="line4228"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="293"
          y1="364.39999"
          x2="295.5"
          y2="364.39999"
          id="line4230"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.5"
          y1="364.39999"
          x2="293"
          y2="364.39999"
          id="line4232"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="288"
          y1="364.39999"
          x2="290.5"
          y2="364.39999"
          id="line4234"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="285.39999"
          y1="364.39999"
          x2="288"
          y2="364.39999"
          id="line4236"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="282.89999"
          y1="364.39999"
          x2="285.39999"
          y2="364.39999"
          id="line4238"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="280.39999"
          y1="364.39999"
          x2="282.89999"
          y2="364.39999"
          id="line4240"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="277.89999"
          y1="364.39999"
          x2="280.39999"
          y2="364.39999"
          id="line4242"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="275.39999"
          y1="364.39999"
          x2="277.89999"
          y2="364.39999"
          id="line4244"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="272.79999"
          y1="364.39999"
          x2="275.39999"
          y2="364.39999"
          id="line4246"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="270.29999"
          y1="364.39999"
          x2="272.79999"
          y2="364.39999"
          id="line4248"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="266.39999"
          y1="364.39999"
          x2="270.29999"
          y2="364.39999"
          id="line4250"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="262.39999"
          y1="364.39999"
          x2="266.39999"
          y2="364.39999"
          id="line4252"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="258.39999"
          y1="364.39999"
          x2="262.39999"
          y2="364.39999"
          id="line4254"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="254.5"
          y1="364.39999"
          x2="258.39999"
          y2="364.39999"
          id="line4256"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="250.5"
          y1="364.39999"
          x2="254.5"
          y2="364.39999"
          id="line4258"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="246.60001"
          y1="364.39999"
          x2="250.5"
          y2="364.39999"
          id="line4260"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="242.60001"
          y1="364.39999"
          x2="246.60001"
          y2="364.39999"
          id="line4262"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="238.7"
          y1="364.39999"
          x2="242.60001"
          y2="364.39999"
          id="line4264"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="234.7"
          y1="364.39999"
          x2="238.7"
          y2="364.39999"
          id="line4266"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="230.8"
          y1="364.29999"
          x2="234.7"
          y2="364.39999"
          id="line4268"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="226.8"
          y1="364.29999"
          x2="230.8"
          y2="364.29999"
          id="line4270"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="222.8"
          y1="364.29999"
          x2="226.8"
          y2="364.29999"
          id="line4272"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="218.89999"
          y1="364.29999"
          x2="222.8"
          y2="364.29999"
          id="line4274"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="214.89999"
          y1="364.29999"
          x2="218.89999"
          y2="364.29999"
          id="line4276"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="211"
          y1="364.29999"
          x2="214.89999"
          y2="364.29999"
          id="line4278"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="364.29999"
          x2="211"
          y2="364.29999"
          id="line4280"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="M 438.4,367.1"
          id="path4282"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="436.29999"
          y1="367.10001"
          x2="438.10001"
          y2="367.10001"
          id="line4284"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="434.20001"
          y1="367.10001"
          x2="436.29999"
          y2="367.10001"
          id="line4286"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="432"
          y1="367.10001"
          x2="434.20001"
          y2="367.10001"
          id="line4288"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="429.89999"
          y1="367.10001"
          x2="432"
          y2="367.10001"
          id="line4290"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="427.79999"
          y1="367.10001"
          x2="429.89999"
          y2="367.10001"
          id="line4292"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="425.70001"
          y1="367.10001"
          x2="427.79999"
          y2="367.10001"
          id="line4294"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="423.5"
          y1="367.10001"
          x2="425.70001"
          y2="367.10001"
          id="line4296"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="421.39999"
          y1="367.10001"
          x2="423.5"
          y2="367.10001"
          id="line4298"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="418.89999"
          y1="367.10001"
          x2="421.39999"
          y2="367.10001"
          id="line4300"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="416.39999"
          y1="367.10001"
          x2="418.89999"
          y2="367.10001"
          id="line4302"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="413.89999"
          y1="367.10001"
          x2="416.39999"
          y2="367.10001"
          id="line4304"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="411.39999"
          y1="367"
          x2="413.89999"
          y2="367.10001"
          id="line4306"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="408.79999"
          y1="367"
          x2="411.39999"
          y2="367"
          id="line4308"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="406.29999"
          y1="367"
          x2="408.79999"
          y2="367"
          id="line4310"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="403.79999"
          y1="366.89999"
          x2="406.29999"
          y2="367"
          id="line4312"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="401.3,366.9 401.5,366.9 403.8,366.9   "
          id="polyline4314"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="398.70001"
          y1="366.79999"
          x2="401.29999"
          y2="366.89999"
          id="line4316"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="396.20001"
          y1="366.70001"
          x2="398.70001"
          y2="366.79999"
          id="line4318"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="393.60001"
          y1="366.60001"
          x2="396.20001"
          y2="366.70001"
          id="line4320"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="391"
          y1="366.60001"
          x2="393.60001"
          y2="366.60001"
          id="line4322"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="388.60001"
          y1="366.5"
          x2="391"
          y2="366.60001"
          id="line4324"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="386.10001"
          y1="366.39999"
          x2="388.60001"
          y2="366.5"
          id="line4326"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="383.60001"
          y1="366.29999"
          x2="386.10001"
          y2="366.39999"
          id="line4328"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="381.10001"
          y1="366.20001"
          x2="383.60001"
          y2="366.29999"
          id="line4330"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="378.60001"
          y1="366.10001"
          x2="381.10001"
          y2="366.20001"
          id="line4332"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="376.10001"
          y1="366"
          x2="378.60001"
          y2="366.10001"
          id="line4334"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="373.60001"
          y1="365.89999"
          x2="376.10001"
          y2="366"
          id="line4336"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="371.10001"
          y1="365.79999"
          x2="373.60001"
          y2="365.89999"
          id="line4338"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="368.60001"
          y1="365.70001"
          x2="371.10001"
          y2="365.79999"
          id="line4340"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="366.10001"
          y1="365.5"
          x2="368.60001"
          y2="365.70001"
          id="line4342"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="363.60001"
          y1="365.39999"
          x2="366.10001"
          y2="365.5"
          id="line4344"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="361"
          y1="365.29999"
          x2="363.60001"
          y2="365.39999"
          id="line4346"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="358.5"
          y1="365.20001"
          x2="361"
          y2="365.29999"
          id="line4348"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="356"
          y1="365.10001"
          x2="358.5"
          y2="365.20001"
          id="line4350"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="353.5"
          y1="365"
          x2="356"
          y2="365.10001"
          id="line4352"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="351"
          y1="364.89999"
          x2="353.5"
          y2="365"
          id="line4354"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="348.5"
          y1="364.79999"
          x2="351"
          y2="364.89999"
          id="line4356"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="345.89999"
          y1="364.70001"
          x2="348.5"
          y2="364.79999"
          id="line4358"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="343.39999"
          y1="364.5"
          x2="345.89999"
          y2="364.70001"
          id="line4360"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="340.89999"
          y1="364.39999"
          x2="343.39999"
          y2="364.5"
          id="line4362"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="338.39999"
          y1="364.29999"
          x2="340.89999"
          y2="364.39999"
          id="line4364"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="335.89999"
          y1="364.20001"
          x2="338.39999"
          y2="364.29999"
          id="line4366"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="333.29999"
          y1="364.10001"
          x2="335.89999"
          y2="364.20001"
          id="line4368"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="330.79999"
          y1="364"
          x2="333.29999"
          y2="364.10001"
          id="line4370"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="328.29999"
          y1="363.89999"
          x2="330.79999"
          y2="364"
          id="line4372"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="325.79999"
          y1="363.79999"
          x2="328.29999"
          y2="363.89999"
          id="line4374"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="323.29999"
          y1="363.70001"
          x2="325.79999"
          y2="363.79999"
          id="line4376"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="320.79999"
          y1="363.60001"
          x2="323.29999"
          y2="363.70001"
          id="line4378"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="318.20001"
          y1="363.5"
          x2="320.79999"
          y2="363.60001"
          id="line4380"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="315.70001"
          y1="363.39999"
          x2="318.20001"
          y2="363.5"
          id="line4382"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="313.20001"
          y1="363.29999"
          x2="315.70001"
          y2="363.39999"
          id="line4384"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.70001"
          y1="363.29999"
          x2="313.20001"
          y2="363.29999"
          id="line4386"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="308.10001"
          y1="363.20001"
          x2="310.70001"
          y2="363.29999"
          id="line4388"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="305.60001"
          y1="363.10001"
          x2="308.10001"
          y2="363.20001"
          id="line4390"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="303.10001"
          y1="363.10001"
          x2="305.60001"
          y2="363.10001"
          id="line4392"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="300.60001"
          y1="363"
          x2="303.10001"
          y2="363.10001"
          id="line4394"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="298.10001"
          y1="363"
          x2="300.60001"
          y2="363"
          id="line4396"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="295.5"
          y1="363"
          x2="298.10001"
          y2="363"
          id="line4398"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="293"
          y1="362.89999"
          x2="295.5"
          y2="363"
          id="line4400"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.5"
          y1="362.89999"
          x2="293"
          y2="362.89999"
          id="line4402"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="288"
          y1="362.89999"
          x2="290.5"
          y2="362.89999"
          id="line4404"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="285.5"
          y1="362.89999"
          x2="288"
          y2="362.89999"
          id="line4406"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="282.89999"
          y1="362.89999"
          x2="285.5"
          y2="362.89999"
          id="line4408"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="280.39999"
          y1="362.89999"
          x2="282.89999"
          y2="362.89999"
          id="line4410"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="277.89999"
          y1="362.89999"
          x2="280.39999"
          y2="362.89999"
          id="line4412"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="275.39999"
          y1="362.89999"
          x2="277.89999"
          y2="362.89999"
          id="line4414"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="272.79999"
          y1="362.89999"
          x2="275.39999"
          y2="362.89999"
          id="line4416"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="270.29999"
          y1="362.89999"
          x2="272.79999"
          y2="362.89999"
          id="line4418"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="268.29999"
          y1="362.89999"
          x2="270.29999"
          y2="362.89999"
          id="line4420"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="266.39999"
          y1="362.89999"
          x2="268.29999"
          y2="362.89999"
          id="line4422"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="264.39999"
          y1="362.89999"
          x2="266.39999"
          y2="362.89999"
          id="line4424"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="262.39999"
          y1="362.89999"
          x2="264.39999"
          y2="362.89999"
          id="line4426"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="260.39999"
          y1="362.89999"
          x2="262.39999"
          y2="362.89999"
          id="line4428"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="258.5"
          y1="362.89999"
          x2="260.39999"
          y2="362.89999"
          id="line4430"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="256.5"
          y1="362.89999"
          x2="258.5"
          y2="362.89999"
          id="line4432"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="254.5"
          y1="362.89999"
          x2="256.5"
          y2="362.89999"
          id="line4434"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="252.5"
          y1="362.89999"
          x2="254.5"
          y2="362.89999"
          id="line4436"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="250.5"
          y1="362.89999"
          x2="252.5"
          y2="362.89999"
          id="line4438"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="248.60001"
          y1="362.89999"
          x2="250.5"
          y2="362.89999"
          id="line4440"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="246.60001"
          y1="362.89999"
          x2="248.60001"
          y2="362.89999"
          id="line4442"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="244.60001"
          y1="362.89999"
          x2="246.60001"
          y2="362.89999"
          id="line4444"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="242.60001"
          y1="362.89999"
          x2="244.60001"
          y2="362.89999"
          id="line4446"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="240.7"
          y1="362.89999"
          x2="242.60001"
          y2="362.89999"
          id="line4448"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="238.7"
          y1="362.89999"
          x2="240.7"
          y2="362.89999"
          id="line4450"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="236.7"
          y1="362.89999"
          x2="238.7"
          y2="362.89999"
          id="line4452"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="234.7"
          y1="362.89999"
          x2="236.7"
          y2="362.89999"
          id="line4454"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="232.7"
          y1="362.89999"
          x2="234.7"
          y2="362.89999"
          id="line4456"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="230.8"
          y1="362.89999"
          x2="232.7"
          y2="362.89999"
          id="line4458"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="228.8"
          y1="362.89999"
          x2="230.8"
          y2="362.89999"
          id="line4460"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="226.8"
          y1="362.89999"
          x2="228.8"
          y2="362.89999"
          id="line4462"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="224.8"
          y1="362.89999"
          x2="226.8"
          y2="362.89999"
          id="line4464"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="222.89999"
          y1="362.89999"
          x2="224.8"
          y2="362.89999"
          id="line4466"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="220.89999"
          y1="362.89999"
          x2="222.89999"
          y2="362.89999"
          id="line4468"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="218.89999"
          y1="362.89999"
          x2="220.89999"
          y2="362.89999"
          id="line4470"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="216.89999"
          y1="362.89999"
          x2="218.89999"
          y2="362.89999"
          id="line4472"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="214.89999"
          y1="362.89999"
          x2="216.89999"
          y2="362.89999"
          id="line4474"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="213"
          y1="362.89999"
          x2="214.89999"
          y2="362.89999"
          id="line4476"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="211"
          y1="362.89999"
          x2="213"
          y2="362.89999"
          id="line4478"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="209"
          y1="362.89999"
          x2="211"
          y2="362.89999"
          id="line4480"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="207"
          y1="362.89999"
          x2="209"
          y2="362.89999"
          id="line4482"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="309.79999"
          y1="363"
          x2="309.10001"
          y2="363"
          id="line4484"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.20001"
          y1="363"
          x2="309.79999"
          y2="363"
          id="line4486"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.29999"
          y1="363"
          x2="310.20001"
          y2="363"
          id="line4488"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.5"
          y1="363"
          x2="310.29999"
          y2="363"
          id="line4490"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="310.89999"
          y1="363"
          x2="310.5"
          y2="363"
          id="line4492"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="311.29999"
          y1="363"
          x2="310.89999"
          y2="363"
          id="line4494"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="312"
          y1="363.10001"
          x2="311.29999"
          y2="363"
          id="line4496"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="394.39999"
          y1="366.5"
          x2="392.5"
          y2="366.39999"
          id="line4498"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="396.29999"
          y1="366.5"
          x2="394.39999"
          y2="366.5"
          id="line4500"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="399.20001"
          y1="366.60001"
          x2="396.29999"
          y2="366.5"
          id="line4502"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <polyline
          class="st0"
          points="402.1,366.7 401.5,366.6 399.2,366.6   "
          id="polyline4504"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="405"
          y1="366.70001"
          x2="402.10001"
          y2="366.70001"
          id="line4506"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="407.89999"
          y1="366.79999"
          x2="405"
          y2="366.70001"
          id="line4508"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="409.79999"
          y1="366.79999"
          x2="407.89999"
          y2="366.79999"
          id="line4510"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="411.70001"
          y1="366.79999"
          x2="409.79999"
          y2="366.79999"
          id="line4512"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="413.70001"
          y1="366.79999"
          x2="411.70001"
          y2="366.79999"
          id="line4514"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="415.60001"
          y1="366.79999"
          x2="413.70001"
          y2="366.79999"
          id="line4516"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="417.5"
          y1="366.89999"
          x2="415.60001"
          y2="366.79999"
          id="line4518"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="419.5"
          y1="366.89999"
          x2="417.5"
          y2="366.89999"
          id="line4520"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="421.39999"
          y1="366.89999"
          x2="419.5"
          y2="366.89999"
          id="line4522"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="423.29999"
          y1="366.89999"
          x2="421.39999"
          y2="366.89999"
          id="line4524"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="425.10001"
          y1="366.89999"
          x2="423.29999"
          y2="366.89999"
          id="line4526"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="426.79999"
          y1="366.89999"
          x2="425.10001"
          y2="366.89999"
          id="line4528"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="428.5"
          y1="366.89999"
          x2="426.79999"
          y2="366.89999"
          id="line4530"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="430.29999"
          y1="366.89999"
          x2="428.5"
          y2="366.89999"
          id="line4532"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.89999"
          y1="362.60001"
          x2="291.5"
          y2="362.60001"
          id="line4534"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290.29999"
          y1="362.60001"
          x2="290.89999"
          y2="362.60001"
          id="line4536"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="290"
          y1="362.60001"
          x2="290.29999"
          y2="362.60001"
          id="line4538"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="289.89999"
          y1="362.60001"
          x2="290"
          y2="362.60001"
          id="line4540"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="289.70001"
          y1="362.60001"
          x2="289.89999"
          y2="362.60001"
          id="line4542"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198"
          y1="367.10001"
          x2="197.8"
          y2="367.10001"
          id="line4544"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.10001"
          y1="367.10001"
          x2="198"
          y2="367.10001"
          id="line4546"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.2"
          y1="367.10001"
          x2="198.10001"
          y2="367.10001"
          id="line4548"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.39999"
          y1="367.10001"
          x2="198.2"
          y2="367.10001"
          id="line4550"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.5"
          y1="367.10001"
          x2="198.39999"
          y2="367.10001"
          id="line4552"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.7"
          y1="367.10001"
          x2="198.5"
          y2="367.10001"
          id="line4554"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.8"
          y1="367.10001"
          x2="198.7"
          y2="367.10001"
          id="line4556"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="198.89999"
          y1="367.10001"
          x2="198.8"
          y2="367.10001"
          id="line4558"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.10001"
          y1="367.10001"
          x2="198.89999"
          y2="367.10001"
          id="line4560"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.2"
          y1="367.10001"
          x2="199.10001"
          y2="367.10001"
          id="line4562"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.39999"
          y1="367.10001"
          x2="199.2"
          y2="367.10001"
          id="line4564"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.5"
          y1="367.10001"
          x2="199.39999"
          y2="367.10001"
          id="line4566"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.60001"
          y1="367.10001"
          x2="199.5"
          y2="367.10001"
          id="line4568"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.8"
          y1="367.10001"
          x2="199.60001"
          y2="367.10001"
          id="line4570"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="199.89999"
          y1="367.10001"
          x2="199.8"
          y2="367.10001"
          id="line4572"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.10001"
          y1="367.10001"
          x2="199.89999"
          y2="367.10001"
          id="line4574"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.2"
          y1="367.10001"
          x2="200.10001"
          y2="367.10001"
          id="line4576"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.3"
          y1="367.10001"
          x2="200.2"
          y2="367.10001"
          id="line4578"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.5"
          y1="367.10001"
          x2="200.3"
          y2="367.10001"
          id="line4580"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.60001"
          y1="367.10001"
          x2="200.5"
          y2="367.10001"
          id="line4582"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.8"
          y1="367.10001"
          x2="200.60001"
          y2="367.10001"
          id="line4584"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="200.89999"
          y1="367.10001"
          x2="200.8"
          y2="367.10001"
          id="line4586"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201"
          y1="367.10001"
          x2="200.89999"
          y2="367.10001"
          id="line4588"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.2"
          y1="367.10001"
          x2="201"
          y2="367.10001"
          id="line4590"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.3"
          y1="367.10001"
          x2="201.2"
          y2="367.10001"
          id="line4592"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <line
          class="st0"
          x1="201.5"
          y1="367.10001"
          x2="201.3"
          y2="367.10001"
          id="line4594"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
        <path
          class="st0"
          d="m 160.2,370.2 c 0,0 0.4,1.1 1.4,1.1 l 0.9,-0.1"
          id="path4596"
          inkscape:connector-curvature="0"
          style="
            fill: none;
            stroke: #030304;
            stroke-width: 0.15000001;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        />
      </g>
      <path
        class="st0"
        d="M 364.4,387"
        id="path4600"
        inkscape:connector-curvature="0"
        style="
          fill: none;
          stroke: #030304;
          stroke-width: 0.15000001;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      />
    </g>
    <path
      style="
        fill: none;
        stroke: #000000;
        stroke-width: 1px;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-opacity: 0;
      "
      d="M 24.600006,14.483484 H 228.75779"
      id="asta-dx-ext-path"
      inkscape:connector-curvature="0"
    />
    <image
      class="astaColorImg"
      :href="`https://cdn.thema-optical.com/3d-models/colori_acetato_sito/${astaColor}.jpg`"
    />
    <defs>
      <clipPath id="myImg">
        <path
          d="M 129.76319,19.121909 C 124.60183,19.002088 78.239843,17.071382 69.675254,16.619597 52.606275,15.719203 27.243011,15.05416 9.8660159,15.051357 l -9.30861387,-0.0015 V 12.350751 9.6516483 L 5.6297605,9.8024268 C 14.294123,10.05998 40.528311,9.5488887 61.648665,8.7110734 72.378654,8.2854293 81.960395,7.8799539 82.941422,7.8100169 83.92245,7.7400799 89.541062,7.4876744 95.427228,7.249116 101.31339,7.0105576 108.43699,6.7112896 111.25745,6.5840762 129.16006,5.7765986 133.45558,5.7217261 189.46095,5.5850752 L 231.32184,5.4829363 V 12.384523 19.28611 L 181.99176,19.23765 C 154.86022,19.211 131.35736,19.15891 129.76319,19.121905 Z"
          id="path2346"
        />
      </clipPath>
    </defs>
    <text id="text21">
      <textPath
        class="asta-dx-ext-text"
        :class="{white: colore.name == 'bianco', nocolore : colore.name == 'nessun-colore'}"
        xlink:href="#asta-dx-ext-path"
        text-anchor="end"
        startOffset="100%"
        id="textPath39"
        :style="{ fill: colore ? colore.colorCode : '#000000', fontFamily: font }"
      >{{ name.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '') }}</textPath>
    </text>
  </svg>
</template>

<script>
  export default {
    props: ["name", "colore", "font", "astaColor"],
  };
</script>

<style>
  .astaColorImg {
    clip-path: url(#myImg);
  }
  .st0 {
    fill: none;
    stroke: #020203;
    stroke-width: 0.15;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .white {
    text-shadow: 0 0 0px black;
  }
  .nocolore {
    text-shadow: 0 0 2px black;
  }
  .asta-dx-ext-text {
    font-size: 10px;
    text-align: right;
  }
</style>
