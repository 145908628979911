import Vue from "vue";
import "./plugins/axios";
import VueI18n from "vue-i18n";
import ajax from "./plugins/ajax_request";
import App from "./App.vue";
import "animate.css/animate.min.css";
import VueWaypoint from "vue-waypoint";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import "./theme/index.css";
import "./theme/claro.less";
import VueCookie from "vue-cookies";
import locale from "element-ui/lib/locale/lang/it";
import _ from "lodash";
import CanvasPlugin from 'configurator-base-app'

Vue.use(VueI18n);

Vue.use(VueWaypoint);
Vue.use(Element, { locale });
Vue.use(VueCookie);

Vue.filter("sizeLabel", (sl) => {
  if (!_.isEmpty(sl)) {
    return `${sl.size_description} ${parseInt(sl.size_eye)}/${parseInt(
      sl.size_bridge
    )}`;
  }
  return "";
});
Vue.filter("misuraAste", (m) => (m == "140" ? "REGULAR" : "LARGE"));

Vue.filter("demi", (c) => (c == "HAVANA" ? "DEMI" : c));

// Vue.config.productionTip = false;./components/Configurator

const i18n = new VueI18n({
  locale: "IT",
  fallbackLocale: "EN",
});

ajax.getCountryCode().then((response) => {
  i18n.locale = response.data;
});

ajax.getTranslations().then((response) => {
  let messages = response.data;
  for (let key in messages) {
    i18n.setLocaleMessage(key, messages[key]);
  }
});

// Configurazione configurator-base-app
const settings = {
  VUE_APP_REST_URL: `${process.env.VUE_APP_REST_URL}`,
  VUE_APP_CONFIG_IDENTIFIER: `${process.env.VUE_APP_CONFIG_IDENTIFIER}`,
  VUE_APP_COLORS_URL: `${process.env.VUE_APP_COLORS_URL}`,
  VUE_APP_MODEL_URL: `${process.env.VUE_APP_MODEL_URL}`,
  VUE_APP_CONFIG_DATABASE: `${process.env.VUE_APP_CONFIG_DATABASE}`,
  VUE_APP_SENDGRID_SHARE_MAIL_TEMPLATE_ID: process.env.VUE_APP_SENDGRID_SHARE_MAIL_TEMPLATE_ID,
  VUE_APP_SENDGRID_API_KEY: process.env.VUE_APP_SENDGRID_API_KEY,
  VUE_APP_HANDLE_SUBLINE_TEMPLES: process.env.VUE_APP_HANDLE_SUBLINE_TEMPLES
}

Vue.use(CanvasPlugin, { store, settings })


const mountEl = document.querySelector("#app");

window.soconfig = new Vue({
  i18n,
  router,
  store,
  created() {
    Vue.component('Canvas3d', this.$canvas3d);
    this.$store.commit("configurator/setLensesType", 'V');
  },
  render: (createElement) => {
    const context = {
      props: { ...mountEl.dataset },
    };
    return createElement(App, context);
  }
}).$mount("#app");

router.push("/");

// eslint-disable-next-line no-unused-vars
// window.soconfig = new Vue({
//   i18n,
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");

/*new Vue({
  i18n,
  el: '#app',
  ...App
})*/
